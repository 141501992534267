import React from 'react';
import styled from 'styled-components';
import { __COLORS } from '../../../src/shared/Theme';
import Help from '../../assets/images/helpsupport.svg';
import { PageHeading } from '../../shared/components/PageHeading/PageHeading';
import { PageContent } from '../../shared/components/PageContent/PageContent';

const SupportHelp = styled.div`
  display: flex;
  justify-content: center;
  img {
    width: 100%;
    height: 500px;
    @media (max-width: 991px) {
      height: auto;
    }
  }
`;
const CustmorSupport = styled.div`
  padding: 17px;
  background: #0e325a;
  display: inline-block;
  color: #fff;
  border-radius: 12px;
  position: absolute;
  margin: 0 0 0 9%;
  text-align: center;
  line-height: 30px;
  @media (max-width: 991px) {
    position: relative;
    display: block;
    width: 310px;
    margin: 0 auto;
  }
`;
const TypographyBody1 = styled.div`
  font-size: 20px;
  margin-bottom: 20px;
`;
const TypographyBody2 = styled.div`
  font-size: 16px;
  font-weight: 600;
`;
const TypographyBody3 = styled.div`
  font-size: 16px;
  a {
    color: ${__COLORS.WHITE_COLOR};
  }
`;
const TypographyBody4 = styled.div`
  font-size: 16px;
`;
const Support = () => {
  return (
    <>
      <PageHeading header="Help & Support" />
      <PageContent>
        <CustmorSupport>
          <TypographyBody1>Customer Support</TypographyBody1>
          <TypographyBody2>Support Email</TypographyBody2>
          <TypographyBody3>
            <a href="mailto:support@insurewill.com">support@insurewill.com</a>
          </TypographyBody3>
          <TypographyBody4>
            <b>Support Hotline:</b> +41 123 456 7890
          </TypographyBody4>
        </CustmorSupport>
        <SupportHelp>
          <img src={Help} alt="Help and Support" />
        </SupportHelp>
      </PageContent>
    </>
  );
};

export default Support;
