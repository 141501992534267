import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import { __COLORS } from './shared/Theme';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';

const MyApp = () => {
  return (
    <>
      <ThemeProvider theme={{ __COLORS }}>
        <Provider store={store}>
          <App />
        </Provider>
      </ThemeProvider>
    </>
  );
};

ReactDOM.render(
  <Router>
    <MyApp />
  </Router>,
  document.getElementById('root'),
);
