import { post, put, get, remove } from './index';

export const admin = {
  adminLogin: (email: string, password: string, client_ip: string, client_loc: any) =>
    post('/admin/login/email', { email, password, client_ip, client_loc }),
  createAdmin: (payload: any) => post('/admin/add-admin', payload),
  editAdmin: (payload: any) => put('/admin/edit-admin', payload),
  getAdminUsers: (payload: any) => get(`/admin/get-admin?pageNo=${payload.pageNo}&searchTerm=${payload.searchTerm}`),
  getAppUsers: (payload: any) => get(`/admin/users?pageNo=${payload.pageNo}&searchTerm=${payload.searchTerm}`),
  getTransactions: (payload: any) =>
    get(
      `/admin/transactions?searchTerm=${payload.searchTerm}&pageNo=${payload.pageNo}&sortedTerm=${payload.sortedTerm}&isAscending=${payload.isAscending}`,
    ),
  deleteAdmin: (payload: any) => remove(`/admin/delete-admin`, payload),
  getUserInfo: ({ userId }: any) => get(`/admin/user-details?userId=${userId}`),
  blockUser: (payload: any) => post(`/admin/block-user`, payload),
  verifyAssetOtp: (payload: any) => post(`/admin/verify-otp`, payload),
  adminAddAsset: (payload: any) => post('/admin/asset-action', payload),
  resendOtp: (payload: any) => post(`/admin/resend-otp`, payload),
};
