import { useEffect, useState } from 'react';
import { useDispatch, useSelector, RootStateOrAny } from 'react-redux';
import { useApi } from '../../shared/api';
import { setAssets } from '../../redux/assets/assetsSlice';
import { toast } from 'react-toastify';
import { PageHeading } from '../../shared/components/PageHeading/PageHeading';
import { PageContent } from '../../shared/components/PageContent/PageContent';
import AssetListTable from './AssetListTable';
import { AddAssetModal } from './AddAssetModal';

interface DataType {
  [key: string]: unknown;
}

const Portfolio = () => {
  const api = useApi();
  const dispatch = useDispatch();

  const { assets, count } = useSelector((s: RootStateOrAny) => s.assets);

  // const [isDetailsModal, setDetailsModal] = useState(false);
  // const [isDeleting, setIsDeleting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedAsset, setSelectedAsset] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedFilter, setSelectedFilter] = useState({
    name: '',
    order: true,
  });

  const [isAssetModal, setAssetModal] = useState(false);

  useEffect(() => {
    getAssets();
  }, []);

  useEffect(() => {
    getAssets();
  }, [currentPage, selectedFilter]);

  const getAssets = async () => {
    try {
      const response = await api.assets.getAll({
        pageNo: currentPage,
        sortedTerm: selectedFilter.name,
        isAscending: selectedFilter.order,
      });
      dispatch(setAssets(response.data));
      setIsLoading(false);
    } catch (e) {
      toast.error((e as Error).message);
      setIsLoading(false);
    }
  };

  // const deleteAsset = async (assetId: any) => {
  //   try {
  //     setIsDeleting(true);
  //     await api.assets.remove({ id: assetId });
  //     const response = await api.assets.getAll();
  //     dispatch(setAssets(response.data));
  //     //setSelectedAsset(null);
  //     toast.success('Asset deleted successfully.');
  //   } catch (e) {
  //     //
  //   }
  //   setIsDeleting(false);
  // };

  const handleFilter = (column: string, value: boolean) => {
    setSelectedFilter({ name: column, order: value });
  };

  const toggleAssetModal = (data: DataType = {}) => {
    setAssetModal(!isAssetModal);
    setSelectedAsset(data);
  };

  return (
    <>
      <PageHeading
        header="Portfolio"
        buttonText="Add New"
        handleClick={() => {
          toggleAssetModal();
        }}
      />
      <PageContent>
        <AssetListTable
          tableData={assets}
          count={count}
          isLoading={isLoading}
          selectedFilter={selectedFilter}
          handleFilter={handleFilter}
          setCurrentPage={(count: number) => {
            setCurrentPage(count);
          }}
          toggleAssetModal={toggleAssetModal}
        />
      </PageContent>
      {isAssetModal && (
        <AddAssetModal
          openModal={isAssetModal}
          toggleModal={toggleAssetModal}
          asset={selectedAsset}
          getAssets={getAssets}
        />
      )}
    </>
  );
};

export default Portfolio;
