import { configureStore, combineReducers } from '@reduxjs/toolkit';
import appReducer from './app/appSlice';
import nomineesReducer from './nominees/nomineesSlice';
import assetsReducer from './assets/assetsSlice';
import userReducer from './users/userSlice';
import notificationReducer from './notification/notificationSlice';

export type RootState = {
  app: any;
  nominees: any;
};

export type GetRootState = () => RootState;

const rootReducer = combineReducers({
  app: appReducer,
  users: userReducer,
  nominees: nomineesReducer,
  assets: assetsReducer,
  notifications: notificationReducer,
});

export const store = configureStore({
  devTools: true,
  reducer: rootReducer,
});

export default store;
