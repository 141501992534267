import BillingHistory from './BillingHistory';
import PaymentDetails from './PaymentDetails';
import { PageHeading } from '../../shared/components/PageHeading/PageHeading';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { useState } from 'react';
import styled from 'styled-components';
import { PageContent } from '../../shared/components/PageContent/PageContent';
import { __COLORS } from '../../shared/Theme';

const TabHeader = styled(NavItem)`
  cursor: pointer;
`;

const TabHeaderText = styled(NavLink)`
  background-color: ${(props) => (props.isActive ? __COLORS.PRIMARY : __COLORS.GREY_BACKGROUND)} !important;
  color: ${(props) => (props.isActive ? __COLORS.WHITE_COLOR : __COLORS.PRIMARY)};
  border-bottom: ${(props) => (props.isActive ? '' : '1px solid #dee2e6')} !important;

  &:hover {
    color: ${(props) => (props.isActive ? __COLORS.WHITE_COLOR : __COLORS.PRIMARY)};
  }
`;

const Billing = (props: any) => {
  const [activeTab, setActiveTab] = useState('1');

  return (
    <>
      <PageHeading header="Billing & Payment" divider={false} margin="0px" />
      <PageContent>
        <Nav tabs style={{ marginBottom: '40px' }}>
          <TabHeader>
            <TabHeaderText onClick={() => setActiveTab('1')} isActive={activeTab === '1'}>
              SUBSCRIPTION LIST
            </TabHeaderText>
          </TabHeader>
          <TabHeader>
            <TabHeaderText isActive={activeTab === '2'} onClick={() => setActiveTab('2')}>
              BILLING HISTORY
            </TabHeaderText>
          </TabHeader>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <PaymentDetails {...props} />
          </TabPane>
          <TabPane tabId="2">
            <BillingHistory />
          </TabPane>
        </TabContent>{' '}
      </PageContent>
    </>
  );
};

export default Billing;
