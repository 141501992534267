import { useEffect } from 'react';
import { useApi } from '../../shared/api';
import styled from 'styled-components';
import { __COLORS } from '../../shared/Theme';
import { SPACING } from '../../shared/Layout';
import MainImge from '../../assets/images/communications1.png';
import { useHistory } from 'react-router-dom';
import { EXTRA_SMALL_DEVICES } from '../../shared/helpers/Mobile';
import BgImg from '../../assets/images/mainbackground.png';
import { ButtonWrapper } from './components/ButtonWrapper';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${__COLORS.WHITE_COLOR};
  margin: 0 auto;
  padding: 5rem 0;
  width: 600px;
  @media (min-width: 320px) and (max-width: 600px) {
    padding: 3rem 0;
  }
`;

const Wrapper = styled.div`
  width: 60%;
  height: 50%;
  border-radius: ${SPACING / 2}px;
  text-align: center;
  padding-top: 2rem;

  ${EXTRA_SMALL_DEVICES`
   width: 80%;
  font-size: 14px;
  height: auto;
  `}
`;

const Logo = styled.h1`
  font-weight: 900;
  font-size: 4rem;
  color: ${__COLORS.PRIMARY};

  ${EXTRA_SMALL_DEVICES`
  text-align: center;
  font-size: 3rem;
  `}
`;

const ButtonContainer = styled.div`
  text-align: -webkit-center;
  margin-top: ${SPACING * 3}px;
`;

const EmailLogo = styled.img`
  width: 120px;
  height: 120px;
  margin-bottom: 20px;
`;

const MainDiv = styled.div`
  background: url(${BgImg});
  background-position: center;
  background-size: cover;
  padding: 2rem;
  height: 100vh;
  display: flex;
  align-items: center;
  @media (max-width: 786px) {
    background-position: left;
    background-size: inherit;
  }
`;

const Message = styled.h2`
  color: #2f5173;
  font-weight: 400;
  line-height: 2.625rem;
  font-family: 'Poppins';
  font-size: 1.75rem;
  @media (min-width: 320px) and (max-width: 600px) {
    font-size: 1.5rem;
  }
`;

type Props = {
  location: {
    search: string;
  };
};

const EmailVerification = (props: Props) => {
  const api = useApi();
  const history = useHistory();

  useEffect(() => {
    if (props.location && props.location.search) {
      const tokenString: string = props.location.search.replace('?token=', '');
      api.users.verifyEmail(tokenString);
    }
  }, []);

  return (
    <MainDiv>
      <Container>
        <Logo>InsureWill</Logo>
        <Wrapper>
          <EmailLogo src={MainImge} alt="" />
          <Message>{`Congratulations, Your email address has been verified`}</Message>
          <ButtonContainer>
            <ButtonWrapper onClick={() => history.push('/login')}>{`Click here to Login`}</ButtonWrapper>
          </ButtonContainer>
        </Wrapper>
      </Container>
    </MainDiv>
  );
};

export default EmailVerification;
