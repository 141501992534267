import moment from 'moment';
import styled from 'styled-components';
import { SPACING } from '../../shared/Layout';
import { resolveAssetType } from './helpers/commonFunctions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { __COLORS } from '../../shared/Theme';
import Loading from '../Loading/Loading';
import { Pagination } from '../../ui-library';

const TableWrapperDiv = styled.div`
  overflow-x: auto;
  max-height: 500px;
  overflow-y: auto;
  width: 100%;
`;

const PortfolioHeaderRow = styled.tr`
  border: 0.125rem solid #614be2;
`;

const ButtonContainer = styled.div`
  text-align: -webkit-center;
`;

const TableRow = styled.tr`
  height: 6.625rem;
  border: 0.188rem solid #f5f5f7;
  @media (min-width: 320px) and (max-width: 1200px) {
    height: 5.625rem;
  }
`;

const InnerTableData = styled.div<{ showBorder: boolean }>`
  border-right: ${(props) => (props.showBorder ? '0.03125rem solid #303c4e' : 'none')};
  height: 4.26031rem;
  align-items: center;
  display: flex;
  justify-content: center;
  @media (min-width: 320px) and (max-width: 786px) {
    height: 3.26031rem;
  }
`;
const PortfolioTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  text-align: center;
  & td {
    background: ${__COLORS.WHITE_COLOR};
    padding: 0.625rem 0 0.625rem 0.625rem;
    border-bottom: 0.125rem solid;
    @media (min-width: 320px) and (max-width: 1200px) {
      padding: 0.313rem 0 0.313rem 0.313rem;
    }
  }
`;
const TableHeading = styled.th`
  width: 16%;
  border: 3px solid #f5f5f7;
  font-size: 1rem;
  font-family: Poppins;
  font-weight: 500;
  color: ${__COLORS.ICON_COLOR};
  background: ${__COLORS.WHITE_COLOR};
  height: 5.0625rem;
  flex-shrink: 0;
  border-radius: 0.9375rem 0.9375rem 0rem 0rem;
  white-space: nowrap;
  padding: 0 10px;
  @media (min-width: 320px) and (max-width: 1200px) {
    font-size: 0.938rem;
    height: 4.0625rem;
  }
`;
const MainButton = styled.button`
  padding: 0.625rem 3.125rem;
  flex-shrink: 0;
  border-radius: 0.3125rem;
  background: #3c607f;
  border: none;
  color: ${__COLORS.WHITE_COLOR};
  @media (min-width: 786px) and (max-width: 1200px) {
    padding: 0.62rem 2rem;
    font-size: 0.875rem;
  }
  @media (min-width: 320px) and (max-width: 786px) {
    padding: 0.313rem 0.625rem;
    font-size: 0.875rem;
  }
`;
const Tdata = styled.h1`
  color: ${__COLORS.ICON_COLOR};
  text-align: center;
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 0 20px;
  @media (min-width: 320px) and (max-width: 1200px) {
    font-size: 0.875rem;
    padding: 0 10px;
  }
`;

const NoRecordsText = styled.div`
  text-align: center;
  margin: ${SPACING * 2}px 0;
  width: 100%;
  color: ${__COLORS.SECONDARY_DARK};
`;

interface DataType {
  [key: string]: unknown;
}

type Props = {
  tableData: Array<Record<string, unknown>>;
  selectedFilter: { name?: string; order?: boolean };
  handleFilter: (_arg1: string, _arg2: boolean) => void;
  count: number;
  isLoading: boolean;
  setCurrentPage: (_arg: number) => void;
  toggleAssetModal: (_arg: any) => void;
};

const AssetListTable = ({
  tableData,
  selectedFilter,
  handleFilter,
  count,
  isLoading,
  setCurrentPage,
  toggleAssetModal,
}: Props) => {
  const handleDetailClick = (assetData: DataType) => {
    toggleAssetModal({ ...assetData });
    // dispatch(toggleModal({ modalType: ModalType.EDIT_ASSET, data: { ...assetData } }));
  };

  return (
    <>
      <TableWrapperDiv>
        <PortfolioTable>
          <PortfolioHeaderRow>
            <TableHeading>SL#</TableHeading>
            <TableHeading>ASSET CATEGORY</TableHeading>
            <TableHeading>ASSET TYPE</TableHeading>
            <TableHeading>
              {`ASSET NAME `}
              {selectedFilter.order ? (
                <FontAwesomeIcon
                  icon={faChevronUp}
                  onClick={() => handleFilter('asset_name', false)}
                  style={{ cursor: 'pointer' }}
                />
              ) : (
                <FontAwesomeIcon
                  icon={faChevronDown}
                  onClick={() => handleFilter('asset_name', true)}
                  style={{ cursor: 'pointer' }}
                />
              )}
            </TableHeading>
            <TableHeading>IDENTIFIER</TableHeading>

            <TableHeading>EXPIRY</TableHeading>

            <TableHeading>ACTION</TableHeading>
          </PortfolioHeaderRow>
          {tableData?.length
            ? tableData?.map((item: any, idx: number) => {
                return (
                  <TableRow key={idx}>
                    <td>
                      <InnerTableData showBorder>
                        <Tdata>{idx + 1}</Tdata>
                      </InnerTableData>
                    </td>
                    <td>
                      <InnerTableData showBorder>
                        <Tdata>{resolveAssetType(item.type, 'CATEGORY') || ''}</Tdata>{' '}
                      </InnerTableData>
                    </td>
                    <td>
                      <InnerTableData showBorder>
                        <Tdata>{resolveAssetType(item.type, 'TYPE') || ''}</Tdata>{' '}
                      </InnerTableData>
                    </td>
                    <td>
                      <InnerTableData showBorder>
                        <Tdata>{item.asset_name}</Tdata>{' '}
                      </InnerTableData>
                    </td>
                    <td>
                      <InnerTableData showBorder>
                        <Tdata>{item.type !== 'plot' && item.type !== 'flat' ? item.unique_id : '-'}</Tdata>
                      </InnerTableData>
                    </td>
                    <td>
                      <InnerTableData showBorder>
                        <Tdata>{item.validThrough ? moment(item.validThrough).format('MM/YYYY') : '-'}</Tdata>
                      </InnerTableData>
                    </td>
                    <td>
                      <InnerTableData showBorder={false}>
                        <Tdata>
                          <ButtonContainer>
                            <MainButton onClick={() => handleDetailClick(item)}>Details </MainButton>
                          </ButtonContainer>
                        </Tdata>
                      </InnerTableData>
                    </td>
                  </TableRow>
                );
              })
            : null}
        </PortfolioTable>
      </TableWrapperDiv>
      {tableData?.length ? <Pagination totalCount={count} onChangeHandler={setCurrentPage} /> : null}
      <>
        {isLoading && !tableData?.length ? (
          <Loading />
        ) : !tableData?.length ? (
          <NoRecordsText>No records found</NoRecordsText>
        ) : null}
      </>
    </>
  );
};

export default AssetListTable;
