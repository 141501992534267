import { useEffect, useState } from 'react';
import { useApi } from '../../shared/api';
import styled from 'styled-components';
import BgImg from '../../assets/images/mainbackground.png';
import MainImge from '../../assets/images/communications1.png';
import { __COLORS } from '../../shared/Theme';
import { SPACING } from '../../shared/Layout';
import { Button } from '../../ui-library';
import { toast } from 'react-toastify';
import { EXTRA_SMALL_DEVICES } from '../../shared/helpers/Mobile';

const Container = styled.div`
  display: flex
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #fff;
  margin: 0 auto;
  padding: 5rem 0;
  width: 600px;
  text-align: center;
`;

const Wrapper = styled.div`
  background: ${__COLORS.WHITE_COLOR};
  padding: ${SPACING * 2}px;
  border-radius: ${SPACING / 2}px;
  text-align: center;
  h2 {
    color: #2f5173;
    font-weight: 400;
    line-height: 2.625rem;
    font-family: 'Poppins';
    font-size: 1.75rem;
  }

  ${EXTRA_SMALL_DEVICES`
   width: 100%;
  font-size: 14px;
  padding: 54px;
  `}
`;

const Logo = styled.h1`
  font-weight: 900;
  font-size: 4rem;
  color: ${__COLORS.PRIMARY};

  ${EXTRA_SMALL_DEVICES`
  text-align: center;
  font-size: 3rem;
  `}
`;

const ButtonContainer = styled.div`
  margin-top: ${SPACING * 3}px;
  display: flex;
  justify-content: center;
`;

const EmailLogo = styled.img`
  width: 120px;
  height: 120px;
  margin-bottom: 20px;
`;
const ErrorMessage = styled.div`
  color: red;
`;
const MainDiv = styled.div`
  background: url(${BgImg});
  background-position: center;
  background-size: cover;
  padding: 2rem;
  justify-content: center;
  height: 100vh;
  display: flex;
  align-items: center;
  @media (max-width: 786px) {
    background-position: left;
    background-size: inherit;
  }
`;

type Props = {
  location: {
    search: string;
  };
};

const WellbeingConfirmation = (props: Props) => {
  const api = useApi();

  const [token, setToken] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [confirmed, setConfirmed] = useState(false);

  useEffect(() => {
    if (props.location && props.location.search) {
      const tokenString: string = props.location.search.replace('?token=', '');
      setToken(tokenString);
    }
  }, []);

  const handleNomineeConfirmation = async (calledFrom: boolean) => {
    try {
      await api.users.getNomineeConfirmation(calledFrom, token);
      toast.success('Confirmed successfully.');
      setConfirmed(true);
    } catch (e) {
      //toast.error(e.message);
      setErrorMessage((e as Error).message);
    }
  };

  return (
    <MainDiv>
      <Container>
        <Logo>InsureWill</Logo>
        <Wrapper>
          {confirmed ? (
            <>
              <EmailLogo src={MainImge} alt="Email img" />
              <h2>{`Your wellbeing has been confirmed.`}</h2>
            </>
          ) : (
            <>
              <h2>{`Please confirm your wellbeing.`}</h2>
              {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}

              <ButtonContainer>
                <Button
                  onClick={() => handleNomineeConfirmation(true)}
                  style={{ marginRight: '15px', marginLeft: '27px' }}
                >{`Yes`}</Button>
                <Button onClick={() => handleNomineeConfirmation(false)}>{`No`}</Button>
              </ButtonContainer>
            </>
          )}
        </Wrapper>
      </Container>
    </MainDiv>
  );
};

export default WellbeingConfirmation;
