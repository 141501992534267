import styled from 'styled-components';
import { useEffect } from 'react';
import { GlobalStyle } from './shared/Theme';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { MainRouter } from './routes';

const Container = styled.div``;

// const renderAppState = (state: LoginStates) => {
//   switch (state) {
//     // case LoginStates.LOADING:
//     //   return <Loading />;
//     case LoginStates.LOGGED_OUT:
//       return <MainSignupRouter />;
//     case LoginStates.LOGGED_IN:
//       return <MainAppRouter />;
//     default:
//       return null;
//   }
// };

const App = () => {
  // const loginState = useSelector((state: RootState) => state.app.loginState);

  // const initialFetch = async () => {
  //   // Check autologin.
  //   const token: any = localStorage.getItem('token');

  //   if (token) {
  //     try {
  //       const queryToken: any = window.location.href.split('token=')[1];
  //       if (queryToken) {
  //         jwt.verify(queryToken, 'insureWill+123+', async (err: any, decoded: any) => {
  //           if (decoded.atomic) {
  //             dispatch(setLoginState(LoginStates.LOGGED_OUT));
  //           }
  //         });
  //       } else {
  //         const response = await api.users.getUser();
  //         dispatch(setLoginState(LoginStates.LOGGED_IN));
  //         dispatch(setUser(response.data));
  //         if (response.data.is_admin) {
  //           history.push('/admin/user-management');
  //         } else {
  //           history.push('/portfolio');
  //         }
  //         const notificationResponse = await api.notifications.notificationList();
  //         notificationResponse && dispatch(setNotification(notificationResponse));
  //       }
  //     } catch (e) {
  //       //
  //     }
  //   } else {
  //     dispatch(setLoginState(LoginStates.LOGGED_OUT));
  //   }
  // };

  useEffect(() => {
    // initialFetch();

    if (sessionStorage.getItem('phoneVerification') && sessionStorage.getItem('phoneVerification') == 'MARK_AS_READ') {
      sessionStorage.setItem('phoneVerification', '');
    }
    if (sessionStorage.getItem('emailVerification') && sessionStorage.getItem('emailVerification') == 'MARK_AS_READ') {
      sessionStorage.setItem('emailVerification', '');
    }
  }, [sessionStorage.getItem('token')]);

  return (
    <Container>
      <ToastContainer />
      <MainRouter />
      {/* <Switch>{renderAppState(loginState)}</Switch> */}
      <GlobalStyle />
    </Container>
  );
};

export default App;
