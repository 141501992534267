import { useState, useEffect, ChangeEvent } from 'react';
import { useSelector, RootStateOrAny } from 'react-redux';
import styled from 'styled-components';
import { useApi } from '../../shared/api';
import { SPACING } from '../../shared/Layout';
import { __COLORS } from '../../shared/Theme';
import { Button, SelectWrapper } from '../../ui-library';
import moment from 'moment';
import { Modal, ModalBody } from 'reactstrap';
import {
  assetTypesCard,
  assetCategoryList,
  // creditCardProviders,
  assetTypesInsurance,
  assetTypesBankAccount,
  assetTypesProperty,
} from '../../shared/options';
import { toast } from 'react-toastify';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useHistory, useParams } from 'react-router';
import InputWrapper from '../../ui-library/Input/InputWrapper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faIndianRupeeSign, faPlus } from '@fortawesome/free-solid-svg-icons';
import { IsInputNumber } from '../../shared/helpers/IsInputNumber';
import { EXTRA_SMALL_DEVICES } from '../../shared/helpers/Mobile';
import { NOMINEES } from '../../routes/Routes';
import AdminOtpModal from './AdminOtpModal';
import './CustomModal.css';

const Flex = styled.div`
  display: flex;

  ${EXTRA_SMALL_DEVICES`
    display: block;
  `}
`;

const AssetDetailsContainer = styled.div`
  padding: ${SPACING * 2}px;
  width: 50%;
  ${EXTRA_SMALL_DEVICES`
    width: 100%;
  `}
`;
const NomineeDetailsContainer = styled.div`
  padding: ${SPACING * 2}px;
  border-left: 1px solid #c4c4c4;
  width: 50%;
  ${EXTRA_SMALL_DEVICES`
    width: 100%;
  `}
`;

const AssetValueContainer = styled.div`
  padding: ${SPACING * 2}px;
`;

const Title = styled.h3`
  text-transform: uppercase;
  font-weight: 700;
  font-size: 22px;
  color: ${__COLORS.ICON_COLOR};
`;

const PreferenceWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${SPACING * 4}px;
  flex-wrap: wrap;
  row-gap: 15px;
`;

const PreferenceText = styled.p`
  font-weight: 500;
  font-size: 12px;
  width: 85px;
  margin: 0 ${SPACING + 4}px;
  color: #888888;
`;

const PreferenceSelectWrapper = styled.div`
  width: 150px;
  margin-bottom: 5px;
`;

const ButtonsContainer = styled.div`
  margin-top: ${SPACING * 4}px;
  margin-bottom: ${SPACING * 2}px;
  justify-content: center;
  display: flex;
  padding-bottom: 20px;

  ${EXTRA_SMALL_DEVICES`
    margin-bottom: 100px;
  `}
`;

const AddMoreContainer = styled.div`
  margin-left: ${SPACING * 2}px;
  display: flex;
`;

const NoNomineeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 15px;
`;
const ErrorMessage = styled.div`
  margin-top: 10px;
  color: red;
`;

const NoNomineeText = styled.p`
  text-align: center;
  margin: ${SPACING * 2}px 0;
`;

const DatePickerInput = styled(DatePicker)`
  height: 42px;
  background: ${__COLORS.INPUT_GREY};
  max-width: 19rem;
  border-radius: 0.3125rem !important;
  border: none;
  box-shadow: none;

  &:focus {
    background: ${__COLORS.INPUT_GREY};
    box-shadow: none;
  }
`;

const FormField = styled.div`
  width: 100%;
  padding: '0 0.88rem 0 0.88rem';
  display: flex;
  justify-content: flex-start;
  margin: ${SPACING * 2.5}px 0;
  gap: 20px;

  @media (min-width: 320px) and (max-width: 1200px) {
    flex-wrap: wrap;
    width: 100%;
  }
`;
const FieldLabel = styled.label`
  width: 40%;
  padding: 5px;
  @media (min-width: 320px) and (max-width: 786px) {
    width: 40%;
  }
`;

const DeleteIcon = styled.span`
  margin-left: 10px;
  color: ${__COLORS.ICON_COLOR};
  cursor: pointer;
`;

const InputWithIcon = styled.div`
  display: flex;
  width: 160px;
  align-items: center;
  border: none;
  padding: 0.5rem;
  background: ${__COLORS.INPUT_GREY};
  border-radius: 0.3125rem !important;
`;

const InputAmount = styled.input`
  flex: 1;
  border: none;
  outline: none;
  background: ${__COLORS.INPUT_GREY};
  width: 100%;
`;

const NomineeShareWrapper = styled.div`
  display: flex;
`;

const RequiredField = styled.span`
  color: ${__COLORS.RED_COLOR};
`;

type Props = {
  asset: any;
  openModal: boolean;
  toggleModal: () => void;
  nomineeData?: any;
  getAssets: () => void;
};

type DataType = {
  [key: string]: unknown;
};

export const AddAssetModal = ({ asset, openModal, toggleModal, nomineeData, getAssets }: Props) => {
  const history = useHistory();
  const api = useApi();

  const params: any = useParams();
  const user = useSelector((s: RootStateOrAny) => s.users.user);
  const nomineeList = nomineeData ? nomineeData : user && user.Nominees ? user.Nominees : [];

  const formatCardNumber = (value: string) => {
    let val = value;
    let newVal = '';
    val = val.replace(/\s/g, '');

    // iterate to letter-spacing after every 4 digits
    for (let i = 0; i < val.length; i++) {
      if (i % 4 == 0 && i > 0) newVal = newVal.concat(' ');
      newVal = newVal.concat(val[i]);
    }
    return newVal;
  };

  const [isLoading, setIsLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(asset && Object.keys(asset).length ? true : false);
  const [nominees, setNominees] = useState<any>(nomineeList ? nomineeList : []);
  const [assetType, setAssetType] = useState<any>(null);
  const [provider, setProvider] = useState(asset && asset.provider ? asset.provider : '');
  const [assetCategory, setAssetCategory] = useState<any>('');
  const [assetOptions, setAssetOptions] = useState<any>([]);

  const [assetName, setAssetName] = useState(asset && asset.asset_name ? asset.asset_name : '');

  const [insuranceNumber, setInsuranceNumber] = useState(asset && asset.type === 'insurance' ? asset.unique_id : '');
  const [accountNumber, setAccountNumber] = useState(asset && asset.type === 'bank_account' ? asset.unique_id : '');
  const [address, setAddress] = useState(asset ? asset?.additionalInfo?.address : '');

  const [cardNumber, setCardNumber] = useState(asset.unique_id ? formatCardNumber(asset.unique_id) : '');
  const [folioNumber, setFolioNumber] = useState(asset && asset.type === 'mutual_fund' ? asset.unique_id : '');
  const [expiry, setExpiry] = useState(asset.validThrough ? new Date(asset.validThrough) : '');
  const [notes, setNotes] = useState(asset ? asset?.additionalInfo?.notes : '');
  const [selectedNominees, setSelectedNominees] = useState<any>(nomineeList?.length ? [{ id: null, share: 0 }] : []);
  const [isOtpModal, setOtpModal] = useState(false);
  const [otpId, setOtpId] = useState(null);

  const [errorMessage, setErrorMessage] = useState({
    label: '',
    value: '',
  });
  const [cardData, setCardData] = useState<any>({});

  const [assetValueList, setAssetValueList] = useState<any>([
    { assetName: 'Term Coverage', validThrough: moment(), amount: 100 },
    { assetName: 'Life Insurance', validThrough: moment(), amount: 100 },
  ]);

  const fetchCardDetails = async (value: string) => {
    const response = await api.assets.getCardDetails(value);
    if (
      response.data &&
      response.data.data &&
      response.data.data.bank.name !== '' &&
      response.data.data.card.type !== ''
    ) {
      setCardData(response.data.data);
    } else {
      setErrorMessage({ value: 'Please enter valid Card Number!', label: 'CARD_NUMBER' });
    }
  };

  useEffect(() => {
    if (cardData.card && cardData.card.type) {
      const cardObj = assetTypesCard.find((item: any) => item.label.includes(cardData.card.type)) || {};
      handleSelect(cardObj, setAssetType, 'assetType');
    }
    if (cardData.bank && cardData.bank.name) {
      handleSelect(cardData.bank.name, setProvider, 'provider');
    }
  }, [cardData]);

  useEffect(() => {
    const tempExpiryDate = moment(expiry).format('MM-YY');
    const currentDate = moment().format('MM-YY');

    if (expiry) {
      if (moment(tempExpiryDate, 'MM-YY').isBefore(moment(currentDate, 'MM-YY'))) {
        setErrorMessage({ value: 'Please enter valid expiry date!', label: 'VALID_THRU' });
      }
    }
  }, [expiry]);

  useEffect(() => {
    if (assetCategory && assetCategory.label) {
      if (assetCategory.label == 'Insurance') {
        if (isEditing) {
          setInsuranceNumber(asset.unique_id);
        }
        setAssetOptions(assetTypesInsurance);
      } else if (assetCategory.label == 'Bank Account') {
        if (isEditing) {
          setAccountNumber(asset.unique_id);
        }
        setAssetOptions(assetTypesBankAccount);
      } else if (assetCategory.label == 'Card') {
        if (isEditing) {
          setCardNumber(asset.unique_id);
        }
        setAssetOptions(assetTypesCard);
      } else {
        setAssetOptions(assetTypesProperty);
      }
    }
  }, [assetCategory]);

  const setSessionData = (data: any, calledFrom: string) => {
    const sessionData = sessionStorage.getItem('Asset Data');
    const sessionDatObj = sessionData ? JSON.parse(sessionData) : {};
    sessionDatObj[calledFrom] = data;
    sessionStorage.setItem('Asset Data', JSON.stringify(sessionDatObj));
  };

  useEffect(() => {
    const sessionData = sessionStorage.getItem('Asset Data');
    const sessionDatObj = sessionData ? JSON.parse(sessionData) : {};

    if (Object.keys(sessionDatObj).length !== 0 && !isEditing) {
      if (sessionDatObj.assetType) {
        setAssetType(sessionDatObj.assetType);
      }
      sessionDatObj.provider && setProvider(sessionDatObj.provider);
      sessionDatObj.assetName && setAssetName(sessionDatObj.assetName);
      sessionDatObj.insuranceNumber && setInsuranceNumber(sessionDatObj.insuranceNumber);
      sessionDatObj.cardNumber && setCardNumber(sessionDatObj.cardNumber);
      sessionDatObj.folioNumber && setFolioNumber(sessionDatObj.folioNumber);
      sessionDatObj.accountNumber && setAccountNumber(sessionDatObj.accountNumber);
      sessionDatObj.expiry && setExpiry(sessionDatObj.expiry || '');
      sessionDatObj.notes && setNotes(sessionDatObj.notes);
      sessionDatObj.selectedNominees && setSelectedNominees(sessionDatObj.selectedNominees);
      sessionDatObj.assetValueList && setAssetValueList(sessionDatObj.assetValueList);
      sessionDatObj.nominees && setNominees(sessionDatObj.nominees);
      sessionDatObj.assetCategory && setAssetCategory(sessionDatObj.assetCategory);
    } else if (asset && Object.keys(asset).length) {
      const selectedNomineeList: any = [];
      if (asset && asset.AssetNominees?.length) {
        asset.AssetNominees.map((item: any) => {
          const tempObject = { ...item.linked_nominee };
          tempObject.share = item.share;
          selectedNomineeList.push(tempObject);
        });
      } else if (nomineeList?.length && !asset?.AssetNominees?.length) {
        selectedNomineeList.push({ id: null, share: 0 });
      }

      let nomineeListTemp: any = [];

      nomineeListTemp = nomineeList.filter((item: any) => !selectedNomineeList.some((nom: any) => nom.id == item.id));
      if (selectedNomineeList?.length && selectedNomineeList[selectedNomineeList.length - 1]?.id !== null) {
        nomineeListTemp = [...nomineeListTemp, selectedNomineeList[selectedNomineeList.length - 1]];
      }
      setNominees(nomineeListTemp);
      setSelectedNominees(selectedNomineeList);
      const tempTypeList = [...assetTypesInsurance, ...assetTypesBankAccount, ...assetTypesCard, ...assetTypesProperty];
      let matchedLabel = '';
      tempTypeList.find((item: any) => {
        if (item.value == asset.type) {
          matchedLabel = item;
        }
      });
      if (asset.type == 'health' || asset.type == 'vehicle' || asset.type == 'term') {
        setAssetCategory({ label: 'Insurance', value: 'insurance' });
      } else if (asset.type == 'single' || asset.type == 'joint' || asset.type == 'corporate') {
        setAssetCategory({ label: 'Bank Account', value: 'bank account' });
      } else if (asset.type == 'credit_card' || asset.type == 'debit_card') {
        setAssetCategory({ label: 'Card', value: 'Card' });
      } else if (asset.type == 'plot' || asset.type == 'flat') {
        setAssetCategory({ label: 'Property', value: 'property' });
      }

      setAssetType(matchedLabel);

      if (asset && asset.AssetValues && asset.AssetValues.length !== 0) {
        const assetValueListTemp: any = [];
        asset.AssetValues.map((item: any) => {
          const tempObject = {
            assetName: item.coverage_type,
            amount: item.amount,
            validThrough: item.validThrough,
          };
          assetValueListTemp.push(tempObject);
        });
        setAssetValueList(assetValueListTemp);
      }
    }
  }, []);

  const getUniqueIdentifier = () => {
    switch (assetCategory.label) {
      case 'Insurance':
        return insuranceNumber;
      case 'Bank Account':
        return accountNumber;
      case 'Card':
        return cardNumber;

      default:
        return '';
    }
  };

  const addNewAsset = () => {
    const tempAssetList = [...assetValueList];
    tempAssetList.push({ name: '', validThrough: moment(), amount: '' });
    handleSelect(tempAssetList, setAssetValueList, 'assetValueList');
  };

  const deleteAsset = (index: number) => {
    const tempAssetList = [...assetValueList];
    tempAssetList.splice(index, 1);
    handleSelect(tempAssetList, setAssetValueList, 'assetValueList');
  };

  const getTotalShare = (list: any = null) => {
    const listToCheck = list ? list : selectedNominees?.length ? selectedNominees : [];
    let totalShares = 0;

    listToCheck.length !== 0 &&
      listToCheck.map((item: any) => {
        totalShares += item.share ? parseFloat(item.share) : 0;
      });

    return totalShares;
  };

  const toggleOtpModal = (calledFrom: string) => {
    setOtpModal(!isOtpModal);

    if (calledFrom === 'OTP_VERIFIED') {
      getAssets();
      toggleModal();
    }
  };

  const addAssetByAdmin = async () => {
    saveAsset('Add_BY_ADMIN');
  };

  const saveAsset = async (calledFrom = 'ADD_BY_USER') => {
    setErrorMessage({ value: '', label: '' });
    try {
      if (isValidated()) {
        setIsLoading(true);
        const unique_id = getUniqueIdentifier();

        const assetValuesTemp: any = {};
        assetValueList.length !== 0 &&
          assetValueList.map((item: any) => {
            assetValuesTemp[item.assetName] = {
              validThrough: item.validThrough ? moment(item.validThrough).format('YYYY-MM-DD') : '',
              amount: item.amount ? parseFloat(item.amount) : 0,
            };
          });
        if (isEditing) {
          const payload: DataType = {
            assetId: asset.id,
            unique_id,
            type: assetType.value,
            provider: provider,
            asset_name: assetName,
            issuer_bank: provider,
            additionalInfo: {
              notes,
              address,
            },

            asset_value: assetValuesTemp,
          };

          if (selectedNominees.length && selectedNominees.every((item: any) => item.id !== null)) {
            payload.nominee_details = selectedNominees.map((item: any) => {
              return { nominee_id: item.id, share: parseInt(item.share) };
            });
          }
          if (assetCategory.label !== 'Property') {
            payload.validThrough = moment(expiry, 'MM/YY').format('YYYY-MM-DD');
          }
          if (calledFrom === 'Add_BY_ADMIN') {
            if (['admin', 'super_admin'].includes(user?.role)) {
              payload.userId = +params?.userId || '';
              payload.action = 'edit_asset';
            }
            const response = await api.admin.adminAddAsset(payload);

            if (response) {
              setOtpId(response?.id);
              setOtpModal(!isOtpModal);
            }
          } else {
            await api.assets.update(payload);
            sessionStorage.setItem('Asset Data', '');
            getAssets();
            toggleModal();

            toast.success('Asset updated successfully.');
          }
        } else {
          const payload: DataType = {
            unique_id,
            type: assetType.value,
            provider: provider,
            asset_name: assetName,
            issuer_bank: provider,
            additionalInfo: {
              notes,
              address,
            },

            asset_value: assetValuesTemp,
          };
          if (selectedNominees.length && selectedNominees.every((item: any) => item.id !== null)) {
            payload.nominee_details = selectedNominees.map((item: any) => {
              return { nominee_id: item.id, share: parseInt(item.share) };
            });
          }
          if (assetCategory.label !== 'Property') {
            payload.validThrough = moment(expiry).format('YYYY-MM-DD');
          }

          if (calledFrom === 'Add_BY_ADMIN') {
            if (['admin', 'super_admin'].includes(user?.role)) {
              payload.userId = +params?.userId || '';
              payload.action = 'add_assets';
            }

            const response = await api.admin.adminAddAsset(payload);
            if (response) {
              setOtpId(response?.id);
              setOtpModal(!isOtpModal);
            }
          } else {
            await api.assets.add(payload);
            sessionStorage.setItem('Asset Data', '');
            getAssets();
            toggleModal();
            toast.success('Asset added successfully.');
          }
        }
      }
    } catch (e) {
      toast.error((e as Error).message);
    }
    setIsLoading(false);
  };

  const selectNomineeHandler = (e: any, index: number) => {
    const isExisting =
      selectedNominees && selectedNominees.length !== 0 && selectedNominees.find((item: any) => item.id === e.value.id);

    if (!isExisting) {
      const newNomineeList = [...selectedNominees];

      newNomineeList[index] = { ...e.value };

      let defaultShare = 0;
      if (index === 0) {
        defaultShare = 100;
      } else {
        const totalShares = getTotalShare(newNomineeList);
        defaultShare = totalShares >= 100 ? 0 : 100 - totalShares;
      }
      newNomineeList[index] = { ...e.value, share: defaultShare };

      handleSelect(newNomineeList, setSelectedNominees, 'selectedNominees');
    }
  };

  const removeSelectedNominee = (nominee: any) => {
    const filtered = selectedNominees.filter((item: any) => item.id !== nominee.id);
    handleSelect(filtered, setSelectedNominees, 'selectedNominees');

    const filterNom = nomineeList.filter(
      (option: any) =>
        !filtered.find((nom: any, index: number) => {
          if (nom.id === option.id && index !== filtered.length - 1) {
            return nom;
          }
        }),
    );

    handleSelect(filterNom, setNominees, 'nominees');
  };

  const handleMoreNominee = () => {
    const restNominees = nominees.filter((option: any) => !selectedNominees.find((nom: any) => nom.id === option.id));
    setNominees(restNominees);
    setSelectedNominees([...selectedNominees, { id: null, share: 0 }]);
  };

  const updateShare = (i: number, e: ChangeEvent<HTMLInputElement>) => {
    setErrorMessage({ label: '', value: '' });
    const nominee = selectedNominees[i];
    nominee.share = e.target.value;
    handleSelect(
      [...selectedNominees.slice(0, i), nominee, ...selectedNominees.slice(i + 1)],
      setSelectedNominees,
      'selectedNominees',
    );
  };

  const handleCancel = () => {
    sessionStorage.setItem('Asset Data', '');
    toggleModal();
  };

  const expiryHandler = (e: any) => {
    setErrorMessage({ value: '', label: '' });
    setExpiry(e);
    handleSelect(e, setExpiry, 'expiry');
  };

  const isValidated = () => {
    setErrorMessage({ label: '', value: '' });
    let isOkay = true;
    if (!assetCategory || !assetType || !provider.trim().length || !assetName?.trim().length) {
      isOkay = false;
    }

    if (assetCategory.label !== 'Property' && !moment(expiry).isValid()) {
      isOkay = false;
    }

    if (assetCategory.label === 'Card' && cardNumber.trim().length < 4) {
      setErrorMessage({
        label: 'CARD_NUMBER',
        value: 'Please fill atleast 4 digits!',
      });
      isOkay = false;
    }

    if (assetCategory.label === 'Insurance' && insuranceNumber.trim()?.length < 4) {
      setErrorMessage({
        label: 'INSURANCE_NUMBER',
        value: 'Please fill atleast 4 digits!',
      });
      isOkay = false;
    }

    if (assetCategory.label === 'Bank Account' && accountNumber.trim()?.length < 4) {
      setErrorMessage({
        label: 'Account_NUMBER',
        value: 'Please fill atleast 4 digits!',
      });
      isOkay = false;
    }
    if (selectedNominees.length && selectedNominees.every((item: any) => item.id !== null)) {
      const totalShares = getTotalShare();

      if (totalShares < 100 || totalShares > 100) {
        setErrorMessage({ value: 'Sum of nominees share should be 100!', label: 'NOMINEES_SUM' });
        isOkay = false;
      }
    }

    if (assetValueList && assetValueList.length !== 0) {
      let assetEmpty = '';
      assetValueList.map((item: any) => {
        if (!item.assetName || !item.amount || !item.validThrough) {
          assetEmpty = !item.assetName
            ? 'Please enter Asset Name!'
            : !item.amount
            ? 'Please enter Asset Amount!'
            : !item.validThrough
            ? 'Please enter Valid Till Date!'
            : '';
        }
      });

      if (assetEmpty !== '') {
        setErrorMessage({ value: assetEmpty, label: 'ASSET_VALUE' });
        isOkay = false;
      }
    }

    return isOkay;
  };

  const isRequiredFilled = () => {
    let isOkay = true;
    if (!assetCategory || !assetType || !provider.trim().length || !assetName.trim().length) {
      isOkay = false;
    }

    if (assetCategory.label !== 'Property' && !moment(expiry).isValid()) {
      isOkay = false;
    }

    if (!isOkay) {
      toast.error('Please fill all the required fields!');
    }
    return isOkay;
  };

  const handleAssetValue = (value: string, name: string, index: number) => {
    setErrorMessage({ label: '', value: '' });

    if (name === 'amount' && parseFloat(value) <= 0) {
      setErrorMessage({ label: 'ASSET_AMOUNT', value: 'Asset amount should be more than 0!' });
    } else {
      let tempAssetList = [...assetValueList];
      tempAssetList = tempAssetList.map((item, idx) => {
        if (index === idx) {
          item[name] = value;
        }
        return item;
      });
      handleSelect(tempAssetList, setAssetValueList, 'assetValueList');
    }
  };

  const handleSelect = (e: any, handler: (_args: any) => void, calledFrom: string) => {
    handler(e);
    if (!isEditing) {
      setSessionData(e, calledFrom);
    }
  };

  const handleCardInput = async (value: string) => {
    const trimValue = value.replaceAll(' ', '');
    if (trimValue && trimValue.length > 16) {
      return false;
    }

    const intValue = +trimValue;
    if (IsInputNumber(intValue)) {
      const newVal = formatCardNumber(value);

      handleSelect(newVal, setCardNumber, 'cardNumber');
      if (value.replace(/\s+/g, '').length === 6) {
        setErrorMessage({ label: '', value: '' });
        fetchCardDetails(value.replace(/\s+/g, ''));
      }
    }
  };

  const getValueLabel = (value: any) => {
    if (!value) return null;
    return { value: value.id, label: `${value.name} (${value.relation})` };
  };

  return (
    <Modal
      isOpen={openModal}
      toggle={toggleModal}
      // backdrop="static"
      fade={false}
      className="right-modal"
    >
      {/* <div className="modal-dialog"> */}
      <ModalBody>
        <Flex>
          <AssetDetailsContainer>
            <Title>Asset Details</Title>

            <FormField>
              <FieldLabel>
                Asset Category<RequiredField>*</RequiredField>
              </FieldLabel>
              <SelectWrapper
                options={assetCategoryList}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setAssetType('');
                  handleSelect(e, setAssetCategory, 'assetCategory');
                }}
                value={assetCategory}
                placeholder="Select Asset Category"
                isDisabled={isEditing}
              />
            </FormField>
            {assetCategory && assetCategory.label && (
              <FormField>
                <FieldLabel>
                  Asset Type<RequiredField>*</RequiredField>
                </FieldLabel>
                <SelectWrapper
                  options={assetOptions}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => handleSelect(e, setAssetType, 'assetType')}
                  value={assetType}
                  placeholder="Select Asset Type"
                />
              </FormField>
            )}
            {assetCategory && assetCategory.label == 'Card' && (
              <FormField>
                <FieldLabel>
                  Card Number<RequiredField>*</RequiredField>
                </FieldLabel>
                <InputWrapper
                  placeholder="9123-xxxx-xxxx-4170"
                  type="text"
                  onChange={(e: ChangeEvent<HTMLInputElement>) => handleCardInput(e.target.value)}
                  value={cardNumber}
                  errorMessage={errorMessage.label === 'CARD_NUMBER' ? errorMessage.value : ''}
                />
              </FormField>
            )}

            {assetCategory && assetCategory.label && (
              <FormField>
                <FieldLabel>
                  {`Provider ${assetCategory.label == 'Property' ? '(Society - if any)' : ''}`}
                  <RequiredField>*</RequiredField>
                </FieldLabel>
                <InputWrapper
                  placeholder="Provider Name"
                  type="text"
                  onChange={(e: ChangeEvent<HTMLInputElement>) => handleSelect(e.target.value, setProvider, 'provider')}
                  value={provider}
                />
              </FormField>
            )}

            {assetCategory && assetCategory.label && (
              <FormField>
                <FieldLabel>
                  Asset Name<RequiredField>*</RequiredField>
                </FieldLabel>
                <InputWrapper
                  placeholder={`${
                    assetCategory.label == 'Insurance'
                      ? 'Policy/Scheme Name'
                      : assetCategory.label == 'Card'
                      ? 'ICICI Ruby Amex + Master Card'
                      : 'Asset Name'
                  }  `}
                  type="text"
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    handleSelect(e.target.value, setAssetName, 'assetName')
                  }
                  value={assetName}
                />
              </FormField>
            )}

            {assetCategory && assetCategory.label == 'Insurance' && (
              <FormField>
                <FieldLabel>
                  Identifier<RequiredField>*</RequiredField>
                </FieldLabel>
                <InputWrapper
                  placeholder="Policy No/ID"
                  type="text"
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    handleSelect(e.target.value, setInsuranceNumber, 'insuranceNumber')
                  }
                  value={insuranceNumber}
                  maxLength={30}
                  errorMessage={errorMessage.label === 'INSURANCE_NUMBER' ? errorMessage.value : ''}
                />
              </FormField>
            )}
            {assetCategory && assetCategory.label == 'Bank Account' && (
              <FormField>
                <FieldLabel>
                  Identifier<RequiredField>*</RequiredField>
                </FieldLabel>
                <InputWrapper
                  placeholder="Account Number"
                  type="text"
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    if (IsInputNumber(e.target.value)) {
                      handleSelect(e.target.value, setAccountNumber, 'accountNumber');
                    }
                  }}
                  value={accountNumber}
                  maxLength={30}
                  errorMessage={errorMessage.label === 'Account_NUMBER' ? errorMessage.value : ''}
                />
              </FormField>
            )}

            {assetCategory && assetCategory.label == 'Property' && (
              <FormField>
                <FieldLabel>Address</FieldLabel>
                <InputWrapper
                  placeholder="Address"
                  type="text"
                  onChange={(e: ChangeEvent<HTMLInputElement>) => handleSelect(e.target.value, setAddress, 'address')}
                  value={address}
                />
              </FormField>
            )}

            {assetType?.value === 'mutual_fund' && (
              <FormField>
                <FieldLabel>Folio Number</FieldLabel>
                <InputWrapper
                  placeholder="9123-xxxx-xxxx-4170"
                  type="text"
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    handleSelect(e.target.value, setFolioNumber, 'folioNumber')
                  }
                  value={folioNumber}
                />
              </FormField>
            )}
            {assetType?.value === 'bank_account' && (
              <FormField>
                <FieldLabel>Bank Account Number</FieldLabel>
                <InputWrapper
                  placeholder="9123-xxxx-xxxx-4170"
                  type="text"
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    handleSelect(e.target.value, setAccountNumber, 'accountNumber')
                  }
                  value={accountNumber}
                  maxLength={30}
                />
              </FormField>
            )}
            {assetCategory && assetCategory.label !== 'Property' && (
              <>
                <FormField>
                  <FieldLabel>
                    Valid Thru <RequiredField>*</RequiredField>
                  </FieldLabel>
                  <div style={{ width: '19rem' }}>
                    <DatePickerInput
                      className="form-control "
                      placeholderText="MM/YY"
                      selected={moment(expiry).isValid() ? new Date(expiry) : null}
                      onChange={expiryHandler}
                      dateFormat="MM/yy"
                      showMonthYearPicker
                      showFullMonthYearPicker
                    />
                    {errorMessage.label === 'VALID_THRU' && <ErrorMessage>{errorMessage.value}</ErrorMessage>}
                  </div>
                </FormField>
              </>
            )}

            <FormField>
              <FieldLabel>Notes</FieldLabel>
              <InputWrapper
                placeholder="Your description here."
                type="text"
                onChange={(e: ChangeEvent<HTMLInputElement>) => handleSelect(e.target.value, setNotes, 'notes')}
                value={notes}
              />
            </FormField>
          </AssetDetailsContainer>

          <NomineeDetailsContainer>
            <Title>Nominee Details</Title>
            {!nomineeList.length ? (
              <NoNomineeContainer>
                <NoNomineeText>{`There are no nominees ${
                  user?.role === 'user' ? 'in your account.' : '.'
                }`}</NoNomineeText>
                {user?.role === 'user' && (
                  <Button
                    onClick={() => {
                      toggleModal();
                      // dispatch(closeModal(null));
                      history.replace(NOMINEES);
                    }}
                  >
                    Add Nominees
                  </Button>
                )}
              </NoNomineeContainer>
            ) : (
              nomineeList?.length &&
              selectedNominees?.length &&
              selectedNominees.map((item: any, idx: number) => (
                <FormField key={item.idx}>
                  <SelectWrapper
                    options={
                      nominees
                        ? nominees.map((item: any) => {
                            return { value: item, label: `${item.name} (${item.relation})` };
                          })
                        : []
                    }
                    onChange={(e: ChangeEvent<HTMLInputElement>) => selectNomineeHandler(e, idx)}
                    placeholder={'Add Nominee'}
                    isClearable={false}
                    value={getValueLabel(item?.name ? item : '')}
                    isDisabled={idx !== selectedNominees.length - 1}
                  />

                  <NomineeShareWrapper>
                    <InputWrapper
                      value={item.share}
                      onChange={(e: any) => {
                        if (IsInputNumber(e.target.value) && e.target.value <= 100) {
                          updateShare(idx, e);
                        }
                      }}
                      placeholder="Share %"
                      width={'100px'}
                      maxLength={3}
                    />

                    {nominees.length &&
                    idx === selectedNominees.length - 1 &&
                    selectedNominees.length !== nomineeList.length &&
                    !selectedNominees.some((nom: any) => nom.id === null) ? (
                      <Button isPrimary onClick={handleMoreNominee} style={{ marginLeft: '16px' }}>
                        <FontAwesomeIcon icon={faPlus} size="xs" style={{ marginRight: '5px' }} />
                        Add More
                      </Button>
                    ) : null}
                    {idx > 0 && (
                      <DeleteIcon onClick={() => removeSelectedNominee(item)} style={{ marginTop: '8px' }}>
                        <FontAwesomeIcon icon={faXmark} size="lg" />
                      </DeleteIcon>
                    )}
                  </NomineeShareWrapper>
                </FormField>
              ))
            )}

            {errorMessage.label === 'NOMINEES_SUM' && <ErrorMessage>{errorMessage.value}</ErrorMessage>}
          </NomineeDetailsContainer>
        </Flex>
        <AssetValueContainer>
          <Title>Asset Value</Title>
          {errorMessage.label === 'ASSET_AMOUNT' && <ErrorMessage>{errorMessage.value}</ErrorMessage>}

          {assetValueList.length !== 0 &&
            assetValueList.map((item: any, idx: number) => {
              return (
                <PreferenceWrapper key={idx}>
                  <PreferenceSelectWrapper>
                    <InputWrapper
                      name="assetName"
                      placeholder="Asset Name"
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        handleAssetValue(e.target.value, e.target.name, idx)
                      }
                      value={item.assetName}
                      width={'155px'}
                    />
                  </PreferenceSelectWrapper>
                  <PreferenceText>Of Amount</PreferenceText>

                  <InputWithIcon>
                    <FontAwesomeIcon icon={faIndianRupeeSign} style={{ marginRight: '0.5rem' }} size="sm" />
                    <InputAmount
                      name="amount"
                      placeholder="Enter Amount"
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        if (IsInputNumber(e.target.value)) {
                          handleAssetValue(e.target.value, e.target.name, idx);
                        }
                      }}
                      value={item.amount}
                    />
                  </InputWithIcon>
                  <PreferenceText>
                    {`${assetType && assetType.label == 'Plot' ? 'As On Date' : 'this is valid till'}`}
                  </PreferenceText>
                  <PreferenceSelectWrapper>
                    <DatePickerInput
                      className="form-control "
                      placeholderText="DD-MM-YYYY"
                      selected={moment(item.validThrough).isValid() ? new Date(item.validThrough) : null}
                      onChange={(e: any) => handleAssetValue(e, 'validThrough', idx)}
                      dateFormat="dd-MM-yyyy"
                      minDate={moment().toDate()}
                    />
                  </PreferenceSelectWrapper>
                  {idx == assetValueList.length - 1 && (
                    <AddMoreContainer>
                      <Button isPrimary onClick={() => addNewAsset()}>
                        <FontAwesomeIcon icon={faPlus} size="xs" style={{ marginRight: '5px' }} />
                        Add More
                      </Button>

                      {assetValueList.length > 2 && (
                        <DeleteIcon onClick={() => deleteAsset(idx)} style={{ marginTop: '10px' }}>
                          <FontAwesomeIcon icon={faXmark} size="lg" />
                        </DeleteIcon>
                      )}
                    </AddMoreContainer>
                  )}
                </PreferenceWrapper>
              );
            })}
          {errorMessage.label === 'ASSET_VALUE' && <ErrorMessage>{errorMessage.value}</ErrorMessage>}
        </AssetValueContainer>
        <ButtonsContainer>
          <Button
            onClick={() => {
              isRequiredFilled() ? (user?.role === 'user' ? saveAsset() : addAssetByAdmin()) : null;
            }}
            // isSecondary={!isValidated()}
            isLoading={isLoading}
          >
            Save
          </Button>
          <Button isSecondary style={{ marginLeft: SPACING * 2 }} onClick={() => handleCancel()}>
            Cancel
          </Button>
        </ButtonsContainer>

        {isOtpModal && (
          <AdminOtpModal
            openModal={isOtpModal}
            toggleModal={toggleOtpModal}
            actionType={isEditing ? 'edit_asset' : 'add_assets'}
            otpId={otpId}
          />
        )}
      </ModalBody>
      {/* </div> */}
    </Modal>
  );
};
