import styled from 'styled-components';
import { Button } from '../../../ui-library/Button/Button';
import { __COLORS } from '../../Theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { Divider } from '../../../ui-library/Divider/Divider';

const Container = styled.div<{ margin?: string }>`
  margin-bottom: ${({ margin }) => (margin ? margin : '1.875rem')};
`;

const TopSection = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  padding: 20px 50px;
  @media (min-width: 320px) and (max-width: 890px) {
    padding: 1.25rem 1.125rem;
  }
`;

const Heading = styled.h1`
  color: ${__COLORS.ICON_COLOR};
  text-align: center;
  font-family: poppins;
  font-size: 2rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  @media (min-width: 320px) and (max-width: 786px) {
    font-size: 1.563rem;
  }
`;

type Props = {
  header: string;
  buttonText?: string;
  handleClick?: () => void;
  divider?: boolean;
  margin?: string;
};

export const PageHeading = ({ header, buttonText, handleClick, divider = true, margin }: Props) => {
  return (
    <Container margin={margin}>
      <TopSection>
        <Heading>{header.toUpperCase()}</Heading>
        {buttonText && (
          <Button onClick={handleClick} isPrimary>
            <FontAwesomeIcon icon={faPlus} size="xs" style={{ marginRight: '5px' }} />
            {buttonText.toUpperCase()}
          </Button>
        )}
      </TopSection>
      {divider ? <Divider /> : null}
    </Container>
  );
};
