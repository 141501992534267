import { useState } from 'react';
import styled from 'styled-components';
import Avatar from '../../assets/images/profileimage.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisVertical, faPenToSquare, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Popover, PopoverBody } from 'reactstrap';
import { __COLORS } from '../../shared/Theme';

const NomineeCard = styled.div`
  width: 15.625rem;
  height: 100%;
  flex-shrink: 0;
  border-radius: 0.625rem;
  background: ${__COLORS.WHITE_COLOR};
  margin: 10px 0px;
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.25);
  @media (min-width: 320px) and (max-width: 768px) {
    width: 20.625rem;
  }
`;

const MenuBar = styled.div`
  height: 2rem;
  text-align: right;
  align-items: center;
  padding: 0.87rem;
`;
const ImageBar = styled.div`
  width: 6.875rem;
  height: 6.875rem;
  flex-shrink: 0;
  margin: 0 auto;
  padding: 0.81rem;
`;
const ProfileImage = {
  border: `2px solid ${__COLORS.ICON_COLOR}`,
  borderRadius: '50px',
};

const ContentSection = styled.div`
  padding: 0 0.87rem;
`;

const ProfileName = styled.h1`
  color: ${__COLORS.ICON_COLOR};
  text-align: center;
  font-family: Poppins;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const Divider = styled.hr`
  border-top: 2px solid #c5bbbb;
  position: relative;
  text-align: center;
`;

const ProfileEmail = styled.p`
  color: #918989;
  text-align: center;
  font-family: Poppins;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
const TextDiv = styled.div`
  min-height: 3rem;
  align-items: center;
`;
const Text = styled.p`
  color: ${__COLORS.ICON_COLOR};
  text-align: center;
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const Separator = styled.hr`
  margin: 8px 0px;
  color: ${__COLORS.SECONDARY_DARK};
`;

const Container = styled.div`
  display: flex;
  gap: 3.125rem;
  margin: 0 auto;
  flex-wrap: wrap;

  @media (min-width: 320px) and (max-width: 768px) {
    justify-content: center;
  }
`;

const TooltipItem = styled.div`
  display: flex;
  cursor: pointer;
`;

const PopoverWrapper = styled(Popover)`
  .popover {
    border: none;
    margin-top: 5px;
    background-color: ${__COLORS.WHITE_COLOR};
    text-align: left;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    color: ${__COLORS.ICON_COLOR};
  }
`;

type NomineeType = {
  id: number;
  name: string;
  email: string;
  relation: string;
};

type Props = {
  nominees: NomineeType[];
  toggleModal: (_args: NomineeType) => void;
  toggleDeleteModal: (_args: NomineeType) => void;
};

const NomineesList = ({ nominees, toggleModal, toggleDeleteModal }: Props) => {
  const [isActionPopup, setActionPopup] = useState<boolean>(false);
  const [nomineeId, setNomineeId] = useState<number | null>(null);

  const toggleActionPopup = (id: number | null) => {
    setActionPopup(!isActionPopup);
    setNomineeId(id);
  };

  return (
    <Container>
      {nominees.map((nominee) => (
        <NomineeCard key={nominee.id}>
          <MenuBar>
            <FontAwesomeIcon
              icon={faEllipsisVertical}
              id={`ActionPopup${nominee.id}`}
              onClick={() => toggleActionPopup(nominee.id)}
              style={{ cursor: 'pointer' }}
            />
          </MenuBar>
          <ImageBar>
            <img style={ProfileImage} src={Avatar} alt="" />
          </ImageBar>
          <ContentSection>
            <ProfileName>{nominee?.name}</ProfileName>
            <ProfileEmail>{nominee?.email}</ProfileEmail>
          </ContentSection>
          <Divider />
          <TextDiv>
            <Text>{nominee?.relation}</Text>
          </TextDiv>

          <PopoverWrapper
            placement="bottom"
            isOpen={isActionPopup && nomineeId === nominee.id ? true : false}
            target={`ActionPopup${nominee.id}`}
            toggle={() => toggleActionPopup(nominee.id)}
            hideArrow={false}
            trigger="legacy"
          >
            <PopoverBody>
              <TooltipItem
                onClick={() => {
                  toggleActionPopup(null);
                  toggleModal(nominee);
                }}
              >
                <FontAwesomeIcon icon={faPenToSquare} style={{ marginTop: '2px', marginRight: '5px' }} /> Edit
              </TooltipItem>
              <Separator />
              <TooltipItem
                onClick={() => {
                  toggleActionPopup(null);
                  toggleDeleteModal(nominee);
                }}
              >
                <FontAwesomeIcon icon={faTrash} style={{ marginTop: '2px', marginRight: '5px' }} />
                Delete
              </TooltipItem>
            </PopoverBody>
          </PopoverWrapper>
        </NomineeCard>
      ))}
    </Container>
  );
};

export default NomineesList;
