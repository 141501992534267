import { makeRequest, post, put, get } from './index';

export const users = {
  create: async (userCreateRequest: any) => post('/user', userCreateRequest),
  getUser: async () => makeRequest('/user', {}),
  update: async (userUpdateRequest: any) => put('/user', userUpdateRequest),
  createPreference: async (preferenceObj: any) => post('/communication/preference', preferenceObj),
  updatePreference: async (preferenceObj: any) => put('/communication/preference', preferenceObj),
  getPreference: async () => get('/communication/preference'),
  verifyEmail: async (token: string) => get('/auth/verify-email?token=' + token),
  getEmailVerificationLink: async () => get('/auth/verification-email'),
  getPhoneVerificationLink: async () => get('/auth/send-otp'),
  // get2FactorVerification: async (details: string, otp: string, hostValue: string) =>
  //   get(`/auth/SMS/VERIFY/${details}/${otp}`, hostValue),
  verifyEmailOtp: async (otpPayload: any) => post('/auth/verify-otp ', otpPayload),
  forgotPassword: async (emailPayload: any) => post('/auth/forget-password ', emailPayload),
  resetPassword: async (passwordPayload: any, token: string) =>
    post('/auth/reset-password?token=' + token, passwordPayload),
  verifyWellbeing: async (token: string) => get('/communication/well-being?token=' + token),
  getNomineeConfirmation: async (status: boolean, token: string) =>
    get('/communication/nominee-confirmation?token=' + token + '&status=' + status),
  logout: async () => post('/auth/logout', {}),
  sendOtp: async (otpPayload: any) => post('/auth/send-otp ', otpPayload),
  verifyMobileOtp: async (otpPayload: any) => post('/auth/verify-mobile-otp ', otpPayload),
};
