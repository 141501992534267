import styled from 'styled-components';
import ReactPaginate from 'react-paginate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnglesLeft, faAnglesRight } from '@fortawesome/free-solid-svg-icons';
import { __COLORS } from '../../shared/Theme';

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: end;
  margin-top: 20px;

  .pagination {
    display: flex;
    list-style-type: none;
    padding-left: 0;
  }

  .pagination li {
    margin-right: 10px;
  }

  .pagination li a {
    color: black;
    text-decoration: none;
    padding: 5px 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    transition: background-color 0.3s;
  }

  .pagination .active a {
    background-color: ${__COLORS.PRIMARY};
    color: white;
  }
`;

type Props = {
  // options: Array<Record<string, unknown>>;
  onChangeHandler: (_args: any) => void;
  totalCount: number;
};

export const Pagination = ({ totalCount, onChangeHandler }: Props) => {
  return (
    <PaginationWrapper>
      <ReactPaginate
        previousLabel={<FontAwesomeIcon icon={faAnglesLeft} />}
        nextLabel={<FontAwesomeIcon icon={faAnglesRight} />}
        breakLabel={'...'}
        breakClassName={'break-me'}
        pageCount={Math.ceil(totalCount / 10)}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={(event) => onChangeHandler(event.selected + 1)}
        containerClassName={'pagination'}
        activeClassName={'active'}
      />
    </PaginationWrapper>
  );
};
