import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import BgImg from '../../../assets/images/background.png';
import LocationIcon from '../../../assets/images/LocationIcon.svg';
import { Tooltip } from 'reactstrap';
import { __COLORS } from '../../Theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowRightFromBracket,
  faPenToSquare,
  faDisplay,
  faBars,
  faChevronDown,
} from '@fortawesome/free-solid-svg-icons';
import { faClock, faCircleQuestion } from '@fortawesome/free-regular-svg-icons';
import { useDispatch, useSelector, RootStateOrAny } from 'react-redux';
import { toast } from 'react-toastify';
import { setLoginState } from '../../../redux/app/appSlice';
import { LoginStates } from '../../types';
import { useHistory, useLocation } from 'react-router-dom';
import moment from 'moment';
import NotificationPanel from '../NotificationPanel';
import { useApi } from '../../api';
import { setNotification } from '../../../redux/notification/notificationSlice';
import VerificationModal from '../Modal/VerificationModal';
import { MobileSidebar } from './MobileSidebar';
import {
  PROFILE_SETTINGS,
  SUPPORT,
  ADMIN_LOGIN,
  LOGIN,
  USER_MANAGEMENT,
  PORTFOLIO,
  PREFERENCES,
  // BILLING,
  NOMINEES,
  ADMIN_USERS,
  USER_DETAILS,
} from '../../../routes/Routes';

const MainLogo = styled.div`
  color: ${__COLORS.WHITE_COLOR};
  font-family: Poppins;
  font-size: 2rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  align-items: baseline;
  display: flex;
  gap: 0.25rem;
`;

const AppStyle = styled.div`
  background: url(${BgImg});
  height: 8.5625rem;
  width: 100%;
  @media (min-width: 320px) and (max-width: 786px) {
    height: 6.5625rem;
    display: grid;
    align-items: center;
  }
`;

const Container = styled.div`
  flex-direction: column;
  padding: 0 3.125rem;
  @media (min-width: 786px) and (max-width: 1024px) {
    padding: 0 1rem;
    gap: 14px;
    display: flex;
  }
  @media (min-width: 320px) and (max-width: 786px) {
    padding: 0 1.125rem;
  }
`;

const List1 = styled.h1`
  color: ${__COLORS.WHITE_COLOR};
  font-family: Poppins;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;
const List1Details = styled.p`
  color: ${__COLORS.WHITE_COLOR};
  font-family: Poppins;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;
const TopMenu = styled.div`
  display: flex;
  gap: 1.9rem;
  align-items: center;
  float: right;
  padding: 0.625rem 0;
  justify-content: flex-end;
  margin: 0 auto;
  @media (min-width: 800px) and (max-width: 1024px) {
    gap: 0.35rem;
  }
  @media (min-width: 320px) and (max-width: 786px) {
    display: block;
  }
`;
const MenuLists = styled.div`
  display: flex;
  align-items: center;
  padding: 0 0.625rem;
  border-left: 1px solid ${__COLORS.WHITE_COLOR};
  @media (min-width: 320px) and (max-width: 786px) {
    border-left: 0px;
    position: relative;
  }
`;
const ListContent = styled.div`
  align-items: center;
  padding: 0.625rem;
  @media (min-width: 800px) and (max-width: 1024px) {
    padding: 0 0.5rem;
  }
`;
const BottomMenu = styled.div`
  display: flex;
  gap: 0.625rem;
  align-items: center;
  padding: 0.625rem 0;
  justify-content: space-between;
  width: 100%;
  margin: 0 auto;
`;
const MainMenues = styled.div`
  display: flex;
  gap: 5rem;
  align-items: center;
  @media (min-width: 800px) and (max-width: 1024px) {
    gap: 1.5rem;
  }
  @media (min-width: 320px) and (max-width: 786px) {
    display: none;
  }
`;
const LogoMenu = styled.div`
  display: flex;
  alignitems: center;
  gap: 7rem;
  @media (min-width: 320px) and (max-width: 1024px) {
    gap: 2rem;
  }
`;
const MenuLink = styled.a`
  color: ${__COLORS.WHITE_COLOR};
  text-align: right;
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
    text-underline-offset: 0.563rem;
    font-weight: 600;
    text-decoration-thickness: 2px;
  }
  &.active {
    text-decoration: underline;
    text-underline-offset: 0.563rem;
    font-weight: 600;
    text-decoration-thickness: 2px;
  }
  @media (min-width: 320px) and (max-width: 400px) {
    font-size: 0.875rem;
  }
`;
const ProfileSettings = styled.a`
  color: ${__COLORS.WHITE_COLOR};
  text-align: right;
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const ProfileDetail = styled.p`
  color: ${__COLORS.WHITE_COLOR};
  font-family: Poppins;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: right;
  @media (min-width: 786px) and (max-width: 1024px) {
    text-align: left;
  }
`;
const RightSideWrapper = styled.div`
  display: flex;
  gap: 20px;
`;

const HamBurgIcon = styled(FontAwesomeIcon)`
  color: ${__COLORS.WHITE_COLOR};
  @media (min-width: 786px) and (max-width: 2560px) {
    display: none;
  }
`;

const HelpHeader = styled.div`
  display: flex;
  gap: 10px;
  @media (min-width: 320px) and (max-width: 785px) {
    display: none;
  }
`;
const NotificationMobile = styled.div`
  @media (min-width: 320px) and (max-width: 785px) {
    position: absolute;
    right: 42px;
    top: 35px;
  }
`;

const TooltipWrapper = styled(Tooltip)`
  .tooltip-inner {
    margin-top: 5px;
    background-color: ${__COLORS.WHITE_COLOR};
    text-align: left;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    color: ${__COLORS.ICON_COLOR};
    padding: 8px;
  }
`;

const Separator = styled.hr`
  margin: 8px 0px;
  color: ${__COLORS.SECONDARY_DARK};
`;

const TooltipItem = styled.div`
  display: flex;
  cursor: pointer;
`;

const Topbar = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const api = useApi();
  const location = useLocation();

  const user = useSelector((s: RootStateOrAny) => s.users.user);
  const notification = useSelector((s: any) => s?.notifications?.notification);

  const [openSidebar, setOpenSidebar] = useState(false);
  const [isLogoutPopup, setLogoutPopup] = useState(false);
  const [notificationList, setNotificationList] = useState<any>([]);
  const [notificationOpen, setNotificationOpen] = useState(false);
  const [popoverOpen, setPopoverOpen] = useState(-1);
  const [openVerificationModal, setOpenVerificationModal] = useState('');
  const [activeMenuItem, setActiveMenuItem] = useState('');

  const isMobileView = () => {
    // You can define your own logic here to determine mobile view based on viewport width or device type
    return window.innerWidth <= 768; // Example: consider mobile if viewport width is 768px or less
  };

  useEffect(() => {
    if (notification.NotificationList) {
      setNotificationList(notification.NotificationList);
    }
  }, [notification]);

  useEffect(() => {
    setActiveMenuItem(location.pathname);
  }, [location.pathname]);

  const togglePopover = (key: number) => {
    if (key === popoverOpen) {
      setPopoverOpen(-1);
    } else {
      setPopoverOpen(key);
    }
  };

  const handleMenuClick = (link: string) => {
    setActiveMenuItem(link);
    setOpenSidebar(false);
    history.push(link);
  };

  const toggleNotification = () => setNotificationOpen(!notificationOpen);

  const handleNotificationClick = (value: any, e: any) => {
    e.stopPropagation();
    handleActionClick(value, 'seen', e);

    if (value.text) {
      if (value.text.includes('Email')) {
        setOpenVerificationModal('EMAIL_VERIFICATION');
        toggleNotification();
      } else if (value.text.includes('Phone')) {
        setOpenVerificationModal('PHONE_VERIFICATION');
        toggleNotification();
      }
    }
  };

  const handleActionClick = async (item: any, action: string, e: any) => {
    e.stopPropagation();

    try {
      await api.notifications.setNotification(item.id, action);
      const notificationResponse = await api.notifications.notificationList();
      notificationResponse && dispatch(setNotification(notificationResponse));
      toggleNotification();
      togglePopover(-1);
    } catch (e) {
      //
    }
  };

  const logoutHandler = async () => {
    try {
      await api.users.logout();
      sessionStorage.removeItem('token');
      sessionStorage.removeItem('emailVerification');
      sessionStorage.removeItem('phoneVerification');

      dispatch(setLoginState(LoginStates.LOGGED_OUT));
      history.replace(user.role === 'user' ? LOGIN : ADMIN_LOGIN);
      toast.success('You have successfully logged out!');
    } catch (e) {
      toast.error((e as Error).message);
    }
  };

  const menuList = [
    {
      name: 'User Management',
      path: [USER_MANAGEMENT, USER_DETAILS],
      isVisible: ['admin', 'super_admin'].includes(user?.role),
    },
    { name: 'Admin Users', path: [ADMIN_USERS], isVisible: user?.role === 'super_admin' },
    { name: 'Portfolio', path: [PORTFOLIO], isVisible: user?.role === 'user' },
    { name: 'Nominees', path: [NOMINEES], isVisible: user?.role === 'user' },
    { name: 'Preferences', path: [PREFERENCES], isVisible: user?.role === 'user' },
    // { name: 'Billing & Payment', path: [BILLING], isVisible: user?.role === 'user' },
  ];

  return (
    <>
      <AppStyle>
        <Container>
          <TopMenu>
            {!isMobileView() && (
              <>
                <List1>Last Login</List1>

                <MenuLists>
                  <FontAwesomeIcon icon={faClock} style={{ color: `${__COLORS.WHITE_COLOR}` }} />
                  <ListContent>
                    <List1>Time and Date</List1>
                    <List1Details>
                      {user && user.last_login && user.last_login.createdAt
                        ? `${moment(user.last_login.createdAt).format('DD MMM YYYY, hh:mm A Z')}`
                        : ''}
                    </List1Details>
                  </ListContent>
                </MenuLists>
              </>
            )}
            {!isMobileView() && (
              <MenuLists>
                <img src={LocationIcon} alt="Location Icon" />
                <ListContent>
                  <List1>Location</List1>
                  <List1Details>
                    {user && user.last_login && user.last_login.location
                      ? `${user.last_login.location.city ? `${user.last_login.location.city}, ` : ''} ${
                          user.last_login.location.country ? user.last_login.location.country : ''
                        }`
                      : ''}
                  </List1Details>
                </ListContent>
              </MenuLists>
            )}

            <MenuLists>
              {!isMobileView() && (
                <>
                  <FontAwesomeIcon icon={faDisplay} style={{ color: `${__COLORS.WHITE_COLOR}` }} />

                  <ListContent>
                    <List1>System</List1>
                    <List1Details>
                      {user && user.last_login && user.last_login.client
                        ? `${user.last_login.client.browser ? user.last_login.client.browser : ''}, ${
                            user.last_login.client.os ? user.last_login.client.os : ''
                          }`
                        : ''}
                    </List1Details>
                  </ListContent>
                </>
              )}
              {user?.role === 'user' && (
                <NotificationMobile>
                  <NotificationPanel
                    notificationList={notificationList}
                    toggleNotification={toggleNotification}
                    togglePopover={togglePopover}
                    popoverOpen={popoverOpen}
                    notificationOpen={notificationOpen}
                    handleNotificationClick={handleNotificationClick}
                    handleActionClick={handleActionClick}
                    notificationCount={notification?.unseen || 0}
                  />
                </NotificationMobile>
              )}
            </MenuLists>

            {!isMobileView() && (
              <MenuLists>
                <ListContent>
                  <List1>
                    <ProfileSettings>Profile Settings</ProfileSettings>

                    <FontAwesomeIcon
                      icon={faChevronDown}
                      id="LogoutPopup"
                      style={{ cursor: 'pointer', marginLeft: '4px' }}
                      onClick={() => setLogoutPopup(!isLogoutPopup)}
                    />
                  </List1>
                  <ProfileDetail>{`${user?.first_name || ''} ${user?.last_name || ''}`}</ProfileDetail>
                </ListContent>
                <TooltipWrapper
                  placement="bottom"
                  isOpen={isLogoutPopup}
                  target="LogoutPopup"
                  toggle={() => setLogoutPopup(!isLogoutPopup)}
                  hideArrow={false}
                  trigger="legacy"
                >
                  <div>
                    {user?.role === 'user' && (
                      <>
                        <TooltipItem
                          onClick={() => {
                            setLogoutPopup(!isLogoutPopup);
                            history.push(PROFILE_SETTINGS);
                          }}
                        >
                          <FontAwesomeIcon icon={faPenToSquare} style={{ marginTop: '2px', marginRight: '5px' }} /> Edit
                        </TooltipItem>
                        <Separator />
                      </>
                    )}

                    <TooltipItem
                      onClick={() => {
                        setLogoutPopup(!isLogoutPopup);
                        logoutHandler();
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faArrowRightFromBracket}
                        style={{ marginTop: '2px', marginRight: '5px' }}
                      />
                      Logout
                    </TooltipItem>
                  </div>
                </TooltipWrapper>
              </MenuLists>
            )}
          </TopMenu>
          <BottomMenu>
            <LogoMenu>
              <MainLogo>INSUREWILL</MainLogo>
              <MainMenues>
                {menuList.map((menu: any) => {
                  if (menu.isVisible) {
                    return (
                      <React.Fragment key={menu.name}>
                        <MenuLink
                          onClick={() => handleMenuClick(menu.path[0])}
                          className={menu.path.some((route: string) => activeMenuItem.includes(route)) ? 'active' : ''}
                        >
                          {menu.name}
                        </MenuLink>
                      </React.Fragment>
                    );
                  } else return null;
                })}
              </MainMenues>
            </LogoMenu>
            <RightSideWrapper>
              {user?.role === 'user' && (
                <>
                  <HelpHeader>
                    <MenuLink
                      onClick={() => handleMenuClick(SUPPORT)}
                      className={activeMenuItem === SUPPORT ? 'active' : ''}
                    >
                      Help & Support
                    </MenuLink>
                    <FontAwesomeIcon
                      icon={faCircleQuestion}
                      style={{ color: `${__COLORS.WHITE_COLOR}`, marginTop: '4px' }}
                    />
                  </HelpHeader>
                </>
              )}

              <HamBurgIcon icon={faBars} onClick={() => setOpenSidebar(true)} />
            </RightSideWrapper>
          </BottomMenu>
        </Container>
      </AppStyle>

      {/****************for mobile screens */}
      {openSidebar && (
        <MobileSidebar
          isOpen={openSidebar}
          togglemodal={setOpenSidebar}
          user={user}
          handleMenuClick={handleMenuClick}
          activeMenuItem={activeMenuItem}
          logoutHandler={logoutHandler}
          menuList={menuList}
        />
      )}
      {openVerificationModal !== '' && (
        <VerificationModal
          openVerificationModal={openVerificationModal}
          setOpenVerificationModal={setOpenVerificationModal}
        />
      )}
    </>
  );
};
export default Topbar;
