import { ReactNode } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  margin: 0 auto;
  padding: 0 50px;
  // overflow-x: auto;
  @media (min-width: 320px) and (max-width: 786px) {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 50px;
  }
`;

type Props = {
  children: ReactNode;
};

export const PageContent = ({ children }: Props) => {
  return <Container>{children}</Container>;
};
