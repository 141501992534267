import { useState, ChangeEvent } from 'react';
import styled from 'styled-components';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import { EXTRA_SMALL_DEVICES } from '../../shared/helpers/Mobile';
import { Button } from '../../ui-library';
import axios from 'axios';
import { IsInputNumber } from '../../shared/helpers/IsInputNumber';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import InputWrapper from '../../ui-library/Input/InputWrapper';
import { __COLORS } from '../../shared/Theme';

const BodyContainer = styled.div`
  padding: 20px;
`;
const ErrorMessage = styled.div`
  color: red;
  height: 30px;
`;

const InputRow = styled.div`
  margin: 22px 0 5px 0;
`;

export const Label = styled.p`
  font-weight: 300;
  text-transform: uppercase;
  font-size: 14px;
  color: gray;
  width: 30%;
  ${EXTRA_SMALL_DEVICES`
  width: 50%;
  `}
`;

const PopupHeadingdiv = styled.div`
  height: 4.87rem;
  padding: 1.31rem;
  border-bottom: 0.0625rem solid #e3dbdb;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const PopupHeading = styled.h1`
  color: ${__COLORS.ICON_COLOR};
  font-family: Poppins;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

const FieldLabel = styled.label`
  padding: 5px;
`;

type Props = {
  openModal: boolean;
  toggleModal: () => void;
};

const WellbeingOTPModal = ({ openModal, toggleModal }: Props) => {
  const [otp, setOtp] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
    if (IsInputNumber(e.target.value)) {
      setOtp(e.target.value);
    }
  };

  const verifyOtp = async () => {
    setErrorMessage('');

    try {
      if (validateOTP()) {
        await axios.get(`https://2factor.in/API/V1/e5b4e95d-6620-11ec-b710-0200cd936042/SMS/VERIFY/${''}/${otp}`);
      }
    } catch (e) {
      setErrorMessage('Wrong OTP!');
    }
  };

  const validateOTP = () => {
    if (otp.replace(/\s/g, '').length < 4) {
      setErrorMessage('Please enter valid OTP!');
      return false;
    } else {
      return true;
    }
  };

  return (
    <Modal isOpen={openModal} style={{ marginTop: '45px' }}>
      <PopupHeadingdiv>
        <PopupHeading>{'Wellbeing Confirmation'}</PopupHeading>
        <FontAwesomeIcon icon={faXmark} onClick={toggleModal} style={{ cursor: 'pointer' }} />
      </PopupHeadingdiv>

      <ModalBody>
        <BodyContainer>
          <InputRow>
            <FieldLabel>Please enter the OTP sent to your registered number</FieldLabel>
            <InputWrapper
              placeholder="Enter OTP"
              type="text"
              onChange={handleInput}
              value={otp}
              required
              maxLength="6"
              width={'100%'}
              style={{ height: '50px' }}
            />
          </InputRow>
          <ErrorMessage>{`${errorMessage}`}</ErrorMessage>
        </BodyContainer>
      </ModalBody>
      <ModalFooter>
        <Button onClick={toggleModal} isSecondary>
          Cancel
        </Button>
        <Button onClick={verifyOtp} isPrimary>
          Verify OTP
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default WellbeingOTPModal;
