import styled from 'styled-components';
import { __COLORS } from '../../shared/Theme';
import { Spinner } from 'reactstrap';

const ButtonContainer = styled.button<{ isSecondary?: boolean; isPrimary?: boolean }>`
  padding: 5px 20px;
  min-width: 6rem;
  min-height: 40px;
  flex-shrink: 0;
  border-radius: 0.3125rem;
  background: ${({ isSecondary }) => (isSecondary ? __COLORS.SECONDARY : __COLORS.PRIMARY)};
  color: ${({ isSecondary }) => (isSecondary ? __COLORS.ICON_COLOR : __COLORS.WHITE_COLOR)};
  border: none;
  &:hover {
  background: ${({ isSecondary }) => (isSecondary ? __COLORS.SECONDARY_DARK : __COLORS.PRIMARY_DARK)};

    }
  }

  transition: all 300ms;

  // & img {
  //   width: 50%;
  //   height: 36px;
  // }

`;

export const Button = (props: any) => {
  return (
    <ButtonContainer
      style={props.style}
      onClick={!props.isLoading && props.onClick}
      isSecondary={props.isSecondary}
      isPrimary={props.isPrimary}
      disabled={props.isLoading}
    >
      {props.isLoading && <Spinner size="sm" style={{ marginRight: '5px' }} />}
      {props.isLoading ? 'Saving...' : props.children}
    </ButtonContainer>
  );
};
