//endpoints
import { auth } from './auth';
import { notifications } from './notifications';
import { users } from './users';
import { nominees } from './nominees';
import { assets } from './assets';
import { billing } from './billing';
import { admin } from './admin';

const baseURL = process.env.REACT_APP_API_ENDPOINT + '/v1' || '/api/v1';
//const baseURL = 'https://silent-paper-45295.pktriot.net/api' + '/v1' || '/api/v1';

const _parseJSON = async (response: Response) => {
  return response.text().then(function (text) {
    return text ? JSON.parse(text) : {};
  });
};

export const makeRequest = async (endpoint: string, options?: any) => {
  const token = sessionStorage.getItem('token');

  const { headers = {}, ...customOptions } = options;

  const response = await fetch(`${baseURL}${endpoint}`, {
    headers: {
      'content-type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': true,
      ...headers,
      Authorization: token,
    },
    crossDomain: true,
    withCredentials: false,
    ...customOptions,
  });
  if (response.status === 401) {
    const url = window.location.href;
    sessionStorage.removeItem('token');
    if (url.includes('/admin')) {
      window.location.href = '/admin/login';
    } else {
      window.location.href = '/';
    }

    // store.dispatch(setLoginState(LoginStates.LOGGED_OUT));
  }
  if (response.status >= 500) {
    throw new Error('Application server is not responding');
  }
  if (response.status === 204) {
    return;
  }
  const json = await _parseJSON(response);
  if (!response.ok) {
    throw new Error(json.message);
  }
  return json;
};

export const post = async (endpoint: string, payload: any) =>
  makeRequest(endpoint, {
    method: 'POST',
    ...(payload ? { body: JSON.stringify(payload) } : {}),
  });

export const get = async (endpoint: string) =>
  makeRequest(endpoint, {
    method: 'GET',
  });

export const put = (endpoint: string, payload: any, signal?: any) =>
  makeRequest(endpoint, {
    method: 'PUT',
    signal,
    ...(payload ? { body: JSON.stringify(payload) } : {}),
  });

export const remove = (endpoint: string, payload?: any) =>
  makeRequest(endpoint, {
    method: 'DELETE',
    ...(payload ? { body: JSON.stringify(payload) } : {}),
  });

export const useApi = () => {
  return {
    healthCheck: { get: () => makeRequest('/') },
    auth,
    users,
    nominees,
    assets,
    billing,
    notifications,
    admin,
  };
};
