import {
  assetTypesInsurance,
  assetTypesBankAccount,
  assetTypesCard,
  assetTypesProperty,
} from '../../../shared/options';

const resolveAssetType = (type: string, calledFrom: string) => {
  // switch (type) {
  //   case 'credit_card':
  //     return 'Credit Card';
  //   case 'mutual_fund':
  //     return 'Mutual Fund';
  //   case 'insurance':
  //     return 'Insurance';
  //   case 'bank_account':
  //     return 'Bank Account';
  //   case 'debit_card':
  //     return 'Debit Card';
  // }
  const tempTypeList = [...assetTypesInsurance, ...assetTypesBankAccount, ...assetTypesCard, ...assetTypesProperty];
  let matchedLabel = '',
    matchedCategory = '';
  tempTypeList.find((item: any) => {
    if (item.value == type) {
      matchedLabel = item.label;
    }
  });

  if (type == 'health' || type == 'vehicle' || type == 'term') {
    matchedCategory = 'Insurance';
  } else if (type == 'single' || type == 'joint' || type == 'corporate') {
    matchedCategory = 'Bank Account';
  } else if (type == 'credit_card' || type == 'debit_card') {
    matchedCategory = 'Card';
  } else if (type == 'plot' || type == 'flat') {
    matchedCategory = 'Property';
  }
  if (calledFrom == 'CATEGORY') {
    return matchedCategory;
  } else {
    return matchedLabel;
  }
};

export { resolveAssetType };
