import { ChangeEvent, FormEvent, useState } from 'react';
import styled from 'styled-components';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import InputWrapper from '../../ui-library/Input/InputWrapper';
import TextAreaInput from '../../ui-library/Input/TextAreaInput';
import { SelectWrapper, Button } from '../../ui-library';
import { relations } from '../../shared/options';
import { useApi } from '../../shared/api';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { setUser } from '../../redux/users/userSlice';
import { IsInputNumber } from '../../shared/helpers/IsInputNumber';
import { __COLORS } from '../../shared/Theme';

const PopupHeadingdiv = styled.div`
  height: 4.87rem;
  padding: 1.31rem;
  border-bottom: 0.0625rem solid #e3dbdb;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const PopupHeading = styled.h1`
  color: ${__COLORS.ICON_COLOR};
  font-family: Poppins;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

const FormField = styled.div`
  display: flex;
  margin-bottom: 10px;
  @media (min-width: 320px) and (max-width: 500px) {
    flex-wrap: wrap;
    width: 100%;
  }
`;

const FieldLabel = styled.label`
  width: 8rem !important;
  padding: 5px;
  display: flex;
  align-items: center;
`;

interface MessageType {
  [key: string]: string;
}

type Props = {
  open: boolean;
  toggleModal: (_arg?: any) => void;
  nominee?: any;
  totalNominees: number;
};

const AddNomineeModal = ({ open, toggleModal, nominee, totalNominees }: Props) => {
  const api = useApi();
  const dispatch = useDispatch();

  const [name, setName] = useState(nominee?.name || '');
  const [relation, setRelation] = useState<any>(
    nominee?.relation ? { label: nominee.relation, value: nominee.relation } : null,
  );
  const [address, setAddress] = useState(nominee?.address || '');
  const [phoneNumber, setPhoneNumber] = useState(nominee?.phone || '');
  const [email, setEmail] = useState(nominee?.email || '');
  const [errorMessage, setErrorMessage] = useState<MessageType>({});
  const [isLoading, setIsLoading] = useState(false);

  const addNewNominee = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setErrorMessage({});

    try {
      if (validateForm()) {
        setIsLoading(true);

        if (nominee?.id) {
          await api.nominees.update({
            id: nominee.id,
            name,
            relation: relation.value || '',
            address,
            phone: phoneNumber,
            email,
            // dob: '2020-02-02',
          });

          toast.success('Nominee updated successfully.');
        } else {
          await api.nominees.create({
            name,
            relation: relation.value || '',
            address,
            phone: phoneNumber,
            email,
            // dob: '2020-02-02',
          });

          toast.success('Nominee added successfully.');
        }

        const userResponse = await api.users.getUser();
        dispatch(setUser(userResponse.data));

        // const response = await api.nominees.getAll();
        // dispatch(setNominees(response.data));
        toggleModal();
      }
    } catch (e) {
      toast.error((e as Error).message);
    }
    setIsLoading(false);
  };

  const validateForm = () => {
    setErrorMessage({});
    const tempMessage = {
      email: '',
      phoneNumber: '',
      relation: '',
      nominee: '',
      name: '',
    };
    let result = true;
    if (totalNominees && totalNominees == 10) {
      tempMessage.nominee = 'You cannot add more than 10 nominees!';
      result = false;
    }
    if (relation === null) {
      tempMessage.relation = 'Please select relation!';
      result = false;
    }
    if (!/^[a-zA-Z0-9.!#$%&'+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)$/.test(email) || !email.trim().length) {
      tempMessage.email = 'Please enter valid email address!';
      result = false;
    }
    if (phoneNumber.length < 10 || !phoneNumber.trim().length) {
      tempMessage.phoneNumber = 'Phone number must be 10 digits long!';
      result = false;
    }
    if (!name.trim().length) {
      tempMessage.name = 'Please enter valid nominee name!';
      result = false;
    }
    setErrorMessage({ ...tempMessage });
    return result;
  };

  return (
    <Modal isOpen={open} centered={true} size="md">
      <PopupHeadingdiv>
        <PopupHeading>Input Nominee Details</PopupHeading>
        <FontAwesomeIcon icon={faXmark} onClick={toggleModal} style={{ cursor: 'pointer' }} />
      </PopupHeadingdiv>
      <ModalBody>
        <FormField>
          <FieldLabel>Name </FieldLabel>
          <InputWrapper
            value={name}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
            placeholder="Full Name"
            errorMessage={errorMessage?.name}
          />
        </FormField>
        <FormField>
          <FieldLabel>Relation </FieldLabel>
          <SelectWrapper
            options={relations}
            value={relation}
            onChange={setRelation}
            errorMessage={errorMessage?.relation}
            placeholder="Relation"
          />
        </FormField>
        <FormField>
          <FieldLabel>Phone </FieldLabel>
          <InputWrapper
            value={phoneNumber}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              if (IsInputNumber(e.target.value)) {
                setPhoneNumber(e.target.value);
              }
            }}
            placeholder="Phone Number"
            errorMessage={errorMessage?.phoneNumber}
            maxLength={10}
          />
        </FormField>
        <FormField>
          <FieldLabel>Email </FieldLabel>
          <InputWrapper
            value={email}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
            placeholder="Email"
            errorMessage={errorMessage?.email}
          />
        </FormField>
        <FormField>
          <FieldLabel>Address </FieldLabel>

          <TextAreaInput
            value={address}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setAddress(e.target.value)}
            placeholder="Full Address"
          />
        </FormField>
      </ModalBody>
      <ModalFooter style={{ gap: '1rem' }}>
        <Button isSecondary onClick={toggleModal}>
          Close
        </Button>
        <Button isPrimary onClick={addNewNominee} isLoading={isLoading}>
          Save Changes
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AddNomineeModal;
