import { useEffect, useState } from 'react';
import { useDispatch, useSelector, RootStateOrAny } from 'react-redux';
import styled from 'styled-components';
import { useApi } from '../../../../shared/api';
import { setAssets } from '../../../../redux/assets/assetsSlice';
import { toast } from 'react-toastify';
import AssetListTable from '../../../Portfolio/AssetListTable';
import { Button } from '../../../../ui-library';
import { useParams } from 'react-router-dom';
import { AddAssetModal } from '../../../Portfolio/AddAssetModal';

const ButtonWrapper = styled.div`
  margin: 20px 0px;
  text-align: end;
`;

type Props = {
  nomineeData: any;
};

type DataType = {
  [key: string]: unknown;
};

const AssetDetails = ({ nomineeData }: Props) => {
  const api = useApi();
  const dispatch = useDispatch();
  const { userId }: any = useParams();

  const { assets, count } = useSelector((s: RootStateOrAny) => s.assets);

  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedFilter, setSelectedFilter] = useState({
    name: '',
    order: true,
  });
  const [isAssetModal, setAssetModal] = useState(false);
  const [selectedAsset, setSelectedAsset] = useState({});

  useEffect(() => {
    getAssets();
  }, []);

  useEffect(() => {
    if (userId) {
      getAssets();
    }
  }, [currentPage, selectedFilter, userId]);

  const getAssets = async () => {
    try {
      const response = await api.assets.getAll({
        pageNo: currentPage,
        sortedTerm: selectedFilter.name,
        isAscending: selectedFilter.order,
        userId,
      });
      dispatch(setAssets(response.data));
      setIsLoading(false);
    } catch (e) {
      toast.error((e as Error).message);
      setIsLoading(false);
    }
  };

  const handleFilter = (column: string, value: boolean) => {
    setSelectedFilter({ name: column, order: value });
  };

  const toggleAssetModal = (data: DataType = {}) => {
    setAssetModal(!isAssetModal);
    setSelectedAsset(data);
  };

  return (
    <>
      <ButtonWrapper>
        <Button
          isPrimary
          onClick={() => {
            toggleAssetModal();
          }}
        >
          + ADD NEW
        </Button>
      </ButtonWrapper>

      <AssetListTable
        tableData={assets}
        count={count}
        isLoading={isLoading}
        selectedFilter={selectedFilter}
        handleFilter={handleFilter}
        setCurrentPage={(count: number) => {
          setCurrentPage(count);
        }}
        toggleAssetModal={toggleAssetModal}
      />
      {isAssetModal && (
        <AddAssetModal
          openModal={isAssetModal}
          toggleModal={toggleAssetModal}
          asset={selectedAsset}
          nomineeData={nomineeData}
          getAssets={getAssets}
        />
      )}
    </>
  );
};

export default AssetDetails;
