import { useState } from 'react';
import styled from 'styled-components';
import { __COLORS } from '../../../../shared/Theme';
import { Button } from '../../../../ui-library';
import ConfirmActionModal from './ConfirmActionModal';

const MainWrapper = styled.div`
  flex -shrink: 0;
  border-Radius: 0.625rem;
  background: ${__COLORS.WHITE_COLOR};
  margin: 1.5rem 0;
 
`;

const SectionHeading = styled.h1`
  color: ${__COLORS.ICON_COLOR};
  text-align: center;
  font-family: Poppins;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  @media (min-width: 320px) and (max-width: 500px) {
    font-size: 1rem;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1.37rem 1.44rem;
  border-bottom: 0.0625rem solid #eeebeb;
`;

const ValueWrapper = styled.input`
  margin-left: 5px;
  color: ${__COLORS.VALUE_COLOR};
  width: 100%;
`;

const ContentWrapper = styled.div`
  padding: 1.44rem;
`;

const EditIconWrapper = styled.span`
  color: ${__COLORS.ICON_COLOR};
  cursor: pointer;
  display: flex;
`;

const FormField = styled.div`
  padding: '0 0.88rem 0 0.88rem';
  display: flex;
  @media (min-width: 320px) and (max-width: 500px) {
    flex-wrap: wrap;
  }
`;
const FieldLabel = styled.label`
  width: 8rem;
  padding: 5px;
  align-items: center;
`;
const Seperator = styled.hr`
  margin: 1.5rem 0;
  color: inherit;
  border: 0;
  border-top: var(--bs-border-width) solid;
  opacity: 0.15;
  @media (min-width: 320px) and (max-width: 786px) {
    margin: 1rem 0;
  }
`;

type Props = {
  userDetails: any;
  handleUser: () => void;
};

const UserInfo = ({ userDetails, handleUser }: Props) => {
  const [isConfirmModal, setConfirmModal] = useState(false);

  const toggleModal = () => {
    setConfirmModal(!isConfirmModal);
  };
  return (
    <MainWrapper>
      <HeaderContainer>
        <SectionHeading>BASIC INFO</SectionHeading>
        <EditIconWrapper>
          <Button onClick={toggleModal} isPrimary>
            {`${userDetails?.is_blocked ? 'UnBlock' : 'Block'} User`}
          </Button>
        </EditIconWrapper>
      </HeaderContainer>
      <ContentWrapper>
        <FormField>
          <FieldLabel>First Name</FieldLabel>
          <ValueWrapper readOnly value={userDetails?.first_name || '-'} />
        </FormField>
        <Seperator />
        <FormField>
          <FieldLabel>Last Name</FieldLabel>
          <ValueWrapper readOnly value={userDetails?.last_name || '-'} />
        </FormField>
        <Seperator />
        <FormField>
          <FieldLabel>Email </FieldLabel>
          <ValueWrapper readOnly value={userDetails?.email || '-'} />
        </FormField>
        <Seperator />
        <FormField>
          <FieldLabel>Phone </FieldLabel>
          <ValueWrapper readOnly value={userDetails?.phone || '-'} />
        </FormField>
        <Seperator />
        <FormField>
          <FieldLabel>Gender</FieldLabel>
          <ValueWrapper readOnly value={userDetails?.gender || '-'} />
        </FormField>
      </ContentWrapper>
      {isConfirmModal && (
        <ConfirmActionModal
          open={isConfirmModal}
          toggleModal={toggleModal}
          handleUser={handleUser}
          action={userDetails?.is_blocked ? 'UnBlock' : 'Block'}
        />
      )}
    </MainWrapper>
  );
};

export default UserInfo;
