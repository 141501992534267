import { ChangeEvent, FormEvent, useState } from 'react';
import { useApi } from '../../shared/api';
import styled from 'styled-components';
import { __COLORS } from '../../shared/Theme';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import BgImg from '../../assets/images/mainbackground.png';
import InputWrapper from '../../ui-library/Input/InputWrapper';
import InsurewillInfo from '../../shared/components/InsurewillInfo/InsurewillInfo';
import { ButtonWrapper } from './components/ButtonWrapper';
import { ADMIN_LOGIN, LOGIN } from '../../routes/Routes';

const MainDiv = styled.div`
  background: url(${BgImg});
  background-position: center;
  background-size: cover;
  padding: 6.55rem 0;
  height: 100vh;
  display: flex;
  align-items: center;
  @media (min-width: 320px) and (max-width: 786px) {
    background-position: left;
  }
`;
const ContentSection = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  @media (min-width: 320px) and (max-width: 786px) {
    height: 100vh;
  }
`;
const LeftPart = styled.div`
  width: 34%;
  text-align: center;
  background: ${__COLORS.WHITE_COLOR};
  align-items: center;
  display: flex;
  padding: 10rem 0;
  @media (min-width: 786px) and (max-width: 1024px) {
    width: 36%;
    padding: 1.875rem 0;
  }
  @media (min-width: 320px) and (max-width: 786px) {
    width: 100%;
    background: transparent;
    height: 100vh;
  }
`;
const LeftContentDiv = styled.div`
  text-align: center;
  margin: 0 auto;
`;
const InsurewillHeading = styled.h1`
  color: #174966;
  font-family: Poppins;
  font-size: 3.5625rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  @media (min-width: 786px) and (max-width: 1024px) {
    font-size: 2.5625rem;
  }
  @media (min-width: 320px) and (max-width: 786px) {
    color: ${__COLORS.WHITE_COLOR};
  }
`;
const SigninText = styled.h3`
  color: ${__COLORS.ICON_COLOR};
  font-family: Poppins;
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  @media (min-width: 320px) and (max-width: 786px) {
    color: ${__COLORS.WHITE_COLOR};
  }
`;
const LeftparagraphDiv = styled.div`
  width: 60%;
  margin: 0 auto;
  margin-top: 1.25rem;
  @media (min-width: 320px) and (max-width: 786px) {
    width: 77%;
  }
`;
const LeftParagraph = styled.p`
  color: rgb(48, 60, 78);
  text-align: center;
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  @media (min-width: 320px) and (max-width: 786px) {
    color: ${__COLORS.WHITE_COLOR};
  }
`;
const FormDiv = styled.div`
  gap: 1.25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2.6rem auto 3.125rem auto;
  max-width: 300px;
`;

const SignInLink = styled.div`
  color: #1a5172;
  fontfamily: Poppins;
  fontsize: 1rem;
  fontstyle: normal;
  fontweight: 600;
  lineheight: normal;
  cursor: pointer;
`;

const ForgotPassword = () => {
  const api = useApi();
  const history = useHistory();
  const location = useLocation();

  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    setErrorMessage('');
    e.preventDefault();
    if (
      email &&
      /^[a-zA-Z0-9.!#$%&'+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)$/.test(email) &&
      email.trim()?.length
    ) {
      try {
        await api.users.forgotPassword({ email: email });
        toast.success(
          "We've sent the recovery steps to this email address if an account was found associated with it.",
        );
      } catch (e) {
        setErrorMessage((e as Error).message);
      }
    } else {
      setErrorMessage('Please enter valid email address!');
    }
  };

  return (
    <MainDiv>
      <ContentSection>
        <LeftPart>
          <LeftContentDiv>
            <InsurewillHeading>INSUREWILL</InsurewillHeading>
            <SigninText>Forgot Password ?</SigninText>
            <LeftparagraphDiv>
              <LeftParagraph>
                No worries! Enter your email address and you’ll receive a reset password link.
              </LeftParagraph>
            </LeftparagraphDiv>
            <FormDiv>
              <InputWrapper
                type="email"
                id="email"
                name="email"
                placeholder="Email"
                onChange={(e: ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
                value={email}
                required
                errorMessage={errorMessage}
              />
              <ButtonWrapper type="submit" onClick={onSubmit}>
                Send Recovery Email
              </ButtonWrapper>
            </FormDiv>
            <SignInLink onClick={() => history.push(location.pathname.includes('admin') ? ADMIN_LOGIN : LOGIN)}>
              Back to Sign In
            </SignInLink>
          </LeftContentDiv>
        </LeftPart>
        <InsurewillInfo />
      </ContentSection>
    </MainDiv>
  );
};

export default ForgotPassword;
