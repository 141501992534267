import { post, get } from './index';

export const billing = {
  getSubscriptionList: () => get('/auth/subscription-list'),
  makePayment: (payload: any) => post('/payment/create-order', payload),
  getBillingHistory: (payload: any) =>
    get(
      `/user/orders?searchTerm=${payload.searchTerm}&pageNo=${payload.pageNo}&sortedTerm=${payload.sortedTerm}&isAscending=${payload.isAscending}`,
    ),
  raiseDispute: (payload: any) => post('/user/raise-dispute', payload),
};
