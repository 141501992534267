import styled from 'styled-components';
import { useState, useEffect } from 'react';
import { SelectWrapper } from '../../ui-library';
import { frequencyList, termList, attemptList } from '../../shared/options';
import { useApi } from '../../shared/api';
import { toast } from 'react-toastify';
import { EXTRA_SMALL_DEVICES } from '../../shared/helpers/Mobile';
import WellbeingModal from './WellbeingModal';
import { __COLORS } from '../../shared/Theme';

const PreferenceText = styled.div`
  text-align: center;
  padding: 15px;
  ${EXTRA_SMALL_DEVICES`
  width: 100%;
  `};
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1.37rem 1.44rem;
  border-bottom: 0.0625rem solid #eeebeb;
`;

const SectionHeading = styled.h1`
  color: ${__COLORS.ICON_COLOR};
  text-align: center;
  font-family: Poppins;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  @media (min-width: 320px) and (max-width: 500px) {
    font-size: 1rem;
  }
`;

const ContentWrapper = styled.div`
  padding: 1.44rem;
`;

const FormField = styled.div`
  margin-bottom: 10px;
`;
const FieldLabel = styled.label`
  padding: 5px;
`;

interface PreferenceOption {
  label: string;
  value: string | number;
}

interface PreferenceListType {
  frequency: PreferenceOption;
  term: PreferenceOption;
  attempts: PreferenceOption;
}

type PreferenceKey = keyof PreferenceListType;

type Props = {
  preferenceData: any;
  getPreferenceData: () => void;
};

type SelectType = {
  label?: string;
  value?: number;
};

const CommunicationFrequencySection = ({ preferenceData, getPreferenceData }: Props) => {
  const api = useApi();

  const [preferenceObj, setPreferenceObj] = useState<PreferenceListType>({
    frequency: { label: 'Once', value: 1 },
    term: { label: 'Week', value: 'week' },
    attempts: { label: '3', value: 3 },
  });

  const [openWellbeingModal, setOpenWellbeingModal] = useState(false);

  useEffect(() => {
    const preferenceObjTemp = { ...preferenceObj };
    const frequencyObj = (frequencyList.length !== 0 &&
      frequencyList.find((item) => item.value == preferenceData.frequency)) || { label: '', value: '' };

    const termObj = (termList.length !== 0 && termList.find((item) => item.value == preferenceData.term)) || {
      label: '',
      value: '',
    };

    const attemptObj = (attemptList.length !== 0 &&
      attemptList.find((item) => item.value == preferenceData.attempts)) || { label: '', value: '' };

    preferenceObjTemp.frequency = frequencyObj;
    preferenceObjTemp.term = termObj;
    preferenceObjTemp.attempts = attemptObj;

    setPreferenceObj({ ...preferenceObjTemp });
  }, [Object.keys(preferenceData).length == 0]);

  const handleSelect = (value: SelectType, calledFrom: PreferenceKey) => {
    const preferenceOption: PreferenceOption = {
      label: value.label ?? '', // Provide a default value if label is undefined
      value: value.value ?? '', // Provide a default value if value is undefined
    };

    const tempPreferenceObj = { ...preferenceObj };

    tempPreferenceObj[calledFrom] = preferenceOption;
    setPreferenceObj(tempPreferenceObj);

    if (preferenceData?.failed_attempts > preferenceData?.attempts) {
      toggleWellbeingModal();
    } else {
      setCommunicationPreference(value, calledFrom);
    }
  };

  const toggleWellbeingModal = () => {
    setOpenWellbeingModal(!openWellbeingModal);
  };

  const setCommunicationPreference = async (value: SelectType, calledFrom: string) => {
    try {
      const payload = {
        [calledFrom]: value.value,
      };

      await api.users.updatePreference(payload);
      await api.users.getPreference();

      toast.success('Updated successfully.');
    } catch (e) {
      toast.error((e as Error).message);
    }
  };

  return (
    <>
      <HeaderContainer>
        <SectionHeading>Communication Frequency</SectionHeading>
      </HeaderContainer>
      <ContentWrapper>
        <FormField>
          <FieldLabel>Reach out to me</FieldLabel>
          <SelectWrapper
            options={frequencyList}
            onChange={(value: SelectType) => handleSelect(value, 'frequency')}
            value={preferenceObj.frequency}
            width="100%"
            height="50px"
            isClearable={false}
          />
        </FormField>
        <FormField>
          <FieldLabel>Every</FieldLabel>
          <SelectWrapper
            options={termList}
            onChange={(value: SelectType) => handleSelect(value, 'term')}
            value={preferenceObj.term}
            width="100%"
            height="50px"
            isClearable={false}
          />
        </FormField>
        <FormField>
          <FieldLabel>At least</FieldLabel>
          <SelectWrapper
            options={attemptList}
            onChange={(value: SelectType) => handleSelect(value, 'attempts')}
            value={preferenceObj.attempts}
            width="100%"
            height="50px"
            isClearable={false}
          />
        </FormField>
        <PreferenceText>Times before you start reaching my nominees.</PreferenceText>
      </ContentWrapper>
      {openWellbeingModal && (
        <WellbeingModal
          openModal={openWellbeingModal}
          toggleModal={toggleWellbeingModal}
          getPreferenceData={getPreferenceData}
        />
      )}
    </>
  );
};
export default CommunicationFrequencySection;
