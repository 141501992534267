import styled from 'styled-components';
import { SPACING } from '../../shared/Layout';
import { EXTRA_SMALL_DEVICES, LARGE_DEVICES, MEDIUM_DEVICES, SMALL_DEVICES } from '../../shared/helpers/Mobile';

export const ModalContainer = styled.div<{ isVisible: boolean }>`
  margin-bottom: auto;
  margin-top: auto;
  // overflow-y: scroll;
  overflow: auto;
  box-shadow: rgba(9, 30, 66, 0.08) 0px 0px 0px 1px, rgba(9, 30, 66, 0.08) 0px 2px 1px,
    rgba(9, 30, 66, 0.31) 0px 0px 20px -6px;
  padding: ${SPACING * 3}px;
  display: flex;
  flex-direction: column;
  height: auto;
  max-height: calc(100% - 1px);
  width: 550px;
  pointer-events: auto;
  border-radius: 3px;
  outline: 0px;
  background-color: #fff;
  height: 100%;
  width: 50%;
  align-self: flex-end;
  ${EXTRA_SMALL_DEVICES`
    width: 100%;
  `}

  ${SMALL_DEVICES`
    width: 100%;
  `}

  ${MEDIUM_DEVICES`
    width: 80%;
  `}

  ${LARGE_DEVICES`
    width:70%;
  `}
`;
