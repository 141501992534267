import { css } from 'styled-components';

export const EXTRA_SMALL_DEVICES = (...args: any) => css`
  /* Extra small devices (phones, 600px and down) */
  @media only screen and (max-width: 600px) {
    ${css`
      ${args};
    `};
  }
`;

export const IPAD_SMALL_DEVICES = (...args: any) => css`
  /* Extra small devices (ipad,1024px and down) */
  @media only screen and (max-width: 1024px) {
    ${css`
      ${args};
    `};
  }
`;

export const SMALL_DEVICES = (...args: any) => css`
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) {
    ${css`
      ${args};
    `};
  }
`;

export const MEDIUM_DEVICES = (...args: any) => css`
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) {
    ${css`
      ${args};
    `};
  }
`;

export const MEDIUM_DEVICE_SIZE = 768;

export const LARGE_DEVICES = (...args: any) => css`
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 1024px) {
    ${css`
      ${args};
    `};
  }
`;

export const LARGE_DEVICE_SIZE = 1024;

export const EXTRA_LARGE_DEVICES = (...args: any) => css`
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 1200px) {
    ${css`
      ${args};
    `};
  }
`;

export const EXTRA_LARGE_DEVICES_MAX = (...args: any) => css`
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (max-width: 1200px) {
    ${css`
      ${args};
    `};
  }
`;
export const LAPTOP_DEVICES = (...args: any) => css`
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 1440px) {
    ${css`
      ${args};
    `};
  }
`;

export const LAPTOP_DEVICES_MAX = (...args: any) => css`
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 1100px) {
    ${css`
      ${args};
    `};
  }
`;

export const MOBILE_DEVICES = (...args: any) => css`
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 475px) {
    ${css`
      ${args};
    `};
  }
`;

export const MOBILE_DEVICES_MIN = (...args: any) => css`
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 320px) {
    ${css`
      ${args};
    `};
  }
`;
