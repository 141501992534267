import { createSlice } from '@reduxjs/toolkit';
import { LoginStates } from '../../shared/types';

export const appSlice = createSlice({
  name: 'app',
  initialState: {
    loginState: LoginStates.LOADING,
  },
  reducers: {
    setLoginState: (state, action) => {
      state.loginState = action.payload;
    },
  },
});

export const { setLoginState } = appSlice.actions;

export default appSlice.reducer;
