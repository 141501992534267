import { PageContent } from '../../../shared/components/PageContent/PageContent';
import { PageHeading } from '../../../shared/components/PageHeading/PageHeading';
import BillingHistory from '../../Billing/BillingHistory';

export const TransactionLogs = () => {
  return (
    <>
      <PageHeading header="Transaction Logs" />
      <PageContent>
        <BillingHistory calledFrom="ADMIN" />
      </PageContent>
    </>
  );
};
