// User Signup Router
export const LOGIN = '/login';
export const SIGNUP = '/signup';
export const EMAIL_VERIFICATION = '/email-verification';
export const FORGOT_PASSWORD = '/forgot-password';
export const RESET_PASSWORD = '/reset-password';
export const WELLBEING_CONFIRMATION = '/wellbeing-confirmation';
export const NOMINEE_WELLBEING_CONFIRMATION = '/nominee-wellbeing-confirmation';

// User Dashboard Router
export const NOMINEES = '/nominees';
export const PORTFOLIO = '/portfolio';
export const PREFERENCES = '/preferences';
export const PROFILE_SETTINGS = '/profile-settings';
export const BILLING = '/billing';
export const SUPPORT = '/support';

// Admin Panel Dashboard
export const USER_MANAGEMENT = '/admin/user-management';
export const USER_DETAILS = '/admin/user-management/:userId';
export const TRANSACTION_LOGS = '/admin/transaction-logs';
export const ADMIN_USERS = '/admin/admin-users';

// Admin Signup Router
export const ADMIN_LOGIN = '/admin/login';
export const ADMIN_EMAIL_VERIFICATION = '/admin/email-verification';
export const ADMIN_FORGOT_PASSWORD = '/admin/forgot-password';
export const ADMIN_RESET_PASSWORD = '/admin/reset-password';

export const userRoutesList = [
  {
    route: NOMINEES,
    isAccessible: ['user'],
  },
  {
    route: PORTFOLIO,
    isAccessible: ['user'],
  },

  {
    route: PREFERENCES,
    isAccessible: ['user'],
  },
  {
    route: PROFILE_SETTINGS,
    isAccessible: ['user'],
  },

  {
    route: BILLING,
    isAccessible: ['user'],
  },
  {
    route: SUPPORT,
    isAccessible: ['user'],
  },
];

export const adminRoutesList = [
  {
    route: USER_MANAGEMENT,
    isAccessible: ['super_admin', 'admin'],
  },
  {
    route: USER_DETAILS,
    isAccessible: ['super_admin', 'admin'],
  },
  {
    route: ADMIN_USERS,
    isAccessible: ['super_admin', 'admin'],
  },
  {
    route: TRANSACTION_LOGS,
    isAccessible: ['super_admin', 'admin'],
  },
];
