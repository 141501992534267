import styled from 'styled-components';

const DividerWrapper = styled.hr`
  border-top: 2px solid #c5bbbb;
  margin: 0 50px;
  position: relative;
  text-align: center;
  @media (min-width: 320px) and (max-width: 890px) {
    margin: 0 1.125rem;
  }
`;

export const Divider = () => {
  return <DividerWrapper />;
};
