import { useEffect, useState } from 'react';
import { useDispatch, useSelector, RootStateOrAny } from 'react-redux';
import { useApi } from '../../shared/api';

import { setNominees } from '../../redux/nominees/nomineesSlice';

import AddNomineeModal from './AddNomineeModal';
import NomineesList from './NomineesList';
import { PageHeading } from '../../shared/components/PageHeading/PageHeading';
import { PageContent } from '../../shared/components/PageContent/PageContent';
import styled from 'styled-components';
import { SPACING } from '../../shared/Layout';
import Loading from '../Loading/Loading';
import DeleteNomineeModal from './DeleteNomineeModal';
import { toast } from 'react-toastify';

const NoNomineeText = styled.p`
  text-align: center;
  margin: ${SPACING * 2}px 0;
`;

type NomineeType = {
  [key: string]: unknown;
};

const Nominees = () => {
  const user = useSelector((s: RootStateOrAny) => s.users.user);
  const nominees = user && user.Nominees ? user.Nominees : [];

  const api = useApi();

  const [selectedNominee, setSelectedNominee] = useState<NomineeType>({});
  const [isAddNewModal, setAddNewModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isDeleteModal, setDeleteModal] = useState(false);

  const dispatch = useDispatch();

  const getNominees = async () => {
    try {
      const response = await api.nominees.getAll();
      dispatch(setNominees(response.data));
      setIsLoading(false);
    } catch (e) {
      toast.error((e as Error).message);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getNominees();
  }, []);

  const toggleNomineeModal = (data: NomineeType) => {
    setAddNewModal(!isAddNewModal);
    setSelectedNominee(data || {});
  };

  const toggleDeleteModal = (data: NomineeType) => {
    setDeleteModal(!isDeleteModal);
    setSelectedNominee(data || {});
  };

  return (
    <>
      <>
        <PageHeading
          header="Nominees"
          buttonText="Add New"
          handleClick={() => {
            setAddNewModal(!isAddNewModal);
          }}
        />
        <PageContent>
          {isLoading ? (
            <Loading />
          ) : nominees?.length ? (
            <NomineesList nominees={nominees} toggleModal={toggleNomineeModal} toggleDeleteModal={toggleDeleteModal} />
          ) : (
            <NoNomineeText>There are no nominees in your account.</NoNomineeText>
          )}
        </PageContent>
      </>
      {isAddNewModal && (
        <AddNomineeModal
          open={isAddNewModal}
          toggleModal={toggleNomineeModal}
          totalNominees={nominees?.length}
          nominee={selectedNominee}
        />
      )}
      {isDeleteModal && (
        <DeleteNomineeModal open={isDeleteModal} toggleModal={toggleDeleteModal} nominee={selectedNominee} />
      )}
    </>
  );
};

export default Nominees;
