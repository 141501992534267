import styled from 'styled-components';
import { SPACING } from '../../shared/Layout';
import { __COLORS } from '../../shared/Theme';

const InputStyled = styled.input`
    padding: 8px 15px;
    width: 100%;
    flex-shrink: 0,
    border-radius: 0.3125rem !important;
    background: ${__COLORS.INPUT_GREY};
    MozAppearance: textfield;
    appearance: textfield;

    &:placeholder-shown {
    text-overflow: ellipsis;
  }
    
    @media (min-width: 320px) and (max-width: 786px) {
      &::placeholder {
    color: #303C4E;
  }
    @media (min-width: 320px) and (max-width: 500px) {
      width: 100%;
    }
  
`;
const InputContainer = styled.div<{ width: string; marginBottom: string }>`
  border-radius: ${SPACING / 2}px;
  border: none;
  width: ${(props) => (props.width ? props.width : '19rem')};

  @media (min-width: 320px) and (max-width: 500px) {
    width: 100%;
  }
`;
const ErrorMessage = styled.div`
  color: red;
  font-size: 14px;
  text-align: left;
  margin: 0 auto;
`;

export const InputWrapper = (props: any) => {
  return (
    <>
      <InputContainer {...props}>
        <InputStyled {...props} />

        {props.calledFrom && props.calledFrom == 'PASSWORD' && (
          <span onClick={props.handleEyeClick} style={{ float: 'right', position: 'relative', cursor: 'pointer' }}>
            {props.showPassword ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
                style={{
                  width: '16px',
                  height: '16px',
                  color: '#303C4E',
                  cursor: 'pointer',
                  marginLeft: '-60px',
                  marginTop: '14px',
                }}
              >
                <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
                <path
                  fillRule="evenodd"
                  d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z"
                  clipRule="evenodd"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
                style={{
                  width: '16px',
                  height: '16px',
                  color: '#303C4E',
                  cursor: 'pointer',
                  marginLeft: '-60px',
                  marginTop: '14px',
                }}
              >
                <path
                  fillRule="evenodd"
                  d="M3.707 2.293a1 1 0 00-1.414 1.414l14 14a1 1 0 001.414-1.414l-1.473-1.473A10.014 10.014 0 0019.542 10C18.268 5.943 14.478 3 10 3a9.958 9.958 0 00-4.512 1.074l-1.78-1.781zm4.261 4.26l1.514 1.515a2.003 2.003 0 012.45 2.45l1.514 1.514a4 4 0 00-5.478-5.478z"
                  clipRule="evenodd"
                />
                <path d="M12.454 16.697L9.75 13.992a4 4 0 01-3.742-3.741L2.335 6.578A9.98 9.98 0 00.458 10c1.274 4.057 5.065 7 9.542 7 .847 0 1.669-.105 2.454-.303z" />
              </svg>
            )}
          </span>
        )}
        {props.errorMessage && <ErrorMessage>{props.errorMessage}</ErrorMessage>}
      </InputContainer>
    </>
  );
};
export default InputWrapper;
