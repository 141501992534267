import { createSlice } from '@reduxjs/toolkit';

export const nomineesSlice = createSlice({
  name: 'nominees',
  initialState: {
    nominees: [],
  },
  reducers: {
    setNominees: (state, action) => {
      state.nominees = action.payload;
    },
  },
});

export const { setNominees } = nomineesSlice.actions;

export default nomineesSlice.reducer;
