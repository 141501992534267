import styled from 'styled-components';
import LoaderSVG from '../../assets/images/loader.svg';

const Container = styled.div`
  text-align: center;
  align-items: center;
  width: 100%;
  margin-top: 50px;
  height: 50vh;
`;

const Loading = () => {
  return (
    <Container>
      <img src={LoaderSVG} alt="Loading.." />
    </Container>
  );
};

export default Loading;
