import styled from 'styled-components';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { Button } from '../../../../ui-library';
import { __COLORS } from '../../../../shared/Theme';

const PopupHeadingdiv = styled.div`
  height: 4.87rem;
  padding: 1.31rem;
  border-bottom: 0.0625rem solid #e3dbdb;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const PopupHeading = styled.h1`
  color: ${__COLORS.ICON_COLOR};
  font-family: Poppins;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

const ContentWrapper = styled.div`
  margin: 20px 0px;
`;

type Props = {
  open: boolean;
  toggleModal: () => void;
  handleUser: () => void;
  action: string;
};

const ConfirmActionModal = ({ open, toggleModal, handleUser, action }: Props) => {
  const saveChanges = () => {
    toggleModal();
    handleUser();
  };
  return (
    <Modal isOpen={open} centered={true} size="md">
      <PopupHeadingdiv>
        <PopupHeading>{`${action} User`}</PopupHeading>
        <FontAwesomeIcon icon={faXmark} onClick={toggleModal} style={{ cursor: 'pointer' }} />
      </PopupHeadingdiv>
      <ModalBody>
        <ContentWrapper>{`Are you sure you want to ${action} the selected user?`}</ContentWrapper>
      </ModalBody>
      <ModalFooter style={{ gap: '1rem' }}>
        <Button isSecondary onClick={toggleModal}>
          Close
        </Button>
        <Button isPrimary onClick={saveChanges}>
          Save Changes
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ConfirmActionModal;
