import { useState, useEffect, ChangeEvent } from 'react';
import { useSelector, RootStateOrAny } from 'react-redux';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { useApi } from '../../shared/api';
import { useDispatch } from 'react-redux';
import { setUser } from '../../redux/users/userSlice';
import { __COLORS } from '../../shared/Theme';
import { faUserPen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import InputWrapper from '../../ui-library/Input/InputWrapper';
import TextAreaInput from '../../ui-library/Input/TextAreaInput';
import { Button } from '../../ui-library';

const HeaderContainer = styled.div`
  justify-content: space-between;
  display: flex;
  padding: 1.37rem 1.44rem;
  border-bottom: 0.0625rem solid #eeebeb;
  @media (min-width: 320px) and (max-width: 786px) {
    flex-wrap: wrap;
  }
`;

const SectionHeading = styled.h1`
  color: #303c4e;
  text-align: center;
  font-family: Poppins;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  @media (min-width: 320px) and (max-width: 500px) {
    font-size: 1rem;
  }
`;

const EditIconWrapper = styled.span`
  color: ${__COLORS.ICON_COLOR};
  cursor: pointer;
  display: flex;
`;
const ContentWrapper = styled.div`
  padding: 1.44rem;
`;
const FormField = styled.div`
  padding: 0 0.88rem 0 0.88rem;
  display: flex;
  align-items: center;
  @media (min-width: 320px) and (max-width: 500px) {
    flex-wrap: wrap;
  }
`;

const ValueWrapper = styled.input`
  color: ${__COLORS.VALUE_COLOR};
  display: flex;
  align-items: center;
`;
const Seperator = styled.hr`
  margin: 1.5rem 0;
  color: inherit;
  border: 0;
  border-top: var(--bs-border-width) solid;
  opacity: 0.15;
  @media (min-width: 320px) and (max-width: 786px) {
    margin: 1rem 0;
  }
`;
const FieldLabel = styled.label`
  width: 8rem;
  padding: 5px 0;
  display: flex;
  align-items: center;
`;

const CommunicationSection = () => {
  const api = useApi();
  const user = useSelector((s: RootStateOrAny) => s.users.user);

  const dispatch = useDispatch();
  const [isEditing, setIsEditing] = useState(false);
  const [address, setAddress] = useState(user?.address);
  const [postalCode, setPostalCode] = useState(user?.postal);
  const [city, setCity] = useState(user?.city);
  const [stateName, setStateName] = useState(user?.state);
  // const [country, setCountry] = useState(user?.country);

  useEffect(() => {
    if (user) {
      setAddress(user?.address);
      setPostalCode(user?.postal);
      setCity(user?.city);
      setStateName(user?.state);
    }
  }, [user]);

  const saveUserInfo = async () => {
    try {
      await api.users.update({
        address,
        postal: postalCode,
        city,
        state: stateName,
      });

      const response = await api.users.getUser();
      dispatch(setUser(response.data));
      toast.success('Communication Info updated successfully.');
      setIsEditing(false);
    } catch (e) {
      toast.error((e as Error).message);
    }
  };

  const resetForm = () => {
    if (user) {
      setAddress(user?.address);
      setPostalCode(user?.postal);
      // setCountry(user?.country);
      setStateName(user?.state);
    }
    setIsEditing(false);
  };

  return (
    <>
      <HeaderContainer>
        <SectionHeading>COMMUNICATION</SectionHeading>
        <EditIconWrapper>
          {isEditing ? (
            <>
              <Button onClick={() => resetForm()} isSecondary style={{ marginRight: '10px' }}>
                Close
              </Button>
              <Button onClick={saveUserInfo} isPrimary>
                Save
              </Button>
            </>
          ) : (
            <FontAwesomeIcon icon={faUserPen} size="lg" onClick={() => setIsEditing(true)} />
          )}
        </EditIconWrapper>
      </HeaderContainer>
      <ContentWrapper>
        <FormField>
          <FieldLabel>Address</FieldLabel>
          {isEditing ? (
            <TextAreaInput
              value={address}
              onChange={(e: ChangeEvent<HTMLInputElement>) => setAddress(e.target.value)}
              placeholder="Enter Address"
              style={{ marginBottom: '0' }}
            />
          ) : (
            <ValueWrapper readOnly value={address || '-'} />
          )}
        </FormField>
        <Seperator />
        <FormField>
          <FieldLabel>Postal Code</FieldLabel>
          {isEditing ? (
            <InputWrapper
              value={postalCode}
              onChange={(e: ChangeEvent<HTMLInputElement>) => setPostalCode(e.target.value)}
              placeholder="Enter Postal Code"
              style={{ marginBottom: '0' }}
            />
          ) : (
            <ValueWrapper readOnly value={postalCode || '-'} />
          )}
        </FormField>
        <Seperator />
        <FormField>
          <FieldLabel>State/UT</FieldLabel>
          {isEditing ? (
            <InputWrapper
              value={stateName}
              onChange={(e: ChangeEvent<HTMLInputElement>) => setStateName(e.target.value)}
              placeholder="Enter State/UT"
              style={{ marginBottom: '0' }}
            />
          ) : (
            <ValueWrapper readOnly value={stateName || '-'} />
          )}
        </FormField>
        <Seperator />
        {/* <FormField>
          <FieldLabel>Country Side</FieldLabel>
          {isEditing ? (
            <InputWrapper
              value={country}
              onChange={(e: ChangeEvent<HTMLInputElement>) => setCountry(e.target.value)}
              placeholder="Enter Country"
              style={{ marginBottom: '0' }}
            />
          ) : (
            <ValueWrapper readOnly value={country || '-'} />
          )}
        </FormField> */}
      </ContentWrapper>
    </>
  );
};

export default CommunicationSection;
