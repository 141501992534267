import { useState } from 'react';
import styled from 'styled-components';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { useApi } from '../../shared/api';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { setUser } from '../../redux/users/userSlice';
import { setNominees } from '../../redux/nominees/nomineesSlice';
import { Button } from '../../ui-library';
import { __COLORS } from '../../shared/Theme';

const PopupHeadingdiv = styled.div`
  height: 4.87rem;
  padding: 1.31rem;
  border-bottom: 0.0625rem solid #e3dbdb;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const PopupHeading = styled.h1`
  color: ${__COLORS.ICON_COLOR};
  font-family: Poppins;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

const ContentWrapper = styled.div`
  margin: 20px 0px;
`;

type Props = {
  open: boolean;
  toggleModal: (_args?: any) => void;
  nominee?: any;
};

const DeleteNomineeModal = ({ open, toggleModal, nominee }: Props) => {
  const api = useApi();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const deleteNominee = async () => {
    try {
      setIsLoading(true);
      await api.nominees.remove({ id: nominee.id });
      const response = await api.nominees.getAll();
      dispatch(setNominees(response.data));
      toast.success('Nominee deleted successfully.');
      const userResponse = await api.users.getUser();
      dispatch(setUser(userResponse.data));

      toggleModal({});
    } catch (e) {
      toast.error((e as Error).message);
    }
    setIsLoading(false);
  };

  return (
    <Modal isOpen={open} centered={true} size="md">
      <PopupHeadingdiv>
        <PopupHeading>Delete Nominee</PopupHeading>
        <FontAwesomeIcon icon={faXmark} onClick={toggleModal} style={{ cursor: 'pointer' }} />
      </PopupHeadingdiv>
      <ModalBody>
        <ContentWrapper>Are you sure you want to delete the selected nominee?</ContentWrapper>
      </ModalBody>
      <ModalFooter style={{ gap: '1rem' }}>
        <Button isSecondary onClick={toggleModal}>
          Close
        </Button>
        <Button isPrimary onClick={deleteNominee} isLoading={isLoading}>
          Save Changes
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default DeleteNomineeModal;
