import { useState, ChangeEvent } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { useApi } from '../../shared/api';
// import { countryCodeList } from '../../shared/options';
import { toast } from 'react-toastify';
// import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
// import ReactFlagsSelect from 'react-flags-select';
import 'react-phone-number-input/style.css';
// import { isValidPhoneNumber } from 'react-phone-number-input';
import Vector from '../../assets/images/Vector.png';
import InputWrapper from '../../ui-library/Input/InputWrapper';
import BgImg from '../../assets/images/mainbackground.png';
import RightBack from '../../assets/images/rightback.png';
import { ButtonWrapper } from './components/ButtonWrapper';
import { __COLORS } from '../../shared/Theme';
interface MessageType {
  [key: string]: string;
}

const MainDiv = styled.div`
  background: url(${BgImg});
  background-position: center;
  background-size: cover;
  padding: 6.55rem 0;
  height: 100vh;
  display: flex;
  align-items: center;
  @media (min-width: 320px) and (max-width: 786px) {
    background-position: left;
  }
`;
const ContentSection = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  @media (min-width: 320px) and (max-width: 786px) {
    height: 100vh;
  }
`;
const LeftPart = styled.div`
  width: 34%;
  text-align: center;
  background: ${__COLORS.WHITE_COLOR};
  align-items: center;
  display: flex;
  padding: 4rem 0;
  @media (min-width: 786px) and (max-width: 1024px) {
    width: 36%;
    padding: 1.875rem 0;
  }
  @media (min-width: 320px) and (max-width: 786px) {
    width: 100%;
    background: transparent;
    height: 100vh;
  }
`;
const LeftContentDiv = styled.div`
  text-align: center;
  margin: 0 auto;
`;
const InsurewillHeading = styled.h1`
  color: #174966;
  font-family: Poppins;
  font-size: 3.5625rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  @media (min-width: 786px) and (max-width: 1024px) {
    font-size: 2.5625rem;
  }
  @media (min-width: 320px) and (max-width: 786px) {
    color: ${__COLORS.WHITE_COLOR};
  }
`;
const SignupText = styled.h3`
  color: ${__COLORS.ICON_COLOR};
  font-family: Poppins;
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  @media (min-width: 320px) and (max-width: 786px) {
    color: ${__COLORS.WHITE_COLOR};
  }
`;
const FormDiv = styled.div`
  display: grid;
  gap: 12px;
  width: 300px;
  margin: 2rem auto 0 auto;
`;

const RightPart = styled.div`
  background: url(${RightBack});
  background-position: center;
  background-size: cover;
  width: 35%;
  flex-shrink: 0;
  text-align: center;
  align-items: center;
  display: flex;
  @media (min-width: 786px) and (max-width: 1024px) {
    width: 40%;
  }
  @media (min-width: 320px) and (max-width: 786px) {
    display: none;
  }
`;
const RightmainHeading = styled.h1`
  color: #174966;
  font-family: Poppins;
  font-size: 3.125rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  @media (min-width: 786px) and (max-width: 1024px) {
    font-size: 2.125rem;
  }
`;
const RightParagraph = styled.p`
  color: ${__COLORS.ICON_COLOR};
  text-align: center;
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 0 5rem;
  @media (min-width: 786px) and (max-width: 1024px) {
    padding: 0 2rem;
  }
`;
const SignInLink = styled.a`
  color: #1a5172;
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-wight: 600;
  line-height: normal;
  cursor: pointer;
`;
const AccText = styled.p`
  color: #000;
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 2.6rem;
`;

const Signup = () => {
  const history = useHistory();
  const api = useApi();

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  // const [gender, setGender] = useState({ label: '', value: '' });
  // const [dateOfBirth, setDateOfBirth] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  // const [countryCode, setCountryCode] = useState('IN');

  const [isLoading, setIsLoading] = useState(false);

  const [message, setMessage] = useState<MessageType>({});

  const signupHandler = async () => {
    // e.preventDefault();
    setMessage({});
    try {
      if (validateForm()) {
        setIsLoading(true);
        // let selectedCountryCode = '';
        // Object.entries(countryCodeList).map(([key, value]) => {
        //   if (countryCode == key) {
        //     selectedCountryCode = value.primary;
        //   }
        // });
        // const phnNumber = String(phoneNumber);
        // const finalNumber = `${phnNumber}`;

        await api.users.create({
          first_name: firstName,
          last_name: lastName,
          email,
          phone: phoneNumber,
          password,
        });
        toast.success('User has been signed up successfully.');
        history.push('/login');
      }
    } catch (e) {
      toast.error((e as Error).message);
    }
    setIsLoading(false);
  };

  const validateForm = () => {
    const tempMessage = { firstName: '', lastName: '', email: '', password: '', confirmPassword: '', phoneNumber: '' };

    let result = true;

    if (!firstName.trim()?.length) {
      tempMessage.firstName = 'Please enter First Name!';
      result = false;
    }
    if (!lastName.trim()?.length) {
      tempMessage.lastName = 'Please enter Last Name!';
      result = false;
    }

    if (!password.trim()?.length) {
      tempMessage.password = 'Please enter Password!';
      result = false;
    }
    if (!confirmPassword.trim()?.length) {
      tempMessage.confirmPassword = 'Please enter Confirm Password!';
      result = false;
    }

    if (password !== confirmPassword) {
      tempMessage.confirmPassword = 'Password and Confirm Password should be same!';
      result = false;
    }

    if (!/^[a-zA-Z0-9.!#$%&'+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)$/.test(email)) {
      tempMessage.email = 'Please enter valid email address!';
      result = false;
    }
    if (!/(^.*(?=.*[!@#$%^&*_0-9])(?=.*[A-Z])(?=.*[a-z]).*$)/.test(password)) {
      tempMessage.password =
        'Must be at least 8 characters long, contain at least one number and have a mixture of uppercase and lowercase letters!';
      result = false;
    }
    if (phoneNumber.toString().length < 10) {
      tempMessage.phoneNumber = 'Phone number must be 10 digits long!';
      result = false;
    }

    // if (moment(dateOfBirth).isAfter(moment(), 'day') || moment(dateOfBirth).isSame(moment(), 'day')) {
    //   tempMessage.dob = 'Please select past date!';
    //   result = false;
    // }

    // let selectedCountryCode = '';
    // Object.entries(countryCodeList).map(([key, value]) => {
    //   if (countryCode == key) {
    //     selectedCountryCode = value.primary;
    //   }
    // });
    // const phnNumber = String(phoneNumber);
    // const finalNumber = `${selectedCountryCode}${phnNumber}`;

    // if (isValidPhoneNumber(finalNumber) == false) {
    //   tempMessage.phoneNumber = 'Phone number is not valid!';
    //   result = false;
    // }

    setMessage({ ...tempMessage });
    return result;
  };

  // const handlePhoneInput = (value: any) => {
  //   if (!isNaN(value)) {
  //     setPhoneNumber(value);
  //   }
  // };

  // const onCodeSelect = (e: any) => {
  //   // const formatVal = formatPhoneNumber(e);
  //   // setPhoneNumber(e);
  //   setCountryCode(e);
  // };

  return (
    <MainDiv>
      <ContentSection>
        <LeftPart>
          <LeftContentDiv>
            <InsurewillHeading>INSUREWILL</InsurewillHeading>
            <SignupText>Sign Up</SignupText>
            <FormDiv>
              <InputWrapper
                type="text"
                id="name"
                placeholder="First Name"
                onChange={(e: ChangeEvent<HTMLInputElement>) => setFirstName(e.target.value)}
                required
                value={firstName}
                errorMessage={message && message?.firstName}
              />

              <InputWrapper
                type="text"
                id="name"
                placeholder="Last Name"
                onChange={(e: ChangeEvent<HTMLInputElement>) => setLastName(e.target.value)}
                required
                value={lastName}
                errorMessage={message && message?.lastName}
              />
              <InputWrapper
                type="email"
                id="email"
                placeholder="Email"
                onChange={(e: ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
                value={email}
                required
                errorMessage={message && message?.email}
              />
              <InputWrapper
                type="tel"
                id="phone"
                placeholder="Phone"
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  const input = e.target.value;
                  // Only allow numbers
                  const formattedInput = input.replace(/\D/g, '');
                  setPhoneNumber(formattedInput);
                }}
                value={phoneNumber}
                required
                errorMessage={message && message?.phoneNumber}
                maxLength={10}
              />
              <InputWrapper
                id="password"
                placeholder="Password"
                value={password}
                required
                width="100%"
                type={showPassword ? 'text' : 'password'}
                onChange={(e: ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
                calledFrom={'PASSWORD'}
                handleEyeClick={() => setShowPassword(!showPassword)}
                showPassword={showPassword}
                errorMessage={message && message?.password}
              />

              <InputWrapper
                id="cpassword"
                placeholder="Confirm Password"
                type={showConfirmPassword ? 'text' : 'password'}
                onChange={(e: ChangeEvent<HTMLInputElement>) => setConfirmPassword(e.target.value)}
                value={confirmPassword}
                calledFrom={'PASSWORD'}
                handleEyeClick={() => setShowConfirmPassword(!showConfirmPassword)}
                showPassword={showConfirmPassword}
                errorMessage={message && message?.confirmPassword}
              />

              <ButtonWrapper isLoading={isLoading} type="submit" onClick={signupHandler}>
                Signup
              </ButtonWrapper>
            </FormDiv>
            <AccText>
              {`Already have an account? `}
              <SignInLink onClick={() => history.push('/login')}>Sign In</SignInLink>
            </AccText>
          </LeftContentDiv>
        </LeftPart>
        <RightPart>
          <div>
            <img src={Vector} alt="" width={65} height={84} />
            <RightmainHeading>DID YOU KNOW</RightmainHeading>
            <RightParagraph>
              90% of Indian citizen don’t even know about the term insurance coverage from their credit cards!
            </RightParagraph>
          </div>
        </RightPart>
      </ContentSection>
    </MainDiv>
  );
};

export default Signup;
