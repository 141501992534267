import styled from 'styled-components';
import { Modal, ModalBody } from 'reactstrap';
import { __COLORS } from '../../Theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDisplay, faXmark, faLocationDot, faArrowRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import { faClock, faCircleUser } from '@fortawesome/free-regular-svg-icons';
import moment from 'moment';

const PopupDiv = styled.div`
  width: 100%;
  flex-shrink: 0;
  border-radius: 0.3125rem;
  background: ${__COLORS.WHITE_COLOR};
  min-height: 100vh;
`;
const PopupHeadingdiv = styled.div`
  padding-left: 1.31rem;
  padding-right: 1.31rem;
  padding-top: 1.31rem;
  padding-bottom: 0px;
`;
const HeadingOptions = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 3px;
`;
const HeadContent = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
`;

// popupdesign
const UserHeading = styled.h1`
  color: #303c4e;
  font-family: poppins;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;
const ViewprofileLink = styled.a`
  color: #303c4e;
  font-family: poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;
const SideMenu = styled.li`
  padding: 10px 0;
`;
const SideMenuLink = styled.a`
  color: #000;
  font-family: poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  &.active {
    border-left: 5px solid;
    padding: 0 10px;
  }
`;

const SidebarLastlogin = styled.a`
  color: #000;
  font-family: poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-bottom: 20px;
`;
const SidebarDetails = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 10px 0;
`;

const DetailHeading = styled.p`
  font-family: poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
`;
const DetailData = styled.p`
  font-family: poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
`;

const Separator = styled.hr`
  margin: 8px 0px;
  color: ${__COLORS.SECONDARY_DARK};
`;

const LogoutWrapper = styled.div`
  cursor: pointer;
  margin-top: 20px;
  font-family: poppins;
`;

const MenuListWrapper = styled.ul`
  list-style: none;
  margin-left: -25px;
`;

type Props = {
  isOpen: boolean;
  togglemodal: (_args: boolean) => void;
  handleMenuClick: (_args: string) => void;
  activeMenuItem: string;
  user: any;
  logoutHandler: () => void;
  menuList: Array<Record<string, unknown>>;
};

export const MobileSidebar = ({
  isOpen,
  togglemodal,
  handleMenuClick,
  user,
  activeMenuItem,
  logoutHandler,
  menuList,
}: Props) => {
  return (
    <Modal isOpen={isOpen} centered={false} style={{ marginRight: 'inherit', margin: '0 0 0 24px' }}>
      <PopupDiv>
        <PopupHeadingdiv>
          <HeadingOptions>
            <HeadContent>
              <FontAwesomeIcon icon={faCircleUser} size="lg" style={{ color: `${__COLORS.ICON_COLOR}` }} />
              <UserHeading>{`${user?.first_name || ''} ${user?.last_name || ''}`}</UserHeading>
            </HeadContent>
            <HeadContent>
              <FontAwesomeIcon
                icon={faXmark}
                onClick={() => togglemodal(false)}
                style={{ cursor: 'pointer', color: `${__COLORS.ICON_COLOR}` }}
                size="lg"
              />
            </HeadContent>
          </HeadingOptions>
          {user?.role === 'user' && (
            <ViewprofileLink onClick={() => handleMenuClick('/profile-settings')}>View profile</ViewprofileLink>
          )}
        </PopupHeadingdiv>

        <ModalBody>
          <Separator />
          <div>
            <MenuListWrapper>
              {menuList.map((menu: any) => {
                if (menu.isVisible) {
                  return (
                    <SideMenu key={menu.name}>
                      <SideMenuLink
                        onClick={() => handleMenuClick(menu.path[0])}
                        className={menu.path.some((route: string) => activeMenuItem.includes(route)) ? 'active' : ''}
                      >
                        {menu.name}
                      </SideMenuLink>
                    </SideMenu>
                  );
                } else return null;
              })}

              {user?.role === 'user' && (
                <SideMenu>
                  <SideMenuLink
                    className={activeMenuItem === '/support' ? 'active' : ''}
                    onClick={() => handleMenuClick('/support')}
                  >
                    Help & Support
                  </SideMenuLink>
                </SideMenu>
              )}
            </MenuListWrapper>
            <SidebarLastlogin>Last Login</SidebarLastlogin>
            <Separator />
            <div>
              <SidebarDetails>
                <FontAwesomeIcon icon={faClock} style={{ color: `${__COLORS.ICON_COLOR}` }} size="lg" />
                <div>
                  <DetailHeading>Time and Date</DetailHeading>
                  <DetailData>
                    {user && user.last_login && user.last_login.createdAt
                      ? `${moment(user.last_login.createdAt).format('DD MMM YYYY, hh:mm A Z')}`
                      : ''}
                  </DetailData>
                </div>
              </SidebarDetails>
              <SidebarDetails>
                <FontAwesomeIcon icon={faLocationDot} style={{ color: `${__COLORS.ICON_COLOR}` }} size="lg" />
                <div>
                  <DetailHeading>Location</DetailHeading>
                  <DetailData>
                    {user && user.last_login && user.last_login.location
                      ? `${user.last_login.location.city ? `${user.last_login.location.city}, ` : ''} ${
                          user.last_login.location.country ? user.last_login.location.country : ''
                        }`
                      : ''}
                  </DetailData>
                </div>
              </SidebarDetails>
              <SidebarDetails>
                <FontAwesomeIcon icon={faDisplay} style={{ color: `${__COLORS.ICON_COLOR}` }} />
                <div>
                  <DetailHeading>System</DetailHeading>
                  <DetailData>
                    {user && user.last_login && user.last_login.client
                      ? `${user.last_login.client.browser ? user.last_login.client.browser : ''}, ${
                          user.last_login.client.os ? user.last_login.client.os : ''
                        }`
                      : ''}
                  </DetailData>
                </div>
              </SidebarDetails>
            </div>
          </div>
          <Separator />
          <LogoutWrapper onClick={logoutHandler}>
            <FontAwesomeIcon icon={faArrowRightFromBracket} style={{ marginTop: '2px', marginRight: '5px' }} /> Logout
          </LogoutWrapper>
        </ModalBody>
      </PopupDiv>
    </Modal>
  );
};
