import styled from 'styled-components';
import { Spinner } from 'reactstrap';
import { __COLORS } from '../../../shared/Theme';

const ButtonContainer = styled.button`
  width: 300px;
  flex-shrink: 0;
  border-radius: 0.3125rem;
  background: #1a4f70;
  padding: 10px;
  color: ${__COLORS.WHITE_COLOR};
  border: none;
  cursor: pointer;

  @media (min-width: 320px) and (max-width: 786px) {
    color: ${__COLORS.WHITE_COLOR};
    background: transparent;
    border-radius: 5px;
    border: 1px solid #fffefe;
    width: 100%;
  }
`;

export const ButtonWrapper = (props: any) => {
  return (
    <ButtonContainer style={props.style} onClick={!props.isLoading && props.onClick} disabled={props.isLoading}>
      {props.isLoading && (
        <Spinner size="sm" style={{ marginRight: '5px' }}>
          {''}
        </Spinner>
      )}
      {props.isLoading ? 'Loading...' : props.children}
    </ButtonContainer>
  );
};
