import { useState, useEffect, useLayoutEffect, ReactNode } from 'react';
import styled from 'styled-components';
// import Sidebar from './Sidebar/Sidebar';
import Topbar from './Topbar/Topbar';
import { __COLORS } from '../Theme';

const Container = styled.div`
  width: 100%;
`;

// const ContentWrapper = styled.div<{ isSidebarActive: boolean }>`
//   margin-bottom: ${SPACING * 8}px;
//   width: 100%;
//   height: 100vh;
//   ${EXTRA_SMALL_DEVICES`
//     margin-left: ${(props: { isSidebarActive: boolean }) => (props.isSidebarActive ? '0' : '300px')};
//   `}

//   ${LARGE_DEVICES`
//     margin-left: 380px;
//   `};
// `;

// const MobileWrapper = styled.div`
//   // ${SMALL_DEVICES`
//   //   display: none;
//   // `}

//   ${LARGE_DEVICES`
//     display: none;
//   `}

//   position: absolute;
//   // right: ${SPACING * 3}px;
//   z-index: 100000;
//   left: 2px;
//   top: 13px;
// `;

const ContentWrapper = styled.div`
  min-height: calc(100vh - 48px);
  background: ${__COLORS.GREY_BACKGROUND};
  overflow: auto;
`;

type Props = {
  children: ReactNode;
};

const DashboardShell = ({ children }: Props) => {
  // const [isSidebarActive, setIsSidebarActive] = useState(true);

  const useWindowSize = () => {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
  };

  const [width] = useWindowSize();

  useEffect(() => {
    isLargeScreen();
  }, [width]);

  const isLargeScreen = () => {
    let result = true;
    if (
      window.matchMedia('(min-width: 1024px)').matches ||
      window.matchMedia('(min-width: 1200px)').matches
      // ||
      // window.matchMedia('(min-width: 768px)').matches
    ) {
      result = true;
    } else {
      result = false;
    }
    return result;
  };

  // const toggleSideBar = () => {
  //   setIsSidebarActive(!isSidebarActive);
  // };

  // const handleSideBar = () => {
  //   if (isSidebarActive) {
  //     setIsSidebarActive(!isSidebarActive);
  //   }
  // };

  return (
    <Container>
      <Topbar />
      <ContentWrapper>{children}</ContentWrapper>

      {/* <MobileWrapper onClick={() => setIsSidebarActive((isActive) => !isActive)}>
        {!isSidebarActive && <Icons.Menu />}
      </MobileWrapper>
      {(isSidebarActive || isLargeScreen()) && <Sidebar isActive={isSidebarActive} toggleSideBar={toggleSideBar} />}

      <ContentWrapper isSidebarActive={isSidebarActive} onClick={() => handleSideBar()}>
        {children}
      </ContentWrapper> */}
    </Container>
  );
};

export default DashboardShell;
