import styled from 'styled-components';
import { Button } from '../../ui-library';
import { SPACING } from '../../shared/Layout';
import { __COLORS } from '../../shared/Theme';
import { EXTRA_SMALL_DEVICES } from '../../shared/helpers/Mobile';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faCircleXmark } from '@fortawesome/free-regular-svg-icons';

const BodyContainer = styled.div`
  padding: 15px;
  text-align: center;
  ${EXTRA_SMALL_DEVICES`
 padding: 0px;
  `}
`;

const FormWrapper = styled.form`
  position: relative;
  padding: 0;
  margin: 0;
  border: 0;

  & + & {
    margin-top: 24px;
  }

  &:nth-last-of-type(2) {
    margin-top: 32px;
  }

  &:last-of-type {
    text-align: center;
  }
`;
const CardButton = styled.input`
  width: 100%;
  padding: ${SPACING}px ${SPACING * 3}px;
  border: 1px solid ${__COLORS.PRIMARY};
  border-radius: 5px;
  background-color: ${__COLORS.PRIMARY};
  color: ${__COLORS.WHITE_COLOR};
  cursor: pointer;
  &: hover {
    background-color: ${__COLORS.PRIMARY};
    color: ${__COLORS.WHITE_COLOR};
  }
`;

const InfoText = styled.h4`
  font-weight: 300;
  line-height: 2.625rem;
  font-family: 'Poppins';
  margin-top: 15px;
`;

type Props = {
  openModal: string;
  toggleModal: (_arg: string) => void;
};

const PaymentStatusModal = ({ openModal, toggleModal }: Props) => {
  const history = useHistory();

  return (
    <>
      <Modal isOpen={openModal === 'success' || openModal === 'failure'} centered={true} style={{ marginTop: '45px' }}>
        <ModalBody>
          <BodyContainer>
            {openModal === 'success' ? (
              <>
                <FontAwesomeIcon icon={faCircleCheck} style={{ color: `${__COLORS.GREEN_COLOR}` }} size="4x" />

                <InfoText>{`Congratulations, Your payment has been completed successfully.`}</InfoText>
              </>
            ) : (
              <>
                <FontAwesomeIcon icon={faCircleXmark} style={{ color: `${__COLORS.RED_COLOR}` }} size="4x" />
                <InfoText>Oops! Your payment has been failed.</InfoText>
              </>
            )}
          </BodyContainer>
        </ModalBody>
        <ModalFooter>
          <>
            <Button
              onClick={() => {
                sessionStorage.setItem('Plan', '');
                history.push('/billing');
                toggleModal('');
              }}
              style={{ marginRight: '10px' }}
              isSecondary
            >
              Cancel
            </Button>
            {openModal === 'success' ? (
              <Button onClick={() => history.push('/portfolio')}>Go to Dashboard</Button>
            ) : (
              <FormWrapper
                target="_blank"
                id="paymentFormRetry"
                action={`${process.env.REACT_APP_API_ENDPOINT}/v1/payment/create-order`}
                method="post"
              >
                <CardButton
                  type="hidden"
                  name="subscription_type"
                  id="sub_type"
                  value={sessionStorage.getItem('Plan') || ''}
                />
                <CardButton type="hidden" value={sessionStorage.getItem('token') || ''} name="token" />
                <CardButton
                  type="submit"
                  value="Please try again"
                  // onClick={() => {
                  //   toggleModal('');
                  // }}
                />
              </FormWrapper>
            )}
          </>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default PaymentStatusModal;
