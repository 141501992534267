import styled from 'styled-components';
import ContactInfoSection from './ContactInfoSection';
import CommunicationFrequencySection from './CommunicationFrequencySection';
import CommunicationModeSection from './CommunicationModeSection';
import SocialMediaSection from './SocialMediaSection';
import { useState, useEffect } from 'react';
import { useApi } from '../../shared/api';
import { toast } from 'react-toastify';
import { PageHeading } from '../../shared/components/PageHeading/PageHeading';
import { PageContent } from '../../shared/components/PageContent/PageContent';
import { __COLORS } from '../../shared/Theme';

const LeftDiv = styled.div`
  width: 60%;
  @media (min-width: 320px) and (max-width: 890px) {
    width: 100%;
  }
`;

const RightDiv = styled.div`
  width: 38%;
  height: 100%;

  @media (min-width: 320px) and (max-width: 890px) {
    width: 100%;
    margin: 0;
  }
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  @media (min-width: 320px) and (max-width: 890px) {
    flex-wrap: wrap;
  }
`;

const CommonContainer = styled.div`
  flex -shrink: 0;
  border-Radius: 0.625rem;
  background: ${__COLORS.WHITE_COLOR};
  margin: 1.5rem 0;
`;

const Preferences = () => {
  const api = useApi();

  const [preferenceData, setPreferenceData] = useState({});

  useEffect(() => {
    getPreferenceData();
  }, []);

  const getPreferenceData = async () => {
    try {
      const response = await api.users.getPreference();
      if (response && response.communicationObject) {
        setPreferenceData(response.communicationObject);
      }
    } catch (e) {
      toast.error((e as Error).message);
    }
  };

  return (
    <>
      <PageHeading header="Preferences" />
      <PageContent>
        <Wrapper>
          <LeftDiv>
            <CommonContainer>
              <ContactInfoSection />
            </CommonContainer>
            <CommonContainer>
              <CommunicationModeSection preferenceData={preferenceData} />
            </CommonContainer>
            <CommonContainer>
              <SocialMediaSection />
            </CommonContainer>
          </LeftDiv>
          <RightDiv>
            <CommonContainer>
              <CommunicationFrequencySection preferenceData={preferenceData} getPreferenceData={getPreferenceData} />
            </CommonContainer>
          </RightDiv>
        </Wrapper>
      </PageContent>
    </>
  );
};

export default Preferences;
