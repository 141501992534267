import { useState, useEffect, ChangeEvent } from 'react';
import { useSelector, RootStateOrAny } from 'react-redux';
import styled from 'styled-components';
import { Button } from '../../ui-library';
import { useDispatch } from 'react-redux';
// import { countryCodeList } from '../../shared/options';
import { useApi } from '../../shared/api';
import { setUser } from '../../redux/users/userSlice';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPen } from '@fortawesome/free-solid-svg-icons';
import { __COLORS } from '../../shared/Theme';
import InputWrapper from '../../ui-library/Input/InputWrapper';
import { IsInputNumber } from '../../shared/helpers/IsInputNumber';

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1.37rem 1.44rem;
  border-bottom: 0.0625rem solid #eeebeb;
`;

const SectionHeading = styled.h1`
  color: #303c4e;
  text-align: center;
  font-family: Poppins;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  @media (min-width: 320px) and (max-width: 500px) {
    font-size: 1rem;
  }
`;

const EditIconWrapper = styled.span`
  color: ${__COLORS.ICON_COLOR};
  cursor: pointer;
  display: flex;
`;

const ContentWrapper = styled.div`
  padding: 1.44rem;
`;

const FormField = styled.div`
  padding: '0 0.88rem 0 0.88rem';
  display: flex;
  @media (min-width: 320px) and (max-width: 500px) {
    flex-wrap: wrap;
  }
`;
const FieldLabel = styled.label`
  width: 8rem;
  padding: 5px;
  display: flex;
  align-items: center;
`;

const ValueWrapper = styled.input`
  margin-left: 5px;
  color: ${__COLORS.VALUE_COLOR};
  width: 100%;
`;

interface MessageType {
  [key: string]: string;
}

interface UserData {
  email?: string;
  phone?: string;
}

const ContactInfoSection = () => {
  const api = useApi();
  const dispatch = useDispatch();
  const user = useSelector((s: RootStateOrAny) => s.users.user);

  const [isEditing, setIsEditing] = useState(false);
  const [email, setEmail] = useState(user?.email);
  const [phone, setPhone] = useState(user?.phone);
  // const [countryCode, setCountryCode] = useState('IN');
  const [message, setMessage] = useState<MessageType>({});

  const saveDetails = async () => {
    try {
      if (validateForm()) {
        // let selectedCountryCode = '';
        // Object.entries(countryCodeList).map(([key, value]) => {
        //   if (countryCode === key) {
        //     selectedCountryCode = value.primary;
        //   }
        // });

        const data: UserData = {};
        if (user?.email !== email) {
          data['email'] = email;
        }
        if (user?.phone !== phone) {
          data['phone'] = phone;
        }
        // if (user?.country_Code !== selectedCountryCode) {
        //   data['country_Code'] = selectedCountryCode;
        // }

        await api.users.update(data);
        const response = await api.users.getUser();
        dispatch(setUser(response.data));
        toast.success('Contact Info updated successfully.');
        setIsEditing(false);
      }
    } catch (e) {
      toast.error((e as Error).message);
    }
  };

  useEffect(() => {
    // Object.entries(countryCodeList).map(([key, value]) => {
    //   if (user) {
    //     if (user?.country_Code === value.primary) {
    //       setCountryCode(key);
    //     }
    //   }
    // });
    if (user) {
      setUserDetails();
    }
  }, [user]);

  const setUserDetails = () => {
    setEmail(user?.email || '');
    setPhone(user?.phone || '');
  };

  const Seperator = styled.hr`
    margin: 1.5rem 0;
    color: inherit;
    border: 0;
    border-top: var(--bs-border-width) solid;
    opacity: 0.15;
    @media (min-width: 320px) and (max-width: 786px) {
      margin: 1rem 0;
    }
  `;

  const validateForm = () => {
    setMessage({});
    const tempMessage = {
      email: '',
      phone: '',
    };
    let result = true;
    if (!/^[a-zA-Z0-9.!#$%&'+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)$/.test(email) || !email.trim().length) {
      tempMessage.email = 'Please enter valid email address!';
      result = false;
    }
    if (phone.length < 10 || !phone.trim().length) {
      tempMessage.phone = 'Phone number must be 10 digits long!';
      result = false;
    }
    setMessage({ ...tempMessage });
    return result;
  };

  const resetForm = () => {
    setUserDetails();
    setIsEditing(false);
  };

  return (
    <>
      <HeaderContainer>
        <SectionHeading>CONTACT INFO</SectionHeading>
        <EditIconWrapper>
          {isEditing ? (
            <>
              <Button onClick={() => resetForm()} isSecondary style={{ marginRight: '10px' }}>
                Close
              </Button>
              <Button onClick={saveDetails} isPrimary>
                Save
              </Button>
            </>
          ) : (
            <FontAwesomeIcon icon={faUserPen} size="lg" onClick={() => setIsEditing(true)} />
          )}
        </EditIconWrapper>
      </HeaderContainer>
      <ContentWrapper>
        <FormField>
          <FieldLabel>Email</FieldLabel>
          {isEditing ? (
            <InputWrapper
              value={email}
              onChange={(e: ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
              placeholder="Enter Email"
              errorMessage={message?.email}
              style={{ marginBottom: '0' }}
            />
          ) : (
            <ValueWrapper readOnly value={email} />
          )}
        </FormField>
        <Seperator />
        <FormField>
          <FieldLabel>Phone</FieldLabel>
          {isEditing ? (
            <InputWrapper
              value={phone}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                if (IsInputNumber(e.target.value)) {
                  setPhone(e.target.value);
                }
              }}
              placeholder="Enter Phone Number"
              errorMessage={message?.phone}
              style={{ marginBottom: '0' }}
              maxLength={10}
            />
          ) : (
            <ValueWrapper readOnly value={phone} />
          )}
        </FormField>
      </ContentWrapper>
    </>
  );
};

export default ContactInfoSection;
