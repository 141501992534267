import styled from 'styled-components';
import { __COLORS } from '../../shared/Theme';
import { Button } from '../../ui-library';
import { toast } from 'react-toastify';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useApi } from '../../shared/api';
import { EXTRA_SMALL_DEVICES } from '../../shared/helpers/Mobile';

const Logo = styled.h2`
  font-weight: 500;
  color: ${__COLORS.PRIMARY};
`;

const BodyContainer = styled.div`
  padding: 15px;
  ${EXTRA_SMALL_DEVICES`
 padding: 0px;
  `}
`;

export const Label = styled.p`
  font-weight: 300;
  text-transform: uppercase;
  font-size: 14px;
  color: gray;
  width: 30%;
  ${EXTRA_SMALL_DEVICES`
  width: 50%;
  `}
`;

export const Value = styled.p`
  font-weight: 500;
  font-size: 16px;
`;

export const LabelValueWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

type Props = {
  openModal: boolean;
  toggleModal: () => void;
  getPreferenceData: () => void;
};

const WellbeingModal = ({ openModal, toggleModal, getPreferenceData }: Props) => {
  const api = useApi();

  const confirmWellbeing = () => {
    try {
      const userToken = sessionStorage.getItem('token') || '';
      api.users.verifyWellbeing(userToken);
      getPreferenceData();
      toast.success('Wellbeing has been confirmed successfully.');
      toggleModal();
    } catch (e) {
      toast.error((e as Error).message);
    }
  };

  return (
    <Modal isOpen={openModal} style={{ marginTop: '45px' }}>
      <ModalHeader>
        <Logo>CONFIRMATION</Logo>
      </ModalHeader>
      <ModalBody>
        <BodyContainer>
          Please confirm your wellbeing to be able to change your communication preferences.
        </BodyContainer>
      </ModalBody>
      <ModalFooter>
        <Button isSecondary onClick={toggleModal}>
          No
        </Button>
        <Button onClick={() => confirmWellbeing()}>Yes</Button>
      </ModalFooter>
    </Modal>
  );
};

export default WellbeingModal;
