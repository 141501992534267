import Select from 'react-select';
import { __COLORS } from '../../shared/Theme';
import styled from 'styled-components';

const ErrorMessage = styled.div`
  color: red;
  font-size: 14px;
  text-align: left;
  margin: 0 auto;
`;
const Container = styled.div<{ width?: string }>`
  width: ${(props) => (props.width ? props.width : '19rem')};
  @media (min-width: 320px) and (max-width: 500px) {
    width: 100%;
  }
`;

export const SelectWrapper = (props: any) => {
  const {
    options,
    value,
    onChange,
    errorMessage,
    isMulti = false,
    width,
    placeholder = '',
    isClearable = true,
    isDisabled = false,
  } = props;

  return (
    <Container width={width}>
      <Select
        {...props}
        options={options}
        value={value}
        onChange={onChange}
        isClearable={isClearable}
        isDisabled={isDisabled}
        isMulti={isMulti}
        placeholder={placeholder}
        styles={{
          control: (baseStyles) => ({
            ...baseStyles,
            border: 'none',
            boxShadow: 'none',
            backgroundColor: `${__COLORS.INPUT_GREY}`,
            width: '100%',
            borderRadius: '4px',
            height: `${props.height}`,
            '@media (min-width: 320px) and (max-width: 500px)': {
              width: '100%',
            },
          }),
        }}
      />
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </Container>
  );
};
