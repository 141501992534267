import React from 'react';
import styled from 'styled-components';
import BasicInfoSection from './BasicInfoSection';
import CommunicationSection from './CommunicationSection';
import { PageHeading } from '../../shared/components/PageHeading/PageHeading';
import { PageContent } from '../../shared/components/PageContent/PageContent';
import { __COLORS } from '../../shared/Theme';

const CommonSections = styled.div`
  width: 50%;
  flex -shrink: 0;
  border-Radius: 0.625rem;
  background: ${__COLORS.WHITE_COLOR};
  margin: 1.5rem 0;
  @media (min-width: 320px) and (max-width: 890px) {
   width: 100%;
  }
`;

const Preferences = () => {
  return (
    <>
      <PageHeading header="Profile Settings" />
      <PageContent>
        <CommonSections>
          <BasicInfoSection />
        </CommonSections>
        <CommonSections>
          <CommunicationSection />
        </CommonSections>
      </PageContent>
    </>
  );
};

export default Preferences;
