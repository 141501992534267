import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { PageContent } from '../../../shared/components/PageContent/PageContent';
import { PageHeading } from '../../../shared/components/PageHeading/PageHeading';
import { __COLORS } from '../../../shared/Theme';
import { SPACING } from '../../../shared/Layout';
import Loading from '../../Loading/Loading';
import AddAdminModal from './AddAdminModal';
import { Pagination } from '../../../ui-library';
import { toast } from 'react-toastify';
import { useApi } from '../../../shared/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare, faTrash, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import DeleteAdminModal from './DeleteAdminModal';
import useDelay from '../../../shared/hooks/useDelay';

const TableWrapperDiv = styled.div`
  overflow-x: auto;
  max-height: 500px;
  overflow-y: auto;
`;
const TableRow = styled.tr`
  height: 6.625rem;
  border: 0.188rem solid #f5f5f7;
  @media (min-width: 320px) and (max-width: 1200px) {
    height: 5.625rem;
  }
`;

const InnerTableData = styled.div<{ showBorder: boolean }>`
  border-right: ${(props) => (props.showBorder ? '0.03125rem solid #303c4e' : 'none')};
  height: 4.26031rem;
  align-items: center;
  display: flex;
  justify-content: center;
  @media (min-width: 320px) and (max-width: 786px) {
    height: 3.26031rem;
  }
`;
const PortfolioTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  text-align: center;
  & td {
    background: ${__COLORS.WHITE_COLOR};
    padding: 0.625rem 0 0.625rem 0.625rem;
    border-bottom: 0.125rem solid;
    @media (min-width: 320px) and (max-width: 1200px) {
      padding: 0.313rem 0 0.313rem 0.313rem;
    }
  }
`;
const TableHeading = styled.th`
  width: 16%;
  border: 3px solid #f5f5f7;
  font-size: 1rem;
  font-family: Poppins;
  font-weight: 500;
  color: ${__COLORS.ICON_COLOR};
  background: ${__COLORS.WHITE_COLOR};
  height: 5.0625rem;
  flex-shrink: 0;
  border-radius: 0.9375rem 0.9375rem 0rem 0rem;
  white-space: nowrap;
  padding: 0 10px;
  @media (min-width: 320px) and (max-width: 1200px) {
    font-size: 0.938rem;
    height: 4.0625rem;
  }
`;

const Tdata = styled.h1`
  color: ${__COLORS.ICON_COLOR};
  text-align: center;
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 0 20px;
  @media (min-width: 320px) and (max-width: 1200px) {
    font-size: 0.875rem;
    padding: 0 10px;
  }
`;

const NoRecordsText = styled.div`
  text-align: center;
  margin: ${SPACING * 2}px 0;
  width: 100%;
  color: ${__COLORS.SECONDARY_DARK};
`;

const PortfolioHeaderRow = styled.tr`
  border: 0.125rem solid #614be2;
`;

const ActionWrapper = styled.div`
  display: flex;
  gap: 15px;
`;
const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  justify-content: flex-end;
`;

const InputWithIcon = styled.div`
  display: flex;
  width: 300px;
  align-items: center;
  border: none;
  padding: 0.5rem;
  background: ${__COLORS.WHITE_COLOR};
  border-radius: 0.3125rem !important;
`;

const InputSearch = styled.input`
  flex: 1;
  border: none;
  outline: none;
  background: ${__COLORS.WHITE_COLOR};
  width: 100%;
`;

type DataType = {
  [key: string]: unknown;
};

export const AdminUsers = () => {
  const api = useApi();
  const [isLoading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [isAddNewModal, setAddNewModal] = useState(false);
  const [adminUsers, setAdminUsers] = useState([]);
  const [count, setCount] = useState(0);
  const [selectedUser, setSelectedUser] = useState({});
  const [isDeleteModal, setDeleteModal] = useState(false);
  const [searchUser, setSearchUser] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const debouncedSearchUser = useDelay(searchValue, 700);

  useEffect(() => {
    getAdminUsers();
  }, []);

  useEffect(() => {
    getAdminUsers();
  }, [currentPage, searchUser]);

  useEffect(() => {
    if (debouncedSearchUser) {
      setSearchUser(debouncedSearchUser);
      setCurrentPage(1);
    } else {
      setSearchUser('');
      setCurrentPage(1);
    }
  }, [debouncedSearchUser]);

  const toggleAddNewModal = (data: DataType = {}) => {
    setSelectedUser(data);
    setAddNewModal(!isAddNewModal);
  };

  const getAdminUsers = async () => {
    try {
      const response = await api.admin.getAdminUsers({
        pageNo: currentPage,
        searchTerm: searchUser,
      });
      if (response.adminList) setAdminUsers(response.adminList);
      if (response.count) setCount(response.count);
      setLoading(false);
    } catch (e) {
      toast.error((e as Error).message);
      setLoading(false);
    }
  };

  const toggleDeleteModal = (data: DataType = {}) => {
    setSelectedUser(data);
    setDeleteModal(!isDeleteModal);
  };

  return (
    <>
      <PageHeading header="Admin Users" buttonText="Add New" handleClick={toggleAddNewModal} />
      <PageContent>
        <FilterContainer>
          <InputWithIcon>
            <FontAwesomeIcon icon={faMagnifyingGlass} style={{ marginRight: '0.5rem' }} size="sm" />
            <InputSearch
              type="text"
              id="searchText"
              name="searchText"
              placeholder="Search User..."
              onChange={(e) => setSearchValue(e.target.value)}
              value={searchValue}
              autoComplete="off"
            />
          </InputWithIcon>
        </FilterContainer>
        <TableWrapperDiv>
          <PortfolioTable>
            <PortfolioHeaderRow>
              <TableHeading>FIRST NAME</TableHeading>
              <TableHeading>LAST NAME</TableHeading>
              <TableHeading>PHONE</TableHeading>
              <TableHeading>EMAIL</TableHeading>
              <TableHeading>ACTION</TableHeading>
            </PortfolioHeaderRow>

            {adminUsers?.length
              ? adminUsers?.map((item: any, idx: number) => {
                  return (
                    <TableRow key={idx}>
                      <td>
                        <InnerTableData showBorder>
                          <Tdata>{item.first_name}</Tdata>
                        </InnerTableData>
                      </td>
                      <td>
                        <InnerTableData showBorder>
                          <Tdata>{item.last_name}</Tdata>
                        </InnerTableData>
                      </td>
                      <td>
                        <InnerTableData showBorder>
                          <Tdata>{item.phone}</Tdata>
                        </InnerTableData>
                      </td>

                      <td>
                        <InnerTableData showBorder>
                          <Tdata>{item.email}</Tdata>
                        </InnerTableData>
                      </td>
                      <td>
                        <InnerTableData showBorder={false}>
                          <Tdata>
                            <ActionWrapper>
                              <FontAwesomeIcon
                                icon={faPenToSquare}
                                size="lg"
                                onClick={() => toggleAddNewModal(item)}
                                style={{ cursor: 'pointer' }}
                              />
                              <FontAwesomeIcon
                                size="lg"
                                icon={faTrash}
                                style={{ cursor: 'pointer' }}
                                onClick={() => toggleDeleteModal(item)}
                              />
                            </ActionWrapper>
                          </Tdata>
                        </InnerTableData>
                      </td>
                    </TableRow>
                  );
                })
              : null}
          </PortfolioTable>
        </TableWrapperDiv>
        {adminUsers?.length ? <Pagination totalCount={count} onChangeHandler={setCurrentPage} /> : null}
        <>
          {isLoading && !adminUsers.length ? (
            <Loading />
          ) : !adminUsers?.length ? (
            <NoRecordsText>No records found</NoRecordsText>
          ) : null}
        </>
        {isAddNewModal && (
          <AddAdminModal
            open={isAddNewModal}
            toggleModal={toggleAddNewModal}
            getAdminUsers={getAdminUsers}
            user={selectedUser}
          />
        )}
        {isDeleteModal && (
          <DeleteAdminModal
            open={isDeleteModal}
            toggleModal={toggleDeleteModal}
            getAdminUsers={getAdminUsers}
            user={selectedUser}
          />
        )}
      </PageContent>
    </>
  );
};
