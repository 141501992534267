import { ReactElement } from 'react';
import { useSelector, RootStateOrAny } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { USER_MANAGEMENT, PORTFOLIO } from './Routes';

type Props = {
  children: ReactElement;
};

export const PublicRoutes = ({ children }: Props) => {
  const history = useHistory();
  const user = useSelector((s: RootStateOrAny) => s.users.user);

  const tokenValue = sessionStorage.getItem('token');
  if (!user && tokenValue) {
    const encodedPayload = tokenValue.split('.')[1];
    const decodedPayload = atob(encodedPayload);
    const user = JSON.parse(decodedPayload);

    if (['admin', 'super_admin'].includes(user?.role)) {
      history.replace(USER_MANAGEMENT);
    } else if (user.role === 'user') {
      history.replace(PORTFOLIO);
    }
  }

  return children;
};
