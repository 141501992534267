import { useState, useEffect, ChangeEvent } from 'react';
import { useSelector, RootStateOrAny } from 'react-redux';
import styled from 'styled-components';
import { __COLORS } from '../../shared/Theme';
import { Button } from '../../ui-library';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPen } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faTwitter, faWhatsapp, faInstagramSquare } from '@fortawesome/free-brands-svg-icons';
import InputWrapper from '../../ui-library/Input/InputWrapper';
import { useApi } from '../../shared/api';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { setUser } from '../../redux/users/userSlice';

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1.37rem 1.44rem;
  border-bottom: 0.0625rem solid #eeebeb;
`;

const SectionHeading = styled.h1`
  color: #303c4e;
  text-align: center;
  font-family: Poppins;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  @media (min-width: 320px) and (max-width: 500px) {
    font-size: 1rem;
  }
`;

const EditIconWrapper = styled.span`
  color: ${__COLORS.ICON_COLOR};
  cursor: pointer;
  display: flex;
`;

const ContentWrapper = styled.div`
  padding: 1.44rem;
`;

const FormField = styled.div`
  padding: '0 0.88rem 0 0.88rem';
  display: flex;
  @media (min-width: 320px) and (max-width: 500px) {
    flex-wrap: wrap;
  }
`;
const FieldLabel = styled.label`
  width: 8rem;
  padding: 5px;
  display: flex;
  align-items: center;
`;

const LogoWrapper = styled.div`
  padding: 0.89rem 1.38rem;
  gap: 1.875rem;
`;

const SocialIcon = styled(FontAwesomeIcon)`
  cursor: pointer;
  padding: 0.625rem;
`;

const Seperator = styled.hr`
  margin: 1.5rem 0;
  color: inherit;
  border: 0;
  border-top: var(--bs-border-width) solid;
  opacity: 0.15;
  @media (min-width: 320px) and (max-width: 786px) {
    margin: 1rem 0;
  }
`;

interface MessageType {
  [key: string]: string;
}

const SocialMediaSection = () => {
  const api = useApi();
  const dispatch = useDispatch();
  const user = useSelector((s: RootStateOrAny) => s.users.user);

  const [isEditing, setIsEditing] = useState(false);
  const [facebookLink, setFacebookLink] = useState('');
  const [instagramLink, setinstagramLink] = useState('');
  const [whatsappLink, setwhatsappLink] = useState('');
  const [twitterLink, setTwitterLink] = useState('');
  const [message, setMessage] = useState<MessageType>({});

  useEffect(() => {
    if (user) {
      setUserDetails();
    }
  }, [user]);

  const resetForm = () => {
    setUserDetails();
    setIsEditing(false);
  };

  const setUserDetails = () => {
    setFacebookLink(user?.facebookLink || '');
    setinstagramLink(user?.instagramLink || '');
    setwhatsappLink(user?.whatsappLink || '');
    setTwitterLink(user?.twitterLink || '');
  };

  const saveDetails = async () => {
    try {
      if (validateForm()) {
        await api.users.update({
          facebookLink,
          instagramLink,
          whatsappLink,
          twitterLink,
        });
        const response = await api.users.getUser();
        dispatch(setUser(response.data));
        toast.success('Socail Media Info updated successfully.');
        setIsEditing(false);
      }
    } catch (e) {
      toast.error((e as Error).message);
    }
  };

  const validateForm = () => {
    setMessage({});
    const tempMessage = {
      facebookLink: '',
      instagramLink: '',
      whatsappLink: '',
      twitterLink: '',
    };

    let result = true;
    if (facebookLink.trim().length && !/^https:\/\/www\.facebook\.com\//.test(facebookLink)) {
      tempMessage.facebookLink = 'Please enter valid facebook link!';
      result = false;
    }

    if (instagramLink.trim().length && !/^https:\/\/www\.instagram\.com\//.test(instagramLink)) {
      tempMessage.instagramLink = 'Please enter valid instagram link!';
      result = false;
    }

    if (whatsappLink.trim().length && !/^https:\/\/wa\.me\//i.test(whatsappLink)) {
      tempMessage.whatsappLink = 'Please enter valid whatsapp link!';
      result = false;
    }

    if (twitterLink.trim().length && !/^https:\/\/www\.twitter\.com\//.test(twitterLink)) {
      tempMessage.twitterLink = 'Please enter valid twitter link!';
      result = false;
    }

    setMessage({ ...tempMessage });
    return result;
  };
  return (
    <>
      <HeaderContainer>
        <SectionHeading>CONNECT TO SOCIAL MEDIA</SectionHeading>
        <EditIconWrapper>
          {isEditing ? (
            <>
              <Button onClick={() => resetForm()} isSecondary style={{ marginRight: '10px' }}>
                Close
              </Button>
              <Button onClick={saveDetails} isPrimary>
                Save
              </Button>
            </>
          ) : (
            <FontAwesomeIcon icon={faUserPen} size="lg" onClick={() => setIsEditing(true)} />
          )}
        </EditIconWrapper>
      </HeaderContainer>
      <ContentWrapper>
        {isEditing ? (
          <>
            <FormField>
              <FieldLabel>Facebook</FieldLabel>
              <InputWrapper
                value={facebookLink}
                onChange={(e: ChangeEvent<HTMLInputElement>) => setFacebookLink(e.target.value)}
                placeholder="Facebook Profile Link"
                style={{ marginBottom: '0' }}
                errorMessage={message?.facebookLink}
              />
            </FormField>
            <Seperator />
            <FormField>
              <FieldLabel>Instagram</FieldLabel>
              <InputWrapper
                value={instagramLink}
                onChange={(e: ChangeEvent<HTMLInputElement>) => setinstagramLink(e.target.value)}
                placeholder="Instagram Profile Link"
                style={{ marginBottom: '0' }}
                errorMessage={message?.instagramLink}
              />
            </FormField>
            <Seperator />
            <FormField>
              <FieldLabel>Whatsapp</FieldLabel>
              <InputWrapper
                value={whatsappLink}
                onChange={(e: ChangeEvent<HTMLInputElement>) => setwhatsappLink(e.target.value)}
                placeholder="Whatsapp Profile Link"
                style={{ marginBottom: '0' }}
                errorMessage={message?.whatsappLink}
              />
            </FormField>
            <Seperator />

            <FormField>
              <FieldLabel>Twitter</FieldLabel>
              <InputWrapper
                value={twitterLink}
                onChange={(e: ChangeEvent<HTMLInputElement>) => setTwitterLink(e.target.value)}
                placeholder="Twitter Profile Link"
                style={{ marginBottom: '0' }}
                errorMessage={message?.twitterLink}
              />
            </FormField>
          </>
        ) : (
          <LogoWrapper>
            {user?.facebookLink ? (
              <a href={`${user?.facebookLink}`} target="_blank">
                <SocialIcon icon={faFacebook} color="#316FF6" size="2x" />
              </a>
            ) : null}
            {user?.instagramLink ? (
              <a href={`${user?.instagramLink}`} target="_blank">
                <SocialIcon icon={faInstagramSquare} color="#c13584" size="2x" />
              </a>
            ) : null}
            {user?.whatsappLink ? (
              <a href={`${user?.whatsappLink}`} target="_blank">
                <SocialIcon icon={faWhatsapp} color="#80D25B" size="2x" />
              </a>
            ) : null}
            {user?.twitterLink ? (
              <a href={`${user?.twitterLink}`} target="_blank">
                <SocialIcon icon={faTwitter} color="#5EA7FF" size="2x" />
              </a>
            ) : null}
          </LogoWrapper>
        )}
      </ContentWrapper>
    </>
  );
};

export default SocialMediaSection;
