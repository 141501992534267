import { ChangeEvent, FormEvent, useState } from 'react';
import styled from 'styled-components';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import InputWrapper from '../../../ui-library/Input/InputWrapper';
import { Button } from '../../../ui-library';

import { useApi } from '../../../shared/api';
import { toast } from 'react-toastify';
import { IsInputNumber } from '../../../shared/helpers/IsInputNumber';
import { __COLORS } from '../../../shared/Theme';

const PopupHeadingdiv = styled.div`
  height: 4.87rem;
  padding: 1.31rem;
  border-bottom: 0.0625rem solid #e3dbdb;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const PopupHeading = styled.h1`
  color: ${__COLORS.ICON_COLOR};
  font-family: Poppins;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

const FormField = styled.div`
  display: flex;
  margin-bottom: 10px;
  @media (min-width: 320px) and (max-width: 500px) {
    flex-wrap: wrap;
    width: 100%;
  }
`;

const FieldLabel = styled.label`
  width: 8rem !important;
  padding: 5px;
  display: flex;
  align-items: center;
`;

interface MessageType {
  [key: string]: string;
}

type Props = {
  open: boolean;
  toggleModal: () => void;
  user: any;
  getAdminUsers: () => void;
};

const AddAdminModal = ({ open, toggleModal, user, getAdminUsers }: Props) => {
  const api = useApi();

  const [firstName, setFirstName] = useState(user?.first_name || '');
  const [lastName, setLastname] = useState(user?.last_name || '');
  const [phoneNumber, setPhoneNumber] = useState(user?.phone || '');
  const [email, setEmail] = useState(user?.email || '');
  const [errorMessage, setErrorMessage] = useState<MessageType>({});
  const [isLoading, setIsLoading] = useState(false);

  const addNewUser = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setErrorMessage({});

    try {
      if (validateForm()) {
        setIsLoading(true);

        if (user?.email) {
          await api.admin.editAdmin({
            first_name: firstName,
            last_name: lastName,
            phone: phoneNumber,
            email,
          });

          toast.success('Admin user updated successfully.');
        } else {
          await api.admin.createAdmin({
            first_name: firstName,
            last_name: lastName,
            phone: phoneNumber,
            email,
          });

          toast.success('Admin user added successfully.');
        }

        getAdminUsers();
        toggleModal();
      }
    } catch (e) {
      toast.error((e as Error).message);
    }
    setIsLoading(false);
  };

  const validateForm = () => {
    setErrorMessage({});
    const tempMessage = {
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
    };
    let result = true;

    if (!/^[a-zA-Z0-9.!#$%&'+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)$/.test(email) || !email.trim().length) {
      tempMessage.email = 'Please enter valid email address!';
      result = false;
    }
    if (phoneNumber.length < 10 || !phoneNumber.trim().length) {
      tempMessage.phoneNumber = 'Phone number must be 10 digits long!';
      result = false;
    }
    if (!firstName.trim().length) {
      tempMessage.firstName = 'Please enter valid first name!';
      result = false;
    }
    if (!lastName.trim().length) {
      tempMessage.firstName = 'Please enter valid last name!';
      result = false;
    }
    setErrorMessage({ ...tempMessage });
    return result;
  };

  return (
    <Modal isOpen={open} centered={true} size="md">
      <PopupHeadingdiv>
        <PopupHeading>{`${user?.first_name ? 'Edit' : 'Add'} Admin User`}</PopupHeading>
        <FontAwesomeIcon icon={faXmark} onClick={toggleModal} style={{ cursor: 'pointer' }} />
      </PopupHeadingdiv>
      <ModalBody>
        <FormField>
          <FieldLabel>First Name </FieldLabel>
          <InputWrapper
            value={firstName}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setFirstName(e.target.value)}
            placeholder="First Name"
            errorMessage={errorMessage?.firstName}
          />
        </FormField>
        <FormField>
          <FieldLabel>Last Name </FieldLabel>
          <InputWrapper
            value={lastName}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setLastname(e.target.value)}
            placeholder="Last Name"
            errorMessage={errorMessage?.lastName}
          />
        </FormField>

        <FormField>
          <FieldLabel>Phone </FieldLabel>
          <InputWrapper
            value={phoneNumber}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              if (IsInputNumber(e.target.value)) {
                setPhoneNumber(e.target.value);
              }
            }}
            placeholder="Phone Number"
            errorMessage={errorMessage?.phoneNumber}
            maxLength={10}
          />
        </FormField>
        <FormField>
          <FieldLabel>Email </FieldLabel>
          <InputWrapper
            value={email}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
            placeholder="Email"
            disabled={user?.email}
            errorMessage={errorMessage?.email}
          />
        </FormField>
      </ModalBody>
      <ModalFooter style={{ gap: '1rem' }}>
        <Button isSecondary onClick={toggleModal}>
          Close
        </Button>
        <Button isPrimary onClick={addNewUser} isLoading={isLoading}>
          Save Changes
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AddAdminModal;
