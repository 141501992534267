import { useState, useEffect, Fragment } from 'react';
import styled from 'styled-components';
import { __COLORS } from '../../shared/Theme';
import PaymentStatusModal from './PaymentStatusModal';
import {
  EXTRA_LARGE_DEVICES_MAX,
  LAPTOP_DEVICES_MAX,
  MEDIUM_DEVICES,
  MOBILE_DEVICES,
  MOBILE_DEVICES_MIN,
} from '../../shared/helpers/Mobile';
import { useApi } from '../../shared/api';
import Loading from '../Loading/Loading';
import { useSelector, RootStateOrAny } from 'react-redux';
import { toast } from 'react-toastify';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex: wrap;
  justify-content: center;
  ${MEDIUM_DEVICES`
  display: block;
  `}
  ${MOBILE_DEVICES_MIN`
  display: block;
   `}
  ${MOBILE_DEVICES`
  display:block;`}
  ${LAPTOP_DEVICES_MAX`
  display:flex;
  `}
`;
const CardWrapper = styled.div<{ borderColor?: string }>`
  overflow: hidden !important;
  padding: 20px !important;
  margin: 20px 45px 20px 20px;
  min-height: 500px !important;
  width: 300px !important;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.05), 0 0px 40px rgba(0, 0, 0, 0.08) !important;
  border-radius: 5px !important;
  transition: all 300ms !important;
  &:hover {
    transform: scale(1.1);
  }
  cursor: pointer;
  background-color: ${__COLORS.WHITE_COLOR};

  border: ${({ borderColor }) => (borderColor ? borderColor : '')};

  ${MOBILE_DEVICES_MIN`
    margin: 0 auto !important;
  width: 85%;
  min-height: 400px;
  margin-bottom: 30px !important;
  padding: 20px;
  margin: 0px 7px;
   `}
  ${MEDIUM_DEVICES`
  margin: 0 auto !important;
  width: 70%;
  margin-bottom: 30px !important;
  padding: 20px;
  margin: 0px 7px;
  `}
  
   ${MOBILE_DEVICES`
   margin: 0 auto !important;
  width: 70%;
  margin-bottom: 30px !important;
  padding: 20px;
  margin: 0px 7px;
   `}
   ${LAPTOP_DEVICES_MAX`
    padding: 20px;
  margin: 20px !important;
  width: 25%;
   `}
   ${EXTRA_LARGE_DEVICES_MAX`
     width: 45%;
   `}
`;

const CardHeader = styled.header``;

const CardHeading = styled.h1`
  font-size: 19px;
  text-transform: uppercase;
  font-weight: 600;
  text-align: center;
`;

const CardFieldset = styled.fieldset`
  position: relative;
  padding: 0;
  margin: 0;
  border: 0;

  & + & {
    margin-top: 24px;
  }

  &:nth-last-of-type(2) {
    margin-top: 32px;
  }

  &:last-of-type {
    text-align: center;
    margin-top: 24px;
  }
`;

const FormWrapper = styled.form`
  position: relative;
  padding: 0;
  margin: 0;
  border: 0;

  & + & {
    margin-top: 24px;
  }

  &:nth-last-of-type(2) {
    margin-top: 32px;
  }

  &:last-of-type {
    text-align: center;
  }
`;
const CardButton = styled.input<{ isSelected?: boolean }>`
  width: 100%;
  padding: 12px 0;
  border: 1px solid ${__COLORS.PRIMARY};
  border-radius: 5px;
  background-color: ${({ isSelected }) => (isSelected ? `${__COLORS.PRIMARY}` : `${__COLORS.WHITE_COLOR}`)};
  color: ${({ isSelected }) => (isSelected ? `${__COLORS.WHITE_COLOR}` : `${__COLORS.PRIMARY}`)};

  cursor: pointer;
  &: hover {
    background-color: ${__COLORS.PRIMARY};
    color: ${__COLORS.WHITE_COLOR};
  }
`;

const CardPrice = styled.h4`
  font-weight: 500;
  text-align: center;
  margin-bottom: 24px;
  margin-top: 24px;
  ${EXTRA_LARGE_DEVICES_MAX`
  font-size:22px !important;
   `}
  ${LAPTOP_DEVICES_MAX`
  font-size:24px !important;
  `}
`;
const Cardsub = styled.sub`
  font-size: 15px;
  font-weight: 400;
`;
const Cardsup = styled.sup``;

const CardList = styled.ul`
  line-height: 2.5em;
  font-size: 15px;
  font-weight: 400;
  color: gray;
  text-align: start;
  margin-top: 5px;
  list-style: none;
  li:before {
    content: '✔';
    margin-left: -1em;
    margin-right: 0.5em;
    color: ${__COLORS.PRIMARY};
  }
`;

type subsOptions = {
  [key: string]: any;
};

type Props = {
  location: {
    search: string;
  };
};

const PaymentDetails = (props: Props) => {
  const api = useApi();
  const user = useSelector((s: RootStateOrAny) => s.users.user);
  console.log('user', user);

  const [statusModal, toggleStatusModal] = useState('');
  const [subscriptionList, setSubscriptionList] = useState<subsOptions>({});
  const [isLoading, setIsLoading] = useState(true);

  const getSubList = async () => {
    try {
      const response = await api.billing.getSubscriptionList();
      setSubscriptionList(response.data);
      setIsLoading(false);
    } catch (e) {
      toast.error((e as Error).message);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getSubList();
    if (props.location && props.location.search) {
      const transactionStatus: string = props.location.search.replace('?status=', '');
      if (transactionStatus) {
        toggleStatusModal(transactionStatus);
      }
    }
  }, []);

  useEffect(() => {
    if (props.location && props.location.search) {
      const transactionStatus: string = props.location.search.replace('?status=', '');
      if (transactionStatus) {
        toggleStatusModal(transactionStatus);
      }
    }
  }, [props.location]);

  return (
    <div>
      {isLoading ? (
        <Loading />
      ) : (
        <Container>
          {Object.entries(subscriptionList).map(([name, value]) => {
            return (
              <Fragment key={value.id}>
                <CardWrapper
                  borderColor={`${
                    user?.subscriptionType.toLowerCase() === name ? `1px solid ${__COLORS.PRIMARY}` : ''
                  }`}
                >
                  <CardHeader>
                    <CardHeading>{name}</CardHeading>
                    <hr />
                  </CardHeader>
                  <CardPrice>
                    <Cardsup>
                      <img src="rps1.png" alt="" width="7%" />
                    </Cardsup>
                    {value.value}
                    <Cardsub>/month</Cardsub>
                  </CardPrice>
                  <FormWrapper
                    target="_blank"
                    id="paymentForm"
                    action={`${process.env.REACT_APP_API_ENDPOINT}/v1/payment/create-order`}
                    method="post"
                  >
                    <CardButton type="hidden" name="subscription_type" id="sub_type" value={name.toLowerCase()} />
                    <CardButton type="hidden" value={sessionStorage.getItem('token') || ''} name="token" />

                    <CardButton
                      type="submit"
                      value={`${user?.subscriptionType.toLowerCase() === name ? 'Selected' : 'Select'}`}
                      onClick={() => {
                        sessionStorage.setItem('Plan', name.toLowerCase());
                      }}
                      isSelected={user?.subscriptionType.toLowerCase() === name ? true : false}
                      disabled={
                        name.toLowerCase() === 'free' || user?.subscriptionType.toLowerCase() === name.toLowerCase()
                          ? true
                          : false
                      }
                    />
                  </FormWrapper>
                  <CardFieldset>
                    <CardList>
                      {value.features.map((val: string) => {
                        return <li>{val}</li>;
                      })}
                    </CardList>
                  </CardFieldset>
                </CardWrapper>
              </Fragment>
            );
          })}

          {statusModal !== '' && <PaymentStatusModal openModal={statusModal} toggleModal={toggleStatusModal} />}
        </Container>
      )}
    </div>
  );
};

export default PaymentDetails;
