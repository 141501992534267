import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { __COLORS } from '../../../../shared/Theme';
import AssetDetails from './AssetDetails';
import UserInfo from './UserInfo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { Divider } from '../../../../ui-library';
import { PageContent } from '../../../../shared/components/PageContent/PageContent';
import { useParams, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useApi } from '../../../../shared/api';
import { USER_MANAGEMENT } from '../../../../routes/Routes';
import Loading from '../../../Loading/Loading';

const TabHeader = styled(NavItem)`
  cursor: pointer;
`;

const TabHeaderText = styled(NavLink)`
  background-color: ${(props) => (props.isActive ? __COLORS.PRIMARY : __COLORS.GREY_BACKGROUND)} !important;
  color: ${(props) => (props.isActive ? __COLORS.WHITE_COLOR : __COLORS.PRIMARY)};
  border-bottom: ${(props) => (props.isActive ? '' : '1px solid #dee2e6')} !important;

  &:hover {
    color: ${(props) => (props.isActive ? __COLORS.WHITE_COLOR : __COLORS.PRIMARY)};
  }
`;

const SubPageHeading = styled.div`
  display: flex;
  margin-bottom: 20px;
  margin-left: 22px;
  margin-top: 20px;
  gap: 10px;
`;

const Heading = styled.h1`
  color: ${__COLORS.ICON_COLOR};
  text-align: center;
  font-family: poppins;
  font-size: 2rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  @media (min-width: 320px) and (max-width: 786px) {
    font-size: 1.563rem;
  }
`;

const UserDetails = () => {
  const api = useApi();
  const history = useHistory();
  const { userId }: any = useParams();

  const [isLoading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState('1');
  const [userDetails, setUserDetails] = useState<any>({});

  useEffect(() => {
    if (userId) {
      getUserDetails();
    }
  }, [userId]);

  const getUserDetails = async () => {
    try {
      const response = await api.admin.getUserInfo({ userId });
      if (response) setUserDetails(response);
      setLoading(false);
    } catch (e) {
      toast.error((e as Error).message);
      setLoading(false);
    }
  };

  const handleUser = async () => {
    try {
      await api.admin.blockUser({ isBlocked: !userDetails?.is_blocked, userId: userDetails?.id });

      getUserDetails();
      toast.success('User Updated Successfully!');
    } catch (e) {
      toast.error((e as Error).message);
    }
  };

  return (
    <>
      <SubPageHeading>
        <FontAwesomeIcon
          icon={faArrowLeft}
          style={{ cursor: 'pointer', marginTop: '15px' }}
          onClick={() => history.push(USER_MANAGEMENT)}
          size="lg"
        />
        <Heading>{`${userDetails.first_name || ''} ${userDetails.last_name || ''}`}</Heading>
        <Divider />
      </SubPageHeading>
      <PageContent>
        {isLoading ? (
          <Loading />
        ) : (
          <>
            <Nav tabs>
              <TabHeader>
                <TabHeaderText isActive={activeTab === '1'} onClick={() => setActiveTab('1')}>
                  USER INFO
                </TabHeaderText>
              </TabHeader>
              <TabHeader>
                <TabHeaderText onClick={() => setActiveTab('2')} isActive={activeTab === '2'}>
                  ASSET DETAILS
                </TabHeaderText>
              </TabHeader>
            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <UserInfo userDetails={userDetails} handleUser={handleUser} />
              </TabPane>
              <TabPane tabId="2">
                <AssetDetails nomineeData={userDetails?.Nominees || []} />
              </TabPane>
            </TabContent>
          </>
        )}
      </PageContent>
    </>
  );
};

export default UserDetails;
