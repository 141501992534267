import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { PageContent } from '../../../shared/components/PageContent/PageContent';
import { PageHeading } from '../../../shared/components/PageHeading/PageHeading';
import { __COLORS } from '../../../shared/Theme';
import { SPACING } from '../../../shared/Layout';
import { Button, Pagination } from '../../../ui-library';
import Loading from '../../Loading/Loading';
import { useApi } from '../../../shared/api';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import useDelay from '../../../shared/hooks/useDelay';

const TableWrapperDiv = styled.div`
  overflow-x: auto;
  max-height: 500px;
  overflow-y: auto;
`;
const TableRow = styled.tr`
  height: 6.625rem;
  border: 0.188rem solid #f5f5f7;
  @media (min-width: 320px) and (max-width: 1200px) {
    height: 5.625rem;
  }
`;

const InnerTableData = styled.div<{ showBorder: boolean }>`
  border-right: ${(props) => (props.showBorder ? '0.03125rem solid #303c4e' : 'none')};
  height: 4.26031rem;
  align-items: center;
  display: flex;
  justify-content: center;
  @media (min-width: 320px) and (max-width: 786px) {
    height: 3.26031rem;
  }
`;
const PortfolioTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  text-align: center;
  & td {
    background: ${__COLORS.WHITE_COLOR};
    padding: 0.625rem 0 0.625rem 0.625rem;
    border-bottom: 0.125rem solid;
    @media (min-width: 320px) and (max-width: 1200px) {
      padding: 0.313rem 0 0.313rem 0.313rem;
    }
  }
`;
const TableHeading = styled.th`
  width: 16%;
  border: 3px solid #f5f5f7;
  font-size: 1rem;
  font-family: Poppins;
  font-weight: 500;
  color: ${__COLORS.ICON_COLOR};
  background: ${__COLORS.WHITE_COLOR};
  height: 5.0625rem;
  flex-shrink: 0;
  border-radius: 0.9375rem 0.9375rem 0rem 0rem;
  white-space: nowrap;
  padding: 0 10px;
  @media (min-width: 320px) and (max-width: 1200px) {
    font-size: 0.938rem;
    height: 4.0625rem;
  }
`;

const Tdata = styled.h1`
  color: ${__COLORS.ICON_COLOR};
  text-align: center;
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 0 20px;
  @media (min-width: 320px) and (max-width: 1200px) {
    font-size: 0.875rem;
    padding: 0 10px;
  }
`;

const NoRecordsText = styled.div`
  text-align: center;
  margin: ${SPACING * 2}px 0;
  width: 100%;
  color: ${__COLORS.SECONDARY_DARK};
`;

const PortfolioHeaderRow = styled.tr`
  border: 0.125rem solid #614be2;
`;

const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  justify-content: flex-end;
`;

const InputWithIcon = styled.div`
  display: flex;
  width: 300px;
  align-items: center;
  border: none;
  padding: 0.5rem;
  background: ${__COLORS.WHITE_COLOR};
  border-radius: 0.3125rem !important;
`;

const InputSearch = styled.input`
  flex: 1;
  border: none;
  outline: none;
  background: ${__COLORS.WHITE_COLOR};
  width: 100%;
`;

const StatusText = styled.span<{ isActive: boolean }>`
  color: ${({ isActive }) => (isActive ? __COLORS.GREEN_COLOR : __COLORS.RED_COLOR)};
`;

export const UserManagement = () => {
  const api = useApi();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(true);
  const [userList, setUserList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(0);
  const [searchUser, setSearchUser] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const debouncedSearchUser = useDelay(searchValue, 700);

  useEffect(() => {
    getUserList();
  }, []);

  useEffect(() => {
    getUserList();
  }, [currentPage, searchUser]);

  useEffect(() => {
    if (debouncedSearchUser) {
      setSearchUser(debouncedSearchUser);
      setCurrentPage(1);
    } else {
      setSearchUser('');
      setCurrentPage(1);
    }
  }, [debouncedSearchUser]);

  const getUserList = async () => {
    try {
      const response = await api.admin.getAppUsers({ pageNo: currentPage, searchTerm: searchUser });

      if (response?.allUsers) setUserList(response.allUsers);

      if (response?.totalUsers) setCount(response.totalUsers);
      setIsLoading(false);
    } catch (e) {
      toast.error((e as Error).message);
      setIsLoading(false);
    }
  };

  const setUserDetails = (id: number) => {
    history.push(`/admin/user-management/${id}`);
  };

  const getStatusValue = (value: string) => {
    const status = `${value.charAt(0).toUpperCase()}${value.slice(1)}`;
    return status;
  };

  return (
    <>
      <PageHeading header="User Management" />
      <PageContent>
        <FilterContainer>
          <InputWithIcon>
            <FontAwesomeIcon icon={faMagnifyingGlass} style={{ marginRight: '0.5rem' }} size="sm" />
            <InputSearch
              type="text"
              id="searchText"
              name="searchText"
              placeholder="Search User..."
              onChange={(e) => setSearchValue(e.target.value)}
              value={searchValue}
              autoComplete="off"
            />
          </InputWithIcon>
        </FilterContainer>
        <TableWrapperDiv>
          <PortfolioTable>
            <PortfolioHeaderRow>
              <TableHeading>USER NAME</TableHeading>
              <TableHeading> EMAIL</TableHeading>
              <TableHeading>NO OF ASSETS</TableHeading>
              <TableHeading>STATUS</TableHeading>
              <TableHeading>ACTION</TableHeading>
            </PortfolioHeaderRow>

            {userList?.length
              ? userList?.map((item: any, idx: number) => {
                  return (
                    <TableRow key={idx}>
                      <td>
                        <InnerTableData showBorder>
                          <Tdata>{`${item.first_name} ${item.last_name}`}</Tdata>
                        </InnerTableData>
                      </td>
                      <td>
                        <InnerTableData showBorder>
                          <Tdata>{item.email}</Tdata>
                        </InnerTableData>
                      </td>
                      <td>
                        <InnerTableData showBorder>
                          <Tdata>{item.Assets ? item.Assets.length : 0}</Tdata>
                        </InnerTableData>
                      </td>
                      <td>
                        <InnerTableData showBorder>
                          <Tdata>
                            {item.status === 'active' ? (
                              <StatusText isActive={true}>{getStatusValue(item.status || '')}</StatusText>
                            ) : (
                              <StatusText isActive={false}>{getStatusValue(item.status || '')}</StatusText>
                            )}
                          </Tdata>
                        </InnerTableData>
                      </td>

                      <td>
                        <InnerTableData showBorder={false}>
                          <Tdata>
                            <Button isPrimary onClick={() => setUserDetails(item.id)}>
                              Details
                            </Button>
                          </Tdata>
                        </InnerTableData>
                      </td>
                    </TableRow>
                  );
                })
              : null}
          </PortfolioTable>
        </TableWrapperDiv>
        {userList?.length ? <Pagination totalCount={count} onChangeHandler={setCurrentPage} /> : null}
        <>
          {isLoading && !userList?.length ? (
            <Loading />
          ) : !userList?.length ? (
            <NoRecordsText>No records found</NoRecordsText>
          ) : null}
        </>
      </PageContent>
    </>
  );
};
