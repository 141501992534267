import styled from 'styled-components';
import { useSelector, RootStateOrAny } from 'react-redux';
import { Button } from '../../ui-library';
import { Input } from 'reactstrap';
import { useState, useEffect } from 'react';
import { useApi } from '../../shared/api';
import { toast } from 'react-toastify';
import VerificationModal from '../../shared/components/Modal/VerificationModal';
import { __COLORS } from '../../shared/Theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPen } from '@fortawesome/free-solid-svg-icons';
import VectorVerify from '../../assets/images/Vectorverify.png';

const RadioButton = styled(Input)`
  width: 20px;
  height: 20px;
  margin-right: 10px;
  margin-top: 0px;
  cursor: pointer;

  &:checked {
    background-color: #2f5173;
    border-color: #2f5173;
    // opacity: 1;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1.37rem 1.44rem;
  border-bottom: 0.0625rem solid #eeebeb;
`;

const SectionHeading = styled.h1`
  color: ${__COLORS.ICON_COLOR};
  text-align: center;
  font-family: Poppins;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  @media (min-width: 320px) and (max-width: 500px) {
    font-size: 1rem;
  }
`;

const EditIconWrapper = styled.span`
  color: ${__COLORS.ICON_COLOR};
  cursor: pointer;
  display: flex;
`;

const ContentWrapper = styled.div`
  padding: 1.44rem;
`;

const FormField = styled.div`
  padding: '0 0.88rem 0 0.88rem';
  display: flex;
  @media (min-width: 320px) and (max-width: 500px) {
    flex-wrap: wrap;
  }
`;
const FieldLabel = styled.label`
  width: 8rem;
  padding: 5px;
  display: flex;
  align-items: center;
`;

const Seperator = styled.hr`
  margin: 1.5rem 0;
  color: inherit;
  border: 0;
  border-top: var(--bs-border-width) solid;
  opacity: 0.15;
  @media (min-width: 320px) and (max-width: 786px) {
    margin: 1rem 0;
  }
`;

const ValueWrapper = styled.div`
  margin-left: 5px;
  color: ${__COLORS.VALUE_COLOR};
`;

interface DataType {
  [key: string]: string;
}

interface PreferenceType {
  preferenceData: DataType;
}

const CommunicationModeSection = ({ preferenceData }: PreferenceType) => {
  const api = useApi();
  const user = useSelector((s: RootStateOrAny) => s.users.user);

  const [mode, setMode] = useState('email');
  const [openVerificationModal, setOpenVerificationModal] = useState('');
  const [isEditing, setIsEditing] = useState(false);

  const handleRadioButton = (calledFrom: string) => {
    setMode(calledFrom);
    // setCommunicationPreference(calledFrom);
  };

  useEffect(() => {
    if (preferenceData.mode) {
      setMode(preferenceData.mode);
    }
  }, [Object.keys(preferenceData).length == 0]);

  const setCommunicationPreference = async () => {
    try {
      await api.users.updatePreference({ mode });
      await api.users.getPreference();

      toast.success('Updated successfully.');
      setIsEditing(false);
    } catch (e) {
      toast.error((e as Error).message);
    }
  };

  const handleVerify = (calledFrom: string) => {
    setOpenVerificationModal(calledFrom);
  };

  const resetForm = () => {
    setIsEditing(false);
    if (preferenceData.mode) {
      setMode(preferenceData.mode);
    }
  };

  return (
    <>
      <HeaderContainer>
        <SectionHeading>Communication Mode</SectionHeading>
        <EditIconWrapper>
          {isEditing ? (
            <>
              <Button onClick={resetForm} isSecondary style={{ marginRight: '10px' }}>
                Close
              </Button>
              <Button onClick={setCommunicationPreference} isPrimary>
                Save
              </Button>
            </>
          ) : (
            <FontAwesomeIcon icon={faUserPen} size="lg" onClick={() => setIsEditing(true)} />
          )}
        </EditIconWrapper>
      </HeaderContainer>
      <ContentWrapper>
        <FormField>
          <FieldLabel>
            <RadioButton
              type="radio"
              name="mode"
              value="EMAIL"
              checked={mode == 'EMAIL'}
              onClick={() => handleRadioButton('EMAIL')}
              disabled={!user?.is_email_verified || !isEditing}
            />
            Email
          </FieldLabel>
          <ValueWrapper>
            {user && user.is_email_verified ? (
              <img src={VectorVerify} alt="" style={{ marginTop: '5px' }} />
            ) : (
              <Button onClick={() => handleVerify('EMAIL_VERIFICATION')} isPrimary>
                Verify
              </Button>
            )}
          </ValueWrapper>
        </FormField>
        <Seperator />
        <FormField>
          <FieldLabel>
            <RadioButton
              type="radio"
              name="mode"
              value="IVR"
              checked={mode == 'IVR'}
              onClick={() => handleRadioButton('IVR')}
              disabled={!user?.is_phone_verified || !isEditing}
            />
            IVR
          </FieldLabel>
          <ValueWrapper>
            {user && user.is_phone_verified ? (
              <img src={VectorVerify} alt="" style={{ marginTop: '5px' }} />
            ) : (
              <Button onClick={() => handleVerify('PHONE_VERIFICATION')}>Verify</Button>
            )}
          </ValueWrapper>
        </FormField>
      </ContentWrapper>

      {openVerificationModal !== '' && (
        <VerificationModal
          openVerificationModal={openVerificationModal}
          setOpenVerificationModal={setOpenVerificationModal}
        />
      )}
    </>
  );
};

export default CommunicationModeSection;
