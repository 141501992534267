import { createSlice } from '@reduxjs/toolkit';

export const assetsSlice = createSlice({
  name: 'assets',
  initialState: {
    assets: [],
    count: 0,
  },
  reducers: {
    setAssets: (state, action) => {
      state.assets = action.payload.rows;
      state.count = action.payload.count;
    },
  },
});

export const { setAssets } = assetsSlice.actions;

export default assetsSlice.reducer;
