import React from 'react';
import styled from 'styled-components';
import { __COLORS } from '../Theme';
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import { EXTRA_SMALL_DEVICES, SMALL_DEVICES } from '../helpers/Mobile';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faCircleInfo, faCircleMinus, faEllipsisVertical, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';

const BellContainer = styled.div`
  position: relative;
  width: 22px;
  height: 23px;
  cursor: pointer;
`;

const NotificationNumber = styled.span`
  position: absolute;
  top: 0%;
  left: 60%;
  border-radius: 50%;
  width: 18px;
  height: 17px;
  background-color: ${__COLORS.RED_COLOR};
  color: ${__COLORS.WHITE_COLOR};
  text-align: center;
  font-size: 11px;
`;

const NotificationItem = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  cursor: pointer;
  width: 80%;
  font-size: 14px;
`;

const ActionItem = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  font-size: 14px;
  padding: 10px;
  color: ${__COLORS.ICON_COLOR};
  border-bottom: 1px solid ${__COLORS.SECONDARY};
`;

const AlertText = styled.div`
  text-align: center;
  padding: 10px;
`;
const FontIconNotification = styled.div`
  display: flex;
  transform: rotate(45deg);
`;

const DropdownMenuWrapper = styled(DropdownMenu)`
  width: 300px;
  overflow-y: auto;
  padding: 0px;
  min-height: 83px;
  margin-top: 10px;
  border-left: 7px solid ${__COLORS.RED_COLOR};
  ${SMALL_DEVICES`
    width: 300px;
  `}
  ${EXTRA_SMALL_DEVICES`
    width: 300px;
  `}
`;

const NotificationWrapper = styled.div<{ seenStatus: boolean }>`
  padding: 10px 15px;
  background-color: ${({ seenStatus }) => `${seenStatus ? `${__COLORS.INPUT_GREY}` : ''}`};
  color: ${__COLORS.ICON_COLOR};
  border-bottom: 1px solid #eae5e5;
`;

const ContentWrapper = styled.div`
  display: flex;
`;

const DateWrapper = styled.div`
  font-size: 10px;
  text-align: end;
  margin-top: 10px;
  font-weight: 500;
  color: #918989;
`;

const DropdownMenuAction = styled(DropdownMenu)<{ listLength?: string }>`
  // height: ${({ listLength }) => (listLength === '2' ? '107px' : '59px')};
  padding: 0;
`;

const VerifyText = styled.div`
  color: ${__COLORS.BLUE_COLOR};
  font-size: 12px;
`;

const ActionPopup = styled.div`
  width: 20%;
  text-align: end;
`;

const NotificationPanel = (Props: any) => {
  const {
    notificationList,
    toggleNotification,
    popoverOpen,
    handleNotificationClick,
    notificationOpen,
    togglePopover,
    handleActionClick,
    notificationCount,
    calledFrom = 'TOPBAR',
  } = Props;

  const getNotificationText = (text: string) => {
    const splittedValue = text.split(',');
    return (
      <div>
        <div>{`${splittedValue[0]}.`}</div>
        {splittedValue[1] ? <VerifyText>{splittedValue[1]}</VerifyText> : null}
      </div>
    );
  };

  return (
    <>
      <Dropdown isOpen={notificationOpen} toggle={toggleNotification} direction="down">
        <DropdownToggle tag="span" data-toggle="dropdown">
          <BellContainer>
            <FontAwesomeIcon
              icon={faBell}
              color={`${calledFrom === 'SIDEBAR' ? __COLORS.ICON_COLOR : __COLORS.WHITE_COLOR}`}
              size="lg"
            />
            {notificationList.length !== 0 && notificationCount !== 0 && (
              <NotificationNumber>{notificationCount}</NotificationNumber>
            )}
          </BellContainer>
        </DropdownToggle>
        <DropdownMenuWrapper positionFixed={true}>
          {notificationList.length !== 0 ? (
            notificationList.map((item: any, index: number) => (
              <React.Fragment key={index}>
                <NotificationWrapper key={index} seenStatus={item.seen_status}>
                  <ContentWrapper>
                    <NotificationItem onClick={(e) => handleNotificationClick(item, e)}>
                      <FontIconNotification>
                        <FontAwesomeIcon icon={faCircleMinus} color={__COLORS.RED_COLOR} size="lg" />
                      </FontIconNotification>
                      {getNotificationText(item.text)}
                    </NotificationItem>

                    <ActionPopup>
                      <Dropdown isOpen={popoverOpen === item.id} toggle={() => togglePopover(item.id)} direction="down">
                        <DropdownToggle tag="span" data-toggle="dropdown">
                          <FontAwesomeIcon
                            icon={faEllipsisVertical}
                            style={{ cursor: 'pointer', color: `${__COLORS.ICON_COLOR}` }}
                          />
                        </DropdownToggle>
                        <DropdownMenuAction positionFixed={true} listLength={!item.seen_status ? '2' : '1'}>
                          {!item.seen_status ? (
                            <ActionItem onClick={(e) => handleActionClick(item, 'seen', e)}>
                              <FontAwesomeIcon
                                icon={faCircleInfo}
                                style={{ cursor: 'pointer', color: `${__COLORS.PRIMARY_DARK}` }}
                              />
                              Mark as read
                            </ActionItem>
                          ) : null}
                          <ActionItem onClick={(e) => handleActionClick(item, 'delete', e)}>
                            <FontAwesomeIcon
                              icon={faTrashCan}
                              style={{ cursor: 'pointer', color: `${__COLORS.PRIMARY_DARK}` }}
                            />
                            Delete
                          </ActionItem>
                        </DropdownMenuAction>
                      </Dropdown>
                    </ActionPopup>
                  </ContentWrapper>
                  <DateWrapper>{item.createdAt ? moment(item.createdAt).format('DD-MM-YY, hh:mm A') : ''}</DateWrapper>
                </NotificationWrapper>
                {/* {index !== notificationList.length - 1 && <Separator />} */}
              </React.Fragment>
            ))
          ) : (
            <AlertText>0 Notifications</AlertText>
          )}
        </DropdownMenuWrapper>
      </Dropdown>
    </>
  );
};

export default NotificationPanel;
