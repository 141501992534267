import { useState, useEffect, ChangeEvent } from 'react';
import { setLoginState } from '../../redux/app/appSlice';
import { useDispatch } from 'react-redux';
import { useApi } from '../../shared/api';
import { LoginStates } from '../../shared/types';
import styled from 'styled-components';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
import WellbeingOTPModal from './WellBeingOTPModal';
import Vector from '../../assets/images/Vector.png';
import BgImg from '../../assets/images/mainbackground.png';
import RightBack from '../../assets/images/rightback.png';
import InputWrapper from '../../ui-library/Input/InputWrapper';
import { ButtonWrapper } from './components/ButtonWrapper';
import { __COLORS } from '../../shared/Theme';
import { USER_MANAGEMENT, PORTFOLIO, FORGOT_PASSWORD, ADMIN_FORGOT_PASSWORD } from '../../routes/Routes';
import { setUser } from '../../redux/users/userSlice';
import { setNotification } from '../../redux/notification/notificationSlice';

const ErrorMessage = styled.div`
  color: red;
`;

const MainDiv = styled.div`
  background: url(${BgImg});
  background-position: center;
  background-size: cover;
  padding: 6.55rem 0;
  height: 100vh;
  display: flex;
  align-items: center;
  @media (max-width: 786px) {
    background-position: left;
    background-size: inherit;
  }
`;
const ContentSection = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  @media (min-width: 320px) and (max-width: 786px) {
    height: 100vh;
  }
`;
const LeftPart = styled.div`
  width: 34%;
  text-align: center;
  background: ${__COLORS.WHITE_COLOR};
  align-items: center;
  display: flex;
  padding: 10rem 0;
  @media (min-width: 786px) and (max-width: 1024px) {
    width: 36%;
    padding: 1.875rem 0;
  }
  @media (min-width: 320px) and (max-width: 786px) {
    width: 100%;
    background: transparent;
    height: 100vh;
  }
`;
const LeftContentDiv = styled.div`
  text-align: center;
  margin: 0 auto;
`;

const RightPart = styled.div`
  background: url(${RightBack});
  background-position: center;
  background-size: cover;
  width: 35%;
  flex-shrink: 0;
  text-align: center;
  align-items: center;
  display: flex;
  @media (min-width: 786px) and (max-width: 1024px) {
    width: 40%;
  }
  @media (min-width: 320px) and (max-width: 786px) {
    display: none;
  }
`;

const InsurewillHeading = styled.h1`
  color: #174966;
  font-family: Poppins;
  font-size: 3.5625rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  @media (min-width: 786px) and (max-width: 1024px) {
    font-size: 2.5625rem;
  }
  @media (min-width: 320px) and (max-width: 786px) {
    color: #fff;
  }
`;
const SigninText = styled.h3`
  color: ${__COLORS.ICON_COLOR};
  font-family: Poppins;
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  @media (min-width: 320px) and (max-width: 786px) {
    color: ${__COLORS.WHITE_COLOR};
  }
`;
const FormDiv = styled.div`
  display: grid;
  gap: 12px;
  width: 300px;
  margin: 2.6rem auto;
`;
const AccText = styled.p`
  color: #000;
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const SignInLink = styled.a`
  color: #1a5172;
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-wight: 600;
  line-height: normal;
  cursor: pointer;
  margin-top: 5px;
  @media (min-width: 320px) and (max-width: 786px) {
    color: ${__COLORS.WHITE_COLOR};
  }
`;
const RightmainHeading = styled.h1`
  color: #174966;
  font-family: Poppins;
  font-size: 3.125rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  @media (min-width: 786px) and (max-width: 1024px) {
    font-size: 2.125rem;
  }
`;
const RightParagraph = styled.p`
  color: ${__COLORS.ICON_COLOR};
  text-align: center;
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 0 5rem;
  @media (min-width: 786px) and (max-width: 1024px) {
    padding: 0 2rem;
  }
`;

type Props = {
  calledFrom?: string;
};

const Login = ({ calledFrom = 'USER' }: Props) => {
  const api = useApi();
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  const [ipAddress, setIpAdress] = useState('');
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const [errorMessage, setErrorMessage] = useState('');
  const [openOtpModal, setOpenOtpModal] = useState(false);

  const [message, setMessage] = useState({
    email: '',
    password: '',
  });

  useEffect(() => {
    getIpAddress();
  }, []);

  const getIpAddress = async () => {
    const res = await axios.get('https://geolocation-db.com/json/');
    if (res && res.data) {
      setIpAdress(res.data.IPv4 ? res.data.IPv4 : '');
      setLatitude(res.data.latitude ? res.data.latitude : '');
      setLongitude(res.data.longitude ? res.data.longitude : '');
    }
  };
  const onSubmit = async () => {
    setErrorMessage('');

    try {
      if (validateForm()) {
        setIsLoading(true);

        const msgObj = {
          email: '',
          password: '',
        };
        setMessage(msgObj);

        let response: any = {};

        if (location.pathname.includes('/admin')) {
          response = await api.admin.adminLogin(email, password, ipAddress, {
            lat: latitude.toString(),
            long: longitude.toString(),
          });
        } else {
          response = await api.auth.loginWithEmail(email, password, ipAddress, {
            lat: latitude.toString(),
            long: longitude.toString(),
          });
        }

        sessionStorage.setItem('token', response.token);

        const encodedPayload = response.token.split('.')[1];

        const decodedPayload = atob(encodedPayload);
        const user = JSON.parse(decodedPayload);

        const userResponse = await api.users.getUser();
        dispatch(setUser(userResponse?.data));
        if (userResponse?.data?.role === 'user') {
          const notificationResponse = await api.notifications.notificationList();
          notificationResponse && dispatch(setNotification(notificationResponse));
        }

        if (user.role === 'user') {
          history.replace(PORTFOLIO);
        } else {
          history.replace(USER_MANAGEMENT);
        }

        toast.success('You have successfully logged in!');
      }
    } catch (e) {
      if ((e as Error)?.message == 'Not ok by user please check mobile for otp') {
        toggleOtpModal();
      } else {
        setErrorMessage((e as Error).message);
      }
      dispatch(setLoginState(LoginStates.LOGGED_OUT));
    }
    setIsLoading(false);
  };

  const validateForm = () => {
    const tempMessage = { ...message };
    let result = true;

    if (!email.trim()?.length) {
      tempMessage.email = 'Please enter Email address!';
      result = false;
    }
    if (!password.trim()?.length) {
      tempMessage.password = 'Please enter Password!';
      result = false;
    }
    setMessage({ ...tempMessage });
    return result;
  };

  const handleEyeClick = () => {
    setShowPassword(!showPassword);
  };

  const toggleOtpModal = () => {
    setOpenOtpModal(!openOtpModal);
  };

  return (
    <MainDiv>
      <ContentSection>
        <LeftPart>
          <LeftContentDiv>
            <InsurewillHeading>INSUREWILL</InsurewillHeading>
            <SigninText>Sign In</SigninText>
            <FormDiv>
              <InputWrapper
                type="email"
                id="Email"
                name="Email"
                placeholder="Email"
                onChange={(e: ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
                value={email}
                errorMessage={message && message.email}
              />

              <InputWrapper
                id="password"
                name="password"
                placeholder="Password"
                type={showPassword ? 'text' : 'password'}
                onChange={(e: ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
                value={password}
                calledFrom={'PASSWORD'}
                handleEyeClick={handleEyeClick}
                showPassword={showPassword}
                errorMessage={message && message.password}
              />
              {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
              <ButtonWrapper type="submit" isLoading={isLoading} onClick={onSubmit}>
                Login
              </ButtonWrapper>
            </FormDiv>
            {calledFrom === 'USER' ? (
              <AccText>
                {`Don’t have an account? `}
                <SignInLink onClick={() => history.push('/signup')}>{`Sign up`}</SignInLink>
              </AccText>
            ) : null}

            <SignInLink
              onClick={() => history.push(calledFrom === 'USER' ? FORGOT_PASSWORD : ADMIN_FORGOT_PASSWORD)}
            >{`Forgot Password?`}</SignInLink>
          </LeftContentDiv>
        </LeftPart>
        <RightPart>
          <div>
            <img src={Vector} alt="" width={65} height={84} />
            <RightmainHeading>DID YOU KNOW</RightmainHeading>
            <RightParagraph>
              90% of Indian citizen don’t even know about the term insurance coverage from their credit cards!
            </RightParagraph>
          </div>
        </RightPart>
        {openOtpModal && <WellbeingOTPModal openModal={openOtpModal} toggleModal={toggleOtpModal} />}
      </ContentSection>
    </MainDiv>
  );
};

export default Login;
