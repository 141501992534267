import { createGlobalStyle } from 'styled-components';

const MainTheme = {
  fontFamily: 'Poppins, sans-serif',
  googleUrl:
    'https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap',
};

export const __COLORS = {
  PRIMARY: '#2F5173',
  PRIMARY_DARK: '#092550',
  SECONDARY: '#F4F1F1',
  SECONDARY_DARK: '#c5bbbb',
  GREY_BACKGROUND: '#f1f4fc',
  INPUT_GREY: '#F6F5F5',
  ICON_COLOR: '#303C4E',
  VALUE_COLOR: '#2F5173',
  WHITE_COLOR: '#FFFEFE',
  RED_COLOR: ' #F00',
  BLUE_COLOR: '#3290A7',
  GREEN_COLOR: '#07bc0c',
};

export const GlobalStyle = createGlobalStyle`
* {
    box-sizing: border-box;
    // line-height: 1.5em;
    outline: none;
    // ::-webkit-scrollbar {
    // 	display: none;
  	// }
  }

  @import url(${MainTheme.googleUrl});

  html {
    font-family: ${MainTheme.fontFamily};
  }

 
  body {
    padding: 0;
    margin: 0;
    border: 0;
    // line-height: 1;
    font-weight: inherit;
    font-style: inherit;
    font-size: 100%;
    font-family: inherit;
    outline: none;
    background: #fff;
    overflow-x: hidden;
  }
 body.modal-open {
    overflow: hidden;
}
  h1, h2, h3, h4, h5, h6, p {
    color: #000;
    font-weight: 100;
    line-height: 1.4;
     font-family: inherit;
     margin: 0;
  }

  h1 {
    font-size: 22px;
   }
   
   p {
     word-break: break-word;
     line-height: 1.4;
      font-family: inherit;
   }

   a {
     text-decoration: none;
   }

   input, textarea {
    box-shadow: none;
     font-family: inherit;
      -webkit-appearance: none;
      border-width: initial;
      border-style: none;
      border-color: initial;
      border-image: initial;
      border-radius: 5px;
      margin: 0;
      padding: 0;
    }
`;
