import styled from 'styled-components';
import { SPACING } from '../../shared/Layout';
import { __COLORS } from '../../shared/Theme';

const InputStyled = styled.textarea`
    padding: 8px 15px;
    width: 19rem;
    flex-shrink: 0,
    border-radius: 0.3125rem;
    background: ${__COLORS.INPUT_GREY};
    MozAppearance: textfield;
    appearance: textfield;
    @media (min-width: 320px) and (max-width: 786px) {
      border-radius: 5px;
      &::placeholder {
    color: #303C4E;
  }
  @media (min-width: 320px) and (max-width: 500px) {
      width: 100%;
    }
`;
const InputContainer = styled.div`
  border-radius: ${SPACING / 2}px;
  border: 1px solid #e0e0e0;
  border: none;
  width: 19rem;
  @media (min-width: 320px) and (max-width: 500px) {
    width: 100%;
  }
`;
const ErrorMessage = styled.div`
  color: red;
  font-size: 14px;
  text-align: left;
  margin: 0 auto;
`;

export const TextAreaInput = (props: any) => {
  return (
    <>
      <InputContainer style={props.style}>
        <InputStyled {...props} />

        {props.errorMessage && <ErrorMessage>{props.errorMessage}</ErrorMessage>}
      </InputContainer>
    </>
  );
};
export default TextAreaInput;
