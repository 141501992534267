import { useState, useEffect, ChangeEvent, FormEvent } from 'react';
import { useApi } from '../../shared/api';
import styled from 'styled-components';
import { __COLORS } from '../../shared/Theme';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import BgImg from '../../assets/images/mainbackground.png';
import InputWrapper from '../../ui-library/Input/InputWrapper';
import InsurewillInfo from '../../shared/components/InsurewillInfo/InsurewillInfo';
import { ButtonWrapper } from './components/ButtonWrapper';
import { ADMIN_LOGIN, LOGIN } from '../../routes/Routes';

const ErrorMessage = styled.div`
  color: ${__COLORS.RED_COLOR};
`;

const MainDiv = styled.div`
  background: url(${BgImg});
  background-position: center;
  background-size: cover;
  padding: 6.55rem 0;
  height: 100vh;
  display: flex;
  align-items: center;
  @media (min-width: 320px) and (max-width: 786px) {
    background-position: left;
  }
`;
const ContentSection = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  @media (min-width: 320px) and (max-width: 786px) {
    height: 100vh;
  }
`;
const LeftPart = styled.div`
  width: 34%;
  text-align: center;
  background: ${__COLORS.WHITE_COLOR};
  align-items: center;
  display: flex;
  padding: 10rem 0;
  @media (min-width: 786px) and (max-width: 1024px) {
    width: 36%;
    padding: 1.875rem 0;
  }
  @media (min-width: 320px) and (max-width: 786px) {
    width: 100%;
    background: transparent;
    height: 100vh;
  }
`;
const LeftContentDiv = styled.div`
  text-align: center;
  margin: 0 auto;
`;

const InsurewillHeading = styled.h1`
  color: #174966;
  font-family: Poppins;
  font-size: 3.5625rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  @media (min-width: 786px) and (max-width: 1024px) {
    font-size: 2.5625rem;
  }
  @media (min-width: 320px) and (max-width: 786px) {
    color: ${__COLORS.WHITE_COLOR};
  }
`;
const SigninText = styled.h3`
  color: ${__COLORS.ICON_COLOR};
  font-family: Poppins;
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  @media (min-width: 320px) and (max-width: 786px) {
    color: ${__COLORS.WHITE_COLOR};
  }
`;
const FormDiv = styled.div`
  margin-top: 2.6rem;
  display: grid;
  gap: 12px;
`;

type Props = {
  location: {
    search: string;
  };
};

const ResetPassword = (props: Props) => {
  const api = useApi();
  const history = useHistory();
  const location = useLocation();

  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [submitting, setSubmitting] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [token, setToken] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const [message, setMessage] = useState({
    newPassword: '',
    confirmPassword: '',
  });

  useEffect(() => {
    if (props.location && props.location.search) {
      const tokenString: string = props.location.search.replace('?token=', '');
      setToken(tokenString);
    }
  }, []);

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setErrorMessage('');
    setMessage({
      newPassword: '',
      confirmPassword: '',
    });

    try {
      if (validateForm()) {
        setIsLoading(true);
        setSubmitting(true);
        await api.users.resetPassword({ new_password: newPassword }, token);
        toast.success('Password has been reset successfully.');
        history.push(location.pathname.includes('admin') ? ADMIN_LOGIN : LOGIN);
      }
    } catch (e) {
      setErrorMessage((e as Error).message);
    }

    setIsLoading(false);
  };

  const validateForm = () => {
    const tempMessage = { ...message };
    let result = true;
    if (!/(^.*(?=.*[!@#$%^&*_0-9])(?=.*[A-Z])(?=.*[a-z]).*$)/.test(newPassword)) {
      tempMessage.newPassword =
        'Must be at least 8 characters long, contain at least one number and have a mixture of uppercase and lowercase letters!';
      result = false;
    }

    if (newPassword !== confirmPassword) {
      tempMessage.confirmPassword = 'Password and Confirm Password should be same!';
      result = false;
    }
    if (!newPassword.trim()?.length) {
      tempMessage.newPassword = 'Please enter New Password!';
      result = false;
    }
    if (!confirmPassword.trim()?.length) {
      tempMessage.confirmPassword = 'Please enter Confirm Password!';
      result = false;
    }
    setMessage({ ...tempMessage });
    return result;
  };

  return (
    <MainDiv>
      <ContentSection>
        <LeftPart>
          <LeftContentDiv>
            <InsurewillHeading>INSUREWILL</InsurewillHeading>
            <SigninText>Reset Password</SigninText>
            <FormDiv>
              <InputWrapper
                id="newPassword"
                placeholder="New Password"
                value={newPassword}
                required
                type={showPassword ? 'text' : 'password'}
                onChange={(e: ChangeEvent<HTMLInputElement>) => setNewPassword(e.target.value)}
                calledFrom={'PASSWORD'}
                handleEyeClick={() => setShowPassword(!showPassword)}
                showPassword={showPassword}
                errorMessage={message && message?.newPassword}
              />

              <InputWrapper
                id="confirmPassword"
                placeholder="Confirm Password"
                type={showConfirmPassword ? 'text' : 'password'}
                onChange={(e: ChangeEvent<HTMLInputElement>) => setConfirmPassword(e.target.value)}
                value={confirmPassword}
                calledFrom={'PASSWORD'}
                handleEyeClick={() => setShowConfirmPassword(!showConfirmPassword)}
                showPassword={showConfirmPassword}
                errorMessage={message && message?.confirmPassword}
              />
              {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
              <ButtonWrapper type="submit" disabled={submitting} isLoading={isLoading} onClick={onSubmit}>
                Save
              </ButtonWrapper>
            </FormDiv>
          </LeftContentDiv>
        </LeftPart>
        <InsurewillInfo />
      </ContentSection>
    </MainDiv>
  );
};

export default ResetPassword;
