import { Button } from '../../../ui-library';
import styled from 'styled-components';
import { PageContent } from '../PageContent/PageContent';
import { useSelector, RootStateOrAny } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { PORTFOLIO, USER_MANAGEMENT } from '../../../routes/Routes';

const Container = styled.div`
  display: flex;
  justify-content: center; /* Horizontal alignment */
  align-items: center; /* Vertical alignment */
  height: 100vh; /* Set height of the container */
`;

const Content = styled.div`
  text-align: center;
`;

export const PageNotFound = () => {
  const history = useHistory();
  const user = useSelector((s: RootStateOrAny) => s.users.user);

  const handleClick = () => {
    if (user?.role === 'user') {
      history.replace(PORTFOLIO);
    } else {
      history.replace(USER_MANAGEMENT);
    }
  };
  return (
    <PageContent>
      <Container>
        <Content>
          <div>Oooops&nbsp;!</div>
          <div>
            We can not seem to find a page you
            <br /> are looking for.
          </div>
          <Button onClick={handleClick}>Back to Home</Button>
        </Content>
      </Container>
    </PageContent>
  );
};
