import styled from 'styled-components';
import Vector from '../../../assets/images/Vector.png';
import RightBack from '../../../assets/images/rightback.png';
import { __COLORS } from '../../Theme';

const RightPart = styled.div`
  background: url(${RightBack});
  background-position: center;
  background-size: cover;
  width: 35%;
  flex-shrink: 0;
  text-align: center;
  align-items: center;
  display: flex;
  @media (min-width: 786px) and (max-width: 1024px) {
    width: 40%;
  }
  @media (min-width: 320px) and (max-width: 786px) {
    display: none;
  }
`;
const RightmainHeading = styled.h1`
  color: #174966;
  font-family: Poppins;
  font-size: 3.125rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  @media (min-width: 786px) and (max-width: 1024px) {
    font-size: 2.125rem;
  }
`;
const RightParagraph = styled.p`
  color: ${__COLORS.ICON_COLOR};
  text-align: center;
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 0 5rem;
  @media (min-width: 786px) and (max-width: 1024px) {
    padding: 0 2rem;
  }
`;

const InsurewillInfo = () => {
  return (
    <>
      <RightPart>
        <div>
          <img src={Vector} alt="" width={65} height={84} />
          <RightmainHeading>DID YOU KNOW</RightmainHeading>
          <RightParagraph>
            90% of Indian citizen don’t even know about the term insurance coverage from their credit cards!
          </RightParagraph>
        </div>
      </RightPart>
    </>
  );
};

export default InsurewillInfo;
