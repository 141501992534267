import { post, makeRequest, put, remove } from './index';

export const assets = {
  add: (assetAddRequest: any) => post('/assets', assetAddRequest),
  update: (assetUpdateRequest: any) => put('/assets', assetUpdateRequest),
  remove: ({ id }: any) => remove(`/assets?asset_id=${id}`),
  getAll: (payload: any) => {
    return makeRequest(
      `/assets?pageNo=${payload.pageNo}&sortedTerm=${payload.sortedTerm}&isAscending=${payload.isAscending}&userId=${
        payload.userId || ''
      }`,
      {},
    );
  },
  getCardDetails: (value: string) => makeRequest(`/assets/card-details?card_number=${value}`, {}),
};
