import { ReactElement } from 'react';
import { useSelector, useDispatch, RootStateOrAny } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useApi } from '../shared/api';
import jwt from 'jsonwebtoken';
import { setNotification } from '../redux/notification/notificationSlice';
import { setUser } from '../redux/users/userSlice';
import { ADMIN_LOGIN, LOGIN, userRoutesList, adminRoutesList, USER_MANAGEMENT, PORTFOLIO } from './Routes';
import { toast } from 'react-toastify';

type Props = {
  children: ReactElement;
  module: string;
};

export const PrivateRoute = ({ children, module }: Props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const api = useApi();
  const user = useSelector((s: RootStateOrAny) => s.users.user);

  const setUserData = async () => {
    const tokenValue = sessionStorage.getItem('token');
    let response: any = {};
    try {
      response = await api.users.getUser();
      dispatch(setUser(response.data));

      if (response?.data?.role === 'user') {
        const notificationResponse = await api.notifications.notificationList();
        notificationResponse && dispatch(setNotification(notificationResponse));
      }
    } catch (e) {
      toast.error((e as Error).message);
    }

    /**********if user tries to access admin routes and vice versa *******/
    if (tokenValue) {
      const encodedPayload = tokenValue.split('.')[1];

      const decodedPayload = atob(encodedPayload);
      const user = JSON.parse(decodedPayload);
      if (
        module === 'USER' &&
        userRoutesList.some(
          (item: any) => location.pathname.includes(item.route) && item.isAccessible.includes(user.role),
        )
      ) {
        return children;
      } else if (
        module === 'ADMIN' &&
        adminRoutesList.some(
          (item: any) => location.pathname.includes(item.route) && item.isAccessible.includes(user.role),
        )
      ) {
        return children;
      } else {
        if (['admin', 'super_admin'].includes(user.role)) {
          history.replace(USER_MANAGEMENT);
        } else if (user.role === 'user') {
          history.replace(PORTFOLIO);
        }
      }
    }

    return children;
    // return null;
  };

  const redirectToLogin = () => {
    if (module === 'ADMIN') {
      history.replace(ADMIN_LOGIN);
    } else {
      history.replace(LOGIN);
    }

    return null;
  };

  if (!user) {
    // page reload logic
    const token = sessionStorage.getItem('token');
    if (token) {
      try {
        const queryToken: any = window.location.href.split('token=')[1];
        if (queryToken) {
          jwt.verify(queryToken, 'insureWill+123+', async (err: any, decoded: any) => {
            if (decoded.atomic) {
              return redirectToLogin();
            }
          });
        } else {
          // return children;

          setUserData();
        }
      } catch (e) {
        return redirectToLogin();
      }
    } else {
      return redirectToLogin();
    }
  }

  if (!sessionStorage.getItem('token')) {
    redirectToLogin();
  }

  return children;
};
