import { ChangeEvent, useState, useEffect } from 'react';
import styled from 'styled-components';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import { EXTRA_SMALL_DEVICES } from '../../shared/helpers/Mobile';
import { Button } from '../../ui-library';
import { IsInputNumber } from '../../shared/helpers/IsInputNumber';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import InputWrapper from '../../ui-library/Input/InputWrapper';
import { __COLORS } from '../../shared/Theme';
import { useApi } from '../../shared/api';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

const BodyContainer = styled.div`
  padding: 20px;
`;
const ErrorMessage = styled.div`
  color: red;
  height: 30px;
`;

const InputRow = styled.div`
  margin: 15px 0 5px 0;
`;

export const Label = styled.p`
  font-weight: 300;
  text-transform: uppercase;
  font-size: 14px;
  color: gray;
  width: 30%;
  ${EXTRA_SMALL_DEVICES`
  width: 50%;
  `}
`;

const PopupHeadingdiv = styled.div`
  height: 4.87rem;
  padding: 1.31rem;
  border-bottom: 0.0625rem solid #e3dbdb;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const PopupHeading = styled.h1`
  color: ${__COLORS.ICON_COLOR};
  font-family: Poppins;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

const FieldLabel = styled.label`
  padding: 5px;
  margin-bottom: 10px;
`;

const TimerWrapper = styled.div`
  text-align: center;
  color: ${__COLORS.RED_COLOR};
`;

const ButtonWrapper = styled.div`
  text-align: center;
`;

type Props = {
  openModal: boolean;
  toggleModal: (_args: string) => void;
  actionType: string;
  otpId: number | null;
};

const AdminOtpModal = ({ openModal, toggleModal, actionType, otpId }: Props) => {
  const api = useApi();
  const params: any = useParams();

  const [otp, setOtp] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [timer, setTimer] = useState(120);
  const [timerActive, setTimerActive] = useState(false);

  useEffect(() => {
    startTimer();
  }, []);

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;
    if (timerActive) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (interval !== null) {
      clearInterval(interval);
    }

    if (timer === 0) {
      setTimerActive(false);
    }

    return () => {
      if (interval !== null) {
        clearInterval(interval);
      }
    };
  }, [timerActive, timer]);

  const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
    if (IsInputNumber(e.target.value)) {
      setOtp(e.target.value);
    }
  };

  const startTimer = () => {
    setTimer(120); // Reset timer to 2 minutes
    setTimerActive(true);
  };

  const verifyOtp = async () => {
    setErrorMessage('');

    try {
      if (validateOTP()) {
        const userId = params?.userId || '';
        const response = await api.admin.verifyAssetOtp({
          otp,
          userId: +userId,
          action: actionType,
        });
        if (response) {
          toggleModal('OTP_VERIFIED');
          toast.success('Asset updated successfully!');
        }
      }
    } catch (e) {
      setErrorMessage((e as Error).message);
    }
  };

  const validateOTP = () => {
    setErrorMessage('');
    if (otp.replace(/\s/g, '').length < 4) {
      setErrorMessage('Please enter valid OTP!');
      return false;
    } else {
      return true;
    }
  };

  const resendOtp = async () => {
    try {
      await api.admin.resendOtp({
        id: otpId,
      });
      startTimer();

      toast.success('OTP has been sent successfully!');
    } catch (e) {
      toast.error((e as Error).message);
    }
  };

  return (
    <Modal isOpen={openModal} style={{ marginTop: '45px' }}>
      <PopupHeadingdiv>
        <PopupHeading>User Confirmation</PopupHeading>
        <FontAwesomeIcon icon={faXmark} onClick={() => toggleModal('CLOSE')} style={{ cursor: 'pointer' }} />
      </PopupHeadingdiv>

      <ModalBody>
        <BodyContainer>
          <InputRow>
            <FieldLabel>Please enter the OTP sent to the user's registered email address.</FieldLabel>
            <InputWrapper
              placeholder="Enter OTP"
              type="text"
              onChange={handleInput}
              value={otp}
              required
              maxLength="6"
              width={'100%'}
              style={{ height: '50px' }}
            />
          </InputRow>
          <ErrorMessage>{`${errorMessage}`}</ErrorMessage>
          {timerActive ? (
            <TimerWrapper>
              Resend OTP in {Math.floor(timer / 60)}:{timer % 60 < 10 ? `0${timer % 60}` : timer % 60}
            </TimerWrapper>
          ) : (
            <ButtonWrapper>
              <Button onClick={resendOtp} isPrimary>
                Resend OTP
              </Button>
            </ButtonWrapper>
          )}
        </BodyContainer>
      </ModalBody>
      <ModalFooter>
        <Button onClick={() => toggleModal('CLOSE')} isSecondary>
          Cancel
        </Button>
        <Button onClick={verifyOtp} isPrimary>
          Verify OTP
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AdminOtpModal;
