import { useState, useEffect, ChangeEvent } from 'react';
import styled from 'styled-components';
import { SelectWrapper, Button } from '../../ui-library';
import { useApi } from '../../shared/api';
import { useDispatch, useSelector, RootStateOrAny } from 'react-redux';
import { setUser } from '../../redux/users/userSlice';
import { toast } from 'react-toastify';
import { genders } from '../../shared/options';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPen } from '@fortawesome/free-solid-svg-icons';
import { __COLORS } from '../../shared/Theme';
import InputWrapper from '../../ui-library/Input/InputWrapper';

const SectionHeading = styled.h1`
  color: ${__COLORS.ICON_COLOR};
  text-align: center;
  font-family: Poppins;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  @media (min-width: 320px) and (max-width: 500px) {
    font-size: 1rem;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1.37rem 1.44rem;
  border-bottom: 0.0625rem solid #eeebeb;
`;

const ValueWrapper = styled.input`
  margin-left: 5px;
  color: ${__COLORS.VALUE_COLOR};
  width: 100%;
`;

const ContentWrapper = styled.div`
  padding: 1.44rem;
`;

const EditIconWrapper = styled.span`
  color: ${__COLORS.ICON_COLOR};
  cursor: pointer;
  display: flex;
`;

const FormField = styled.div`
  padding: '0 0.88rem 0 0.88rem';
  display: flex;
  @media (min-width: 320px) and (max-width: 500px) {
    flex-wrap: wrap;
  }
`;
const FieldLabel = styled.label`
  width: 8rem;
  padding: 5px;
  align-items: center;
`;
const Seperator = styled.hr`
  margin: 1.5rem 0;
  color: inherit;
  border: 0;
  border-top: var(--bs-border-width) solid;
  opacity: 0.15;
  @media (min-width: 320px) and (max-width: 786px) {
    margin: 1rem 0;
  }
`;

type GenderType = {
  label: string;
  value: string;
};

const BasicInfoSection = () => {
  const api = useApi();
  const dispatch = useDispatch();
  const user = useSelector((s: RootStateOrAny) => s.users.user);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [gender, setGender] = useState<GenderType>({ label: '', value: '' });
  const [message, setMessage] = useState({
    firstName: '',
    lastName: '',
  });

  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    if (user) {
      setFirstName(user?.first_name);
      setLastName(user?.last_name);
      setGender({ label: user?.gender ?? '', value: user?.gender ?? '' });
    }
  }, [user]);

  const updateInformation = async () => {
    setMessage({ firstName: '', lastName: '' });
    try {
      if (validateForm()) {
        await api.users.update({
          gender: gender.value ?? '',
          first_name: firstName,
          last_name: lastName,
        });

        const response = await api.users.getUser();
        dispatch(setUser(response.data));
        toast.success('Basic Info updated successfully.');
        setIsEditing(false);
      }
    } catch (e) {
      toast.error((e as Error).message);
    }
  };

  const validateForm = () => {
    const tempMessage = { firstName: '', lastName: '' };
    let result = true;

    if (!firstName.trim().length) {
      tempMessage.firstName = 'Please enter First Name!';
      result = false;
    }

    if (!lastName.trim().length) {
      tempMessage.firstName = 'Please enter Last Name!';
      result = false;
    }

    setMessage({ ...tempMessage });
    return result;
  };

  const resetForm = () => {
    if (user) {
      setFirstName(user?.first_name);
      setLastName(user?.last_name);
      setGender({ label: user?.gender, value: user?.gender });
    }
    setMessage({
      firstName: '',
      lastName: '',
    });
    setIsEditing(false);
  };

  return (
    <>
      <HeaderContainer>
        <SectionHeading>BASIC INFO</SectionHeading>
        <EditIconWrapper>
          {isEditing ? (
            <>
              <Button onClick={() => resetForm()} isSecondary style={{ marginRight: '10px' }}>
                Close
              </Button>
              <Button onClick={updateInformation} isPrimary>
                Save
              </Button>
            </>
          ) : (
            <FontAwesomeIcon icon={faUserPen} size="lg" onClick={() => setIsEditing(true)} />
          )}
        </EditIconWrapper>
      </HeaderContainer>
      <ContentWrapper>
        <FormField>
          <FieldLabel>First Name</FieldLabel>
          {isEditing ? (
            <InputWrapper
              value={firstName}
              onChange={(e: ChangeEvent<HTMLInputElement>) => setFirstName(e.target.value)}
              placeholder="Enter First Name"
              errorMessage={message?.firstName}
              style={{ marginBottom: '0' }}
            />
          ) : (
            <ValueWrapper readOnly value={firstName} />
          )}
        </FormField>
        <Seperator />
        <FormField>
          <FieldLabel>Last Name</FieldLabel>
          {isEditing ? (
            <InputWrapper
              value={lastName}
              onChange={(e: ChangeEvent<HTMLInputElement>) => setLastName(e.target.value)}
              placeholder="Enter Last Name"
              errorMessage={message?.lastName}
              style={{ marginBottom: '0' }}
            />
          ) : (
            <ValueWrapper readOnly value={lastName} />
          )}
        </FormField>
        <Seperator />
        <FormField>
          <FieldLabel>Gender</FieldLabel>
          {isEditing ? (
            <SelectWrapper options={genders} value={gender} onChange={setGender} placeholder="Select Gender" />
          ) : (
            <ValueWrapper readOnly value={gender?.label || '-'} />
          )}
        </FormField>
      </ContentWrapper>
    </>
  );
};

export default BasicInfoSection;
