export const relations = [
  { label: 'Sister', value: 'Sister' },
  { label: 'Brother', value: 'Brother' },
  { label: 'Uncle', value: 'Uncle' },
  { label: 'Aunt', value: 'Aunt' },
  { label: 'Father', value: 'Father' },
  { label: 'Mother', value: 'Mother' },
  { label: 'Grandfather', value: 'Grandfather' },
  { label: 'Husband', value: 'Husband' },
  { label: 'Wife', value: 'Wife' },
  { label: 'Other', value: 'Other' },
];
