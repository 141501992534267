import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import {
  ADMIN_LOGIN,
  ADMIN_USERS,
  TRANSACTION_LOGS,
  USER_MANAGEMENT,
  USER_DETAILS,
  LOGIN,
  SIGNUP,
  EMAIL_VERIFICATION,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  WELLBEING_CONFIRMATION,
  NOMINEE_WELLBEING_CONFIRMATION,
  BILLING,
  NOMINEES,
  PORTFOLIO,
  PREFERENCES,
  PROFILE_SETTINGS,
  SUPPORT,
  ADMIN_FORGOT_PASSWORD,
  ADMIN_RESET_PASSWORD,
} from './Routes';
import Login from '../views/Signup/Login';
import Signup from '../views/Signup/Signup';
import EmailVerification from '../views/Signup/EmailVerification';
import ForgotPassword from '../views/Signup/ForgotPassword';
import ResetPassword from '../views/Signup/ResetPassword';
import WellbeingConfirmation from '../views/Signup/WellbeingConfirmation';
import NomineeWellbeingConfirmation from '../views/Signup/NomineeWellbeingConfirmation';
import { PageNotFound } from '../shared/components/PageNotFound/PageNotFound';

import Portfolio from '../views/Portfolio/Portfolio';
import Nominees from '../views/Nominees/Nominees';
import Preferences from '../views/Preferences/Preferences';
import ProfileSettings from '../views/ProfileSettings/ProfileSettings';
import Billing from '../views/Billing/Billing';
import Support from '../views/Support/Support';

import { UserManagement } from '../views/AdminPanel/UserManagement/UserManagement';
import { TransactionLogs } from '../views/AdminPanel/TransactionLogs/TransactionLogs';
import { AdminUsers } from '../views/AdminPanel/AdminUsers/AdminUsers';
import DashboardShell from '../shared/components/DashboardShell';
import UserDetails from '../views/AdminPanel/UserManagement/UserDetails/UserDetails';

import { PrivateRoute } from './PrivateRoute';
import { PublicRoutes } from './PublicRoute';

export const MainRouter = () => {
  return (
    <Router>
      <Switch>
        {/*******************  Admin Routes */}
        <Route path={ADMIN_LOGIN}>
          <PublicRoutes>
            <Login calledFrom={'ADMIN'} />
          </PublicRoutes>
        </Route>
        <Route path={ADMIN_FORGOT_PASSWORD} component={ForgotPassword} exact />
        <Route path={ADMIN_RESET_PASSWORD} component={ResetPassword} exact />
        <Route path={USER_MANAGEMENT} exact>
          <PrivateRoute module={'ADMIN'}>
            <DashboardShell>
              <UserManagement />
            </DashboardShell>
          </PrivateRoute>
        </Route>
        <Route path={USER_DETAILS} exact>
          <PrivateRoute module={'ADMIN'}>
            <DashboardShell>
              <UserDetails />
            </DashboardShell>
          </PrivateRoute>
        </Route>
        <Route path={TRANSACTION_LOGS} exact>
          <PrivateRoute module={'ADMIN'}>
            <DashboardShell>
              <TransactionLogs />
            </DashboardShell>
          </PrivateRoute>
        </Route>{' '}
        <Route path={ADMIN_USERS} exact>
          <PrivateRoute module={'ADMIN'}>
            <DashboardShell>
              <AdminUsers />
            </DashboardShell>
          </PrivateRoute>
        </Route>
        {/*******************  User Routes */}
        <Route path={LOGIN} exact>
          <PublicRoutes>
            <Login />
          </PublicRoutes>
        </Route>
        <Route path={SIGNUP} component={Signup} exact />
        <Route path={EMAIL_VERIFICATION} component={EmailVerification} exact />
        <Route path={FORGOT_PASSWORD} component={ForgotPassword} exact />
        <Route path={RESET_PASSWORD} component={ResetPassword} exact />
        <Route path={WELLBEING_CONFIRMATION} exact render={(props) => <WellbeingConfirmation {...props} />} />
        <Route path={NOMINEE_WELLBEING_CONFIRMATION} component={NomineeWellbeingConfirmation} exact />
        {/***************User dashboard routes */}
        <Route path={PORTFOLIO} exact>
          <PrivateRoute module={'USER'}>
            <DashboardShell>
              <Portfolio />
            </DashboardShell>
          </PrivateRoute>
        </Route>
        <Route path={NOMINEES} exact>
          <PrivateRoute module={'USER'}>
            <DashboardShell>
              <Nominees />
            </DashboardShell>
          </PrivateRoute>
        </Route>
        <Route path={PREFERENCES} exact>
          <PrivateRoute module={'USER'}>
            <DashboardShell>
              <Preferences />
            </DashboardShell>
          </PrivateRoute>
        </Route>
        <Route path={PROFILE_SETTINGS} exact>
          <PrivateRoute module={'USER'}>
            <DashboardShell>
              <ProfileSettings />
            </DashboardShell>
          </PrivateRoute>
        </Route>
        <Route path={BILLING} exact>
          <PrivateRoute module={'USER'}>
            <DashboardShell>
              <Billing />
            </DashboardShell>
          </PrivateRoute>
        </Route>
        <Route path={SUPPORT} exact>
          <PrivateRoute module={'USER'}>
            <DashboardShell>
              <Support />
            </DashboardShell>
          </PrivateRoute>
        </Route>
        {/* Default route */}
        <Route path="/">
          <PublicRoutes>
            <Login />
          </PublicRoutes>
        </Route>
        <Route path="*" component={PageNotFound} />
      </Switch>
    </Router>
  );
};
